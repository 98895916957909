// / <reference path="../../types/candy.d.ts" />
/**
 * Chat-specific buttons for the UserOverview
 */
import React, { useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import Button from '../Button';
import MultistreamButton from '../Button/Multistream';
import {
  getCurrentUser, getActiveRoom, setPerformingAction, setOpeningPM,
} from '~/modules/chat';
import { ChatContext, events, roleLevels } from './ChatContext';
import BlockButton from './BlockButton';

import styles from './OverviewButtons.scss';

const OverviewButtons = ({ user }) => {
  const currentUser = useSelector(getCurrentUser);
  const { client: socket } = useContext(ChatContext);
  const currentRoomId = useSelector(getActiveRoom);
  const room = useSelector(state => state.entities.chatRooms[currentRoomId]);
  const dispatch = useDispatch();

  const isMyself = useMemo(() => user.userId === currentUser.userId, [user, currentUser]);

  const sendMessage = useCallback((message) => {
    if (!socket) return;
    socket.emit(events.ADD_MESSAGE, {
      roomId: currentRoomId,
      message: {
        text: message,
      },
    });
  }, [socket, currentRoomId]);

  const setVoice = useCallback((value) => {
    if (!socket) return;

    socket.emit(events.SET_VOICE, {
      roomId: currentRoomId,
      userId: user.userId,
      value,
    });
  }, [socket, currentRoomId, user]);

  const setMod = useCallback((value) => {
    if (!socket) return;

    socket.emit(events.SET_MOD, {
      roomId: currentRoomId,
      userId: user.userId,
      value,
    });
  }, [socket, currentRoomId, user]);

  const openPM = useCallback((uid) => {
    if (socket) {
      socket.emit(events.OPEN_PM, { uid });

      dispatch(setOpeningPM(uid));
    }
  }, [socket, dispatch]);

  const setEcho = useCallback((value) => {
    if (socket) {
      socket.emit(events.SET_ECHO, {
        roomId: currentRoomId,
        userId: user.userId,
        value,
      });
    }
  }, [socket, currentRoomId, user]);

  const isEchoed = useMemo(() => user.role === 'echo', [user]);
  /**
   * Call e.stopPropagation on the event to prevent the global click listener from closing the overview
   */
  const wrapStopPropagation = useCallback(fn => (e) => {
    e.stopPropagation();
    fn();
  }, []);

  if (isMyself) {
    return null;
  }

  return (
    <div className={styles.OverviewButtons}>
      {
        user.piczel_user_id && (
          <MultistreamButton username={user.username} />
        )
      }

      {
        currentUser.permissions.kick && (
          <Button onClick={wrapStopPropagation(() => dispatch(setPerformingAction(user.username, 'kick')))} color="black" size="small">
            <FormattedMessage id="OverviewButtons_Kick" defaultMessage="Kick" />
          </Button>
        )
      }

      {
        currentUser.permissions.ban && (
          user.role === 'banned'
            ? (
              <Button onClick={wrapStopPropagation(() => sendMessage(`/unban ${user.username}`))} color="black" size="small">
                <FormattedMessage id="OverviewButtons_Unban" defaultMessage="Unban" />
              </Button>
            )
            : (
              <Button onClick={wrapStopPropagation(() => dispatch(setPerformingAction(user.username, 'ban')))} color="black" size="small">
                <FormattedMessage id="OverviewButtons_Ban" defaultMessage="Ban" />
              </Button>
            )
        )
      }

      {
        currentUser.permissions.promote && room?.moderated && (
          user.role === 'visitor' ? (
            <Button color="black" size="small" onClick={wrapStopPropagation(() => setVoice(true))}>
              <FormattedMessage id="OverviewButtons_GrantVoice" defaultMessage="Grant voice" />
            </Button>
          ) : (
            <Button color="black" size="small" onClick={wrapStopPropagation(() => setVoice(false))}>
              <FormattedMessage id="OverviewButtons_RevokeVoice" defaultMessage="Revoke voice" />
            </Button>
          )
        )
      }

      {
        roleLevels[currentUser.role] >= roleLevels.streamer && (
          user.role === 'mod' ? (
            <Button color="black" size="small" onClick={wrapStopPropagation(() => setMod(false))}>
              <FormattedMessage id="OverviewButtons_RevokeMod" defaultMessage="Revoke mod status" />
            </Button>
          ) : (
            <Button color="black" size="small" onClick={wrapStopPropagation(() => setMod(true))}>
              <FormattedMessage id="OverviewButtons_GrantMod" defaultMessage="Grant mod status" />
            </Button>
          )
        )
      }

      {(
        <>
          <BlockButton user={user} />
          <Button
            disabled={currentUser.piczel_user_id === null || user.piczel_user_id === null}
            onClick={wrapStopPropagation(() => openPM(user.userId))}
            color="black"
            size="small"
          >
            <MessageButtonPM userId={user.piczel_user_id} currentUserId={currentUser.piczel_user_id} />
          </Button>
          {
          roleLevels[currentUser.role] >= roleLevels.admin && (
          <Button size="small" color="black" onClick={wrapStopPropagation(() => setEcho(!isEchoed))}>
            {
              isEchoed ? (
                <FormattedMessage id="OverviewButtons_RevokeEcho" defaultMessage="Remove shadowban" />
              ) : (
                <FormattedMessage id="OverviewButtons_SetEcho" defaultMessage="Shadowban user" />
              )
            }
          </Button>
          )
        }
        </>
      )}

    </div>
  );
};

const MessageButtonPM = ({ currentUserId, userId }) => {
  if (currentUserId === null) {
    return <FormattedMessage id="OverviewButtons_UnregisteredUser" defaultMessage="Unregistered users cannot send PMs" />;
  } if (userId === null) {
    return <FormattedMessage id="OverviewButtons_PMUnregisteredUser" defaultMessage="You cannot PM unregistered users" />;
  }
  return <>PM</>;
};

OverviewButtons.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string,
    role: PropTypes.string,
    username: PropTypes.string,
    piczel_user_id: PropTypes.number,
    userId: PropTypes.string.isRequired,
  }).isRequired,
};

export default OverviewButtons;
