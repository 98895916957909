// @ts-check
// eslint-disable-next-line spaced-comment
/// <reference path="../types/streams.d.ts" />
/**
* Sets up videoJS title bar
*/
import React from 'react';
import propTypes from 'prop-types';
import { FollowButton } from '../Button';
import styles from './index.scss';

/**
 * @param {Object} props
 * @param {Stream} props.stream
 * @param {((username: string) => void)|false} props.toggleFocus
 * @param {((username: string) => void)|false} props.removeStream
 * @param {((username: string) => void)|false} props.stopRecording
 * @param {boolean} props.showBitrates
 */
export default function TitleBar({ stream, toggleFocus, removeStream, stopRecording, showBitrates }) {
  return (
    <div className={styles.TitleBar}>
      <div className={styles['TitleBar--Username']}>
        {stream.username}
        {' '}
        {showBitrates ? `(${Math.round((stream.bitrate || 0) / 1000)}Kbps)` : '' }
      </div>
      <FollowButton small username={stream.username} />

      <div className={styles.TitleBar__Buttons}>
        {toggleFocus && (
          <button
            type="button"
            className={`ion-arrow-resize ${styles.TitleBarButton}`}
            onClick={() => toggleFocus(stream.username)}
            title={`Focus ${stream.username}'s stream`}
          />
        )}
        { (removeStream || stopRecording) && (
          <button
            type="button"
            title={stopRecording ? 'Stop this recording' : `Close ${stream.username}'s stream`}
            onClick={() => {
              if (stopRecording) {
                stopRecording(stream.username);
              } else if (removeStream) {
                removeStream(stream.username);
              }
            }}
            className={`ion-close-round ${styles.TitleBarButton}`}
          />
        )}
      </div>
    </div>
  );
}

TitleBar.propTypes = {
  toggleFocus: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  stream: propTypes.shape({
    username: propTypes.string.isRequired,
    bitrate: propTypes.number,
  }).isRequired,
  removeStream: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  stopRecording: propTypes.oneOfType([propTypes.func, propTypes.bool]),
};

TitleBar.defaultProps = {
  toggleFocus: false,
  stopRecording: false,
  removeStream: false,
};
