const formatDuration = (remaining, milliseconds = true) => {
  if (!remaining) return '';
  const remainingSeconds = milliseconds ? remaining / 1000 : remaining;

  const minutes = (remainingSeconds / 60);
  const seconds = remainingSeconds - (Math.floor(minutes) * 60);

  return `${Math.floor(minutes)}:${Math.round(seconds) < 10 ? '0' : ''}${Math.round(seconds)}`;
};

export default formatDuration;
