import { createSlice, createSelector } from '@reduxjs/toolkit';
import Config from '~/config';

interface PersonalToken {
  id: number;
  name: string;
  token: string;
}

interface PersonalTokenState {
  tokens: PersonalToken[];
}

const initialState: PersonalTokenState = {
  tokens: [],
};

const personalAccessTokens = createSlice({
  name: 'personalAccessTokens',
  initialState,
  reducers: {
    setPersonalAccessTokens: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.tokens = action.payload;
    },
    removeAccessToken: (state, action) => {
      const { id } = action.payload;
      state.tokens = state.tokens.filter(token => token.id !== id);
    },
    addAccessToken: (state, action) => {
      state.tokens.push(action.payload);
    }
  },
});

export const { setPersonalAccessTokens, removeAccessToken, addAccessToken } = personalAccessTokens.actions;

export const loadPersonalAccessTokens = () => async (dispatch, getState, fetch) => {
  const response = await fetch(`${Config.api}/users/me/api`);
  const data = await response.json();

  dispatch(setPersonalAccessTokens(data));
};

export const deletePersonalAccessToken = tokenId => async (dispatch, getState, fetch) => {
  const response = await fetch(`${Config.api}/api/${tokenId}`, {
    method: 'DELETE',
  });

  const data = await response.json();

  dispatch(removeAccessToken({
    id: tokenId,
  }));

  return data;
};

export const createAccessToken = ({ name, expiresAt }) => async (dispatch, getState, fetch) => {
  const response = await fetch(`${Config.api}/users/me/api`, {
    method: 'POST',
    body: JSON.stringify({
      name,
      expires_at: expiresAt,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data = await response.json();

  dispatch(addAccessToken(data));

  return data;
};


export const getPersonalAccessTokens = state => state.personalAccessTokens.tokens;

export default personalAccessTokens.reducer;
