import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateImage } from '~/modules/gallery';

import Checkbox from '../Form/Checkbox';

const FeaturedToggle = ({ image }) =>  {
  const dispatch = useDispatch();

  const onChange = useCallback((e) => {
    dispatch(updateImage({
      id: image.id,
      curated: e.target.checked,
    }));
  }, []);
  
  return (
    <div>
      <Checkbox onChange={onChange} checked={image.curated}>
        Featured on front page
      </Checkbox>
    </div>
  );
};

export default FeaturedToggle;
