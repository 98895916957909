import { schema } from 'normalizr';

export const messageTimeThreshold = (prev: ChatMessage, current: ChatMessage) => ((new Date(current.created_at)).getTime() - (new Date(prev.created_at)).getTime()) <= 5*60*1000;

interface ChatMessageGroup extends Array<ChatMessage> {
  id?: string;
}

export const groupMessages = (messages: ChatMessage[]) => {
  const grouped = messages.reduce((stack: ChatMessageGroup[], message) => {
    const currentGroup = stack.length ? stack[stack.length - 1] : null;

    if (!currentGroup || !Array.isArray(currentGroup)) {
      const group: ChatMessageGroup = [message];
      group.id = message.id;
      stack.push(group);
      return stack;
    } 

    currentGroup.id += message.id;

    const prevMessage = currentGroup[currentGroup.length - 1];
    if (prevMessage.serverMessage || message.serverMessage || !message.created_at || !prevMessage.created_at) {
      stack.push([message]);
      return stack;
    }
    
    const sameUser = prevMessage.user.uid === message.user.uid;
    const recentEnough = messageTimeThreshold(prevMessage, message);

    if (sameUser && recentEnough) {
      currentGroup.push(message);
    } else {
      const group: ChatMessageGroup = [message];
      group.id = message.id;
      stack.push(group);
    }

    return stack;
  }, []);
  return grouped;
};

export const chatUserSchema = new schema.Entity('chatUsers', undefined, {
  idAttribute: 'uid',
});

export const chatMessageSchema = new schema.Entity('chatMessages', {}, {
  idAttribute: 'id',
});

export const chatMessageListchema = [chatMessageSchema];

export const chatRoomSchema = new schema.Entity('chatRooms', {
  users: [chatUserSchema],
  me: chatUserSchema,
});
