import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { startPlayingStreams, getPromptingUsernames, STREAMS_PLAYER, SET_PASSWORDS, SET_SSR, getPlayingStreams, fetchPlayingStream } from '~/modules/streams';
import WatchSkeleton from '~/components/WatchSkeleton';
import { isLoading } from '~/modules/loading';
import { withRouter } from '~/hooks';
import Loading from '~/components/Loading';
import StreamPrompt from '~/components/StreamPrompt';
import { StreamSubscriptionGroup, SubscriptionContext } from '~/services/cable';
import { ChatContext } from '~/components/Chat/ChatContext';

/**
 * If a password is required, renders the prompt, otherwise renders the child node
 */
export default function StreamGuard({ children, noSkeleton }) {
  const { names: usernames, passwords } = useParams();
  const isServerRendered = useSelector(state => state.streams.ssr);
  const loading = useSelector(state => isLoading(state.loading, STREAMS_PLAYER));
  const prompting = useSelector(getPromptingUsernames);
  const streams = useSelector(getPlayingStreams);

  const dispatch = useDispatch();
  /**
   * @type {React.MutableRefObject<StreamSubscriptionGroup|null>}
   */
  const subs = useRef(null);

  const [client, setClient] = useState(null);

  useEffect(() => {
    if (passwords) {
      dispatch({
        type: SET_PASSWORDS,
        payload: {
          [usernames]: passwords,
        },
      });
    }
  }, [passwords, dispatch]);

  useEffect(() => {
    if (!isServerRendered) {
      dispatch(startPlayingStreams(usernames));
    } else {
      dispatch({
        type: SET_SSR,
        payload: false,
      });
    }

    function focusHandler() {
      console.log(usernames);
      const usernameArray = Array.isArray(usernames) ? usernames : [usernames];

      usernameArray.forEach((username) => {
        dispatch(fetchPlayingStream(username));
      });
    }

    window.addEventListener('focus', focusHandler);

    return () => {
      const streamSubs = subs.current;

      if (streamSubs) streamSubs.clearAll();

      window.removeEventListener('focus', focusHandler);
    };
  }, [usernames, passwords, dispatch]);

  useEffect(() => {
    if (!subs.current) {
      subs.current = new StreamSubscriptionGroup(dispatch, streams);
    } else {
      /**
       * @type {StreamSubscriptionGroup}
       */
      const subscriptions = subs.current;

      subscriptions.sync(streams);
    }

    return () => {
    };
  }, [streams, dispatch]);

  const prompt = prompting.length ? <StreamPrompt playing={usernames} /> : <Loading />;

  if (loading || prompting.length) {
    if (noSkeleton) return prompt;

    return (
      <WatchSkeleton loading={loading}>
        {prompt}
      </WatchSkeleton>
    );
  }

  return (
    <ChatContext.Provider
      value={{
        client,
        setClient,
      }}
    >
      <SubscriptionContext.Provider value={subs.current}>
        {children}
      </SubscriptionContext.Provider>
    </ChatContext.Provider>
  );
}

export const StreamGuardHydrate = (dispatch, params) => {
  const { names: usernames, passwords } = params;

  dispatch({
    type: SET_SSR,
    payload: true,
  });

  if (passwords) {
    dispatch({
      type: SET_PASSWORDS,
      payload: {
        [usernames]: passwords,
      },
    });
  }

  return dispatch(startPlayingStreams(usernames));
};
