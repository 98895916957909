import React from 'react';
import classNames from 'classnames/bind';
import { ReactMarkdownWithHtml } from '~/components/Markdown';
import UserOverview from '~/components/UserOverview';
import emojiMap from '@piczel/emoji/emojiRegexMap';
import CommentBox from '../../Box';
import CommentList from '..';
import Button from '~/components/Button';
import Config from '~/config';

import styles from './Comment.scss';

const cx = classNames.bind(styles);
const levelNoIdented = 2;

export default class Comment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOverview: false,
      displayReplyBox: false,
    };

    this.delete = this.delete.bind(this);
    this.canDelete = this.canDelete.bind(this);
    this.globalClickListener = this.globalClickListener.bind(this);
  }

  canDelete() {
    const { isSignedIn, currentUser, comment, owner } = this.props;
    if (!isSignedIn) return false;
    if (currentUser.role == 'admin') return true;
    if (comment.user.id === currentUser.id) return true;
    if (owner === currentUser.username) return true;
    if (comment.parent && comment.parent.user_id === currentUser.id) return true;

    return false;
  }

  delete() {
    this.props.deleteComment(this.props.comment.id);
  }

  componentDidMount() {
    const {
      loadReplies,
      comment: {
        id,
        has_replies,
        replies,
      },
    } = this.props;

    window.addEventListener('click', this.globalClickListener);
    if (has_replies && !replies) loadReplies(id);
  }

  content() {
    if (!this.contentMemoized) {
      this.contentMemoized = this.props.comment.content_processed;

      emojiMap.forEach((url, regex) => {
        this.contentMemoized = this.contentMemoized.replace(regex, `<img class=${styles.Emoji} src="${url}" title="$1" alt="$1" />`);
      });
    }

    return this.contentMemoized;
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.globalClickListener);
  }

  globalClickListener(e) {
    const { id } = this.props.comment;
    const icon = document.getElementById(`icon-${id}`);
    if (!icon?.contains(e.target)) {
      this.setState({ showOverview: false });
    }
  }

  render() {
    const { displayReplyBox } = this.state;

    const {
      isSignedIn,
      currentUser,
      deleteComment,
      sendComment,
      loadReplies,
      level = 0,
      owner,
    } = this.props;

    const {
      content,
      content_processed: htmlContent,
      user,
      created_at,
      parent_type,
      parent_id,
      id,
      has_replies,
      replies,
      replies_count,
      reply_to_id,
    } = this.props.comment;

    return (
      <div id={`comment-${id}`} className={cx('Comment', { 'Comment--child': reply_to_id !== null })}>
        <Button color="transparent" className={styles.Comment__User}
          id={`icon-${id}`}
          onClick={() => this.setState({ showOverview: true })}
        >
          <img src={`${Config.avatarsHost}/avatars/${user.id}`}  className={styles.Comment__Avatar} alt={`${user.username}'s avatar`} />
          <div className={styles.Comment__Username}>{user.username}</div>
          {this.state.showOverview && <UserOverview user={user} />}
        </Button>

        <div className={styles.Comment__Content}>
          <ReactMarkdownWithHtml>
            {this.content()}
          </ReactMarkdownWithHtml>
        </div>

        <div className={styles.Comment__BottomLine}>
          <span className={cx(['Comment__Action', 'Comment__CreatedAt'])}>
            <i className="ion-android-time" />
            <span>{`${created_at} ago`}</span>
          </span>
          {isSignedIn && (
          <span
            onClick={() => this.setState({ displayReplyBox: !displayReplyBox })}
            className={cx(['Comment__Action', 'Comment__Reply'])}
          >
            <i className="ion-reply" />
            <span>Reply</span>
          </span>
          )}

          {this.canDelete() && (
            <span onClick={this.delete} className={cx(['Comment__Action', 'Comment__Delete'])}>
              <i className="ion-trash-a" />
              <span>Delete</span>
            </span>
          )}
        </div>
        <section className={cx(['Comment__Action', 'Comment__ReplySection'], {'Comment__ReplySection--NoIdented':level >= levelNoIdented})}>
          {displayReplyBox && isSignedIn && (
            <div className={styles.Comment__ReplyBox}>
              <CommentBox
                {...{ currentUser, sendComment }}
                reply_to={id}
              />
            </div>
          )}

          {has_replies && (
            <CommentList
              {...{
                isSignedIn, currentUser, sendComment, deleteComment, loadReplies,
              }}
              owner={owner}
              level={level + 1}
              comments={replies || []}
            />
          )}
        </section>
      </div>
    );
  }
}
