import React from 'react';
import classNames from 'classnames/bind';
import { func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import styles from './ViewLayoutSelector.scss';

const cx = classNames.bind(styles);

const ViewLayouts = {
  Auto: 'Auto',
  Desktop: 'Desktop',
  Mobile: 'Mobile',
};

const Icons = {
  Auto: 'ion-wand',
  Desktop: 'ion-android-desktop',
  Mobile: 'ion-android-phone-portrait',
};

const ViewLayoutSelector = ({ setViewLayout, currentViewLayout, className }) => (
  <div className={`${styles.ViewLayoutSelector} ${className}`}>
    <div className={styles.ViewLayoutSelector__Label}>
      <FormattedMessage id="ViewLayoutSelector_Title" defaultMessage="View layout" />
    </div>

    <div className={styles.ViewLayoutSelector__Options}>
      {
        Object.entries(ViewLayouts).map(([label, layout]) => (
          <button
            key={layout}
            className={
              cx(styles.ViewLayoutSelector__Option, { active: currentViewLayout === layout })
            }
            type="button"
            onClick={() => setViewLayout(layout)}
            title={label}
          >
            <span role="img" className={Icons[label]} />
          </button>
        ))
      }
    </div>
  </div>
);

ViewLayoutSelector.propTypes = {
  setViewLayout: func.isRequired,
  currentViewLayout: string.isRequired,
  className: string.isRequired,
};

export default ViewLayoutSelector;
