// @ts-check
import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';
import { FormattedMessage } from 'react-intl';

import TextField from '~/components/Form/TextField';
import {
  getPlayingStreams, resizeChat, getChatWidth, getMainStream,
} from '~/modules/streams';

import { withRouter } from '~/hooks';

import Resizer from './Resizer';
import StreamBanner from '../StreamBanner';
import { requestAccessToken, getAccessToken } from '~/modules/chat';
import getValidationErrors from '~/helpers/validator';
import Homebrew from './Homebrew';
import Loading from '../Loading';
import Button from '../Button';
import LoginForm from '../LoginForm';

import styles from './index.scss';
import OverlayLoginMessage from './OverlayLoginMessage';

const cx = classNames.bind(styles);

class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errored: false,
      promptingUsername: false,
      loading: false,
      mobile: false,
      attemptingLogin: false,
    };
  }

  componentDidMount() {
    const { user, requestChatAccessToken, accessToken } = this.props;

    if (accessToken) return;

    if (user && user.username) {
      this.setState({
        loading: true,
      });

      requestChatAccessToken().then((result) => {
        this.setState({
          loading: false,
          errored: result.error ? result.error : false,
        });
      })
        .catch((reason) => {
          this.setState({
            loading: false,
            errored: reason,
          });
        });
    } else {
      this.setState({ promptingUsername: true });
    }

    this.isMobile();
    window.addEventListener('resize', this.isMobile);
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.isMobile);
  }

  isMobile = () => {
    const mobile = typeof window !== 'undefined' && window.innerWidth < 950;
    this.setState({ mobile });
  }

  render() {
    const {
      width, setWidth, mainStream, className, user,
      streams, accessToken, requestChatAccessToken,
      overlayMode, viewLayout, location
    } = this.props;
    const {
      promptingUsername, errored, loading, mobile, attemptingLogin,
    } = this.state;

    const hasBanner = mainStream && mainStream.banner.url;

    const containerId = 'PiczelChat';

    const fullView = location.pathname.startsWith('/chat/');
    return (
      <div
        id={containerId}
        style={{ width: mobile || viewLayout === 'Mobile' || viewLayout === 'Theater' ? null : width }}
        className={cx('Chat', {
          'Chat--Banner': hasBanner && !fullView, 'Chat--Padding': fullView, 'Chat--Banner__Full': fullView && hasBanner, mobile: viewLayout === 'Mobile', desktop: viewLayout === 'Desktop', theater: viewLayout === 'Theater',
        }, className)}
      >
        { hasBanner && <StreamBanner stream={mainStream} />}
        <div className={styles.Chat__Content}>
          { ((!user || !user.username) && !accessToken) && !attemptingLogin && (
            <Formik
              initialValues={{ username: '' }}
              validate={(values) => {
                const errors = {};
                if (!values.username) {
                  errors.username = "Can't be blank";
                } else {
                  const validationErrors = getValidationErrors(values.username);

                  if (validationErrors.length) errors.username = validationErrors.pop();
                }
                return errors;
              }}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { setErrors }) => {
                this.setState({ loading: true });

                requestChatAccessToken(values.username).then((result) => {
                  this.setState({ loading: false });
                  if (result.error) {
                    setErrors({
                      username: result.error,
                    });
                  } else {
                    this.setState({ promptingUsername: false });
                  }
                });
              }}
            >
              {
                ({ errors }) => (
                  <Form className={styles.Chat__UsernamePrompt}>
                    {
                      overlayMode && (
                        <OverlayLoginMessage />
                      )
                    }

                    <label htmlFor="ChatUsernameInput">
                      <FormattedMessage id="Chat__UsernameInput" defaultMessage="Enter a username for the chat" />
                      <TextField disabled={loading} id="ChatUsernameInput" type="text" name="username" required error={errors.username} />
                    </label>

                    <Button color="transparent" onClick={() => this.setState({ attemptingLogin: true })}>
                      <FormattedMessage id="Chat__Login" defaultMessage="Sign in instead" />
                      &nbsp;
                      <span className="ion-arrow-right-b" role="img" />
                    </Button>
                  </Form>
                )
              }
            </Formik>
          )}

          {
            attemptingLogin && (
              <div className={styles.Chat__UsernamePrompt}>
                {
                  overlayMode && (
                    <OverlayLoginMessage />
                  )
                }

                <LoginForm />
                <Button color="transparent" onClick={() => this.setState({ attemptingLogin: false })}>
                  <span role="img" className="ion-arrow-left-b" />
                  &nbsp;
                  <FormattedMessage id="Chat__AnonLogin" defaultMessage="Choose an username instead" />
                </Button>
              </div>
            )
          }

          {accessToken && (
            <Homebrew
              isMobile={this.state.mobile}
              streams={streams}
              setWidth={setWidth}
              width={width}
              overlayMode={overlayMode}
            />
          )}

          {
            loading && (
              <Loading message="Connecting to the chat" />
            )
          }

          {
            errored && !promptingUsername && (
              <Loading
                color="#333"
                icon="/img/piczel-error-small.png"
                message={(
                  <Button style={{ fontSize: '0.9rem' }} onClick={() => window.location.reload()} color="transparent">
                    Failed to connect to the chat, click here to reload
                    &nbsp;
                    <span role="img" className="ion-refresh" />
                  </Button>
                )}
              />
            )
          }

          <Resizer containerId={containerId} setWidth={setWidth} />
        </div>
      </div>
    );
  }
}

Chat.propTypes = {
  className: propTypes.string,
  streams: propTypes.arrayOf(propTypes.shape({
    username: propTypes.string.isRequired,
  })).isRequired,
  streamUsernames: propTypes.arrayOf(propTypes.string).isRequired,
  user: propTypes.shape({
    username: propTypes.string.isRequired,
  }),
  setWidth: propTypes.func.isRequired,
  width: propTypes.string,
  mainStream: propTypes.shape({
    banner_link: propTypes.string,
    banner: propTypes.shape({
      url: propTypes.string,
    }),
  }),
  requestChatAccessToken: propTypes.func.isRequired,
};

Chat.defaultProps = {
  className: '',
  width: null,
  user: {
    username: null,
  },
  mainStream: {
    banner_link: null,
    banner: {
      url: null,
    },
  },
};

/**
 *
 * @param {RootState} state
 */
function mapStateToProps(state) {
  const { viewLayout } = state.chat.options;

  return {
    width: getChatWidth(state),
    user: state.currentUser.data,
    streamUsernames: state.streams.sections.playing,
    streams: getPlayingStreams(state),
    mainStream: getMainStream(state),
    accessToken: getAccessToken(state),
    viewLayout,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    /**
     * @param {string|null} width new chat width, must include css unit
     */
    setWidth(width) {
      return dispatch(resizeChat(width));
    },

    requestChatAccessToken(username) {
      return dispatch(requestAccessToken(username));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));
