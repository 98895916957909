import { produce, original } from 'immer';

export const ADD_ENTITIES = 'ADD_ENTITIES';
export const REMOVE_ENTITIES = 'REMOVE_ENTITIES';

const initialState = {
  // the object key HAS to match the entityName passed to entityReducer
  chatRooms: {},
  chatUsers: {},
  chatGlobalUsers: {},
  chatEmoticons: {},
  blacklistedWords: {},
  galleryImages: {},
  streams: {},
  users: {},
  posts: {},
  folders: {},
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_ENTITIES:
      Object.keys(action.payload).forEach((entityName) => {
        Object.keys(action.payload[entityName]).forEach((entityId) => {
          if (entityId in draft[entityName]) {
            draft[entityName][entityId] = produce(draft[entityName][entityId], (draftEntity) => {
              Object.assign(draftEntity, action.payload[entityName][entityId]);
            });
          } else {
            draft[entityName][entityId] = action.payload[entityName][entityId];
          }
        });
      });
      break;

    case REMOVE_ENTITIES:
      Object.keys(action.payload).forEach((entityName) => {
        action.payload[entityName].forEach((id) => {
          delete draft[entityName][id];
        });
      });
      break;

    default:
      return draft;
  }
}, initialState);

export default reducer;
