import memoize from 'memoize-one';

let instance = null;

const initCanvas = () => {
  instance = document.createElement('canvas');
};

const getCanvas = () => {
  if (!instance) {
    initCanvas();
  }

  return instance;
};

export const frozenAvatars = {};

const freezeImg = async (img) => {
  let bitMap;
  if('createImageBitmap' in window){
    const options = {
      resizeQuality: 'high'
    };
    
    bitMap = await window.createImageBitmap(img, 0, 0, img.naturalWidth, img.naturalHeight, options)
  }
  
  const image = bitMap?bitMap:img;
  const canvas = getCanvas();
  
  const ctx = canvas.getContext('2d');
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high'; 
  
  canvas.width = image.width;
  canvas.height = image.height;

  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(image, 0, 0, image.width, image.height);
  
  
  return new Promise(resolve => canvas.toBlob(resolve)).then((blob) => {
    const url = URL.createObjectURL(blob);

    if (img.src in frozenAvatars) {
      URL.revokeObjectURL(frozenAvatars[img.src]);
    }
  
    frozenAvatars[img.src] = url;
  
    return url;
  });
};

const memoFreezeImg = memoize(freezeImg, ([a], [b]) => a.src === b.src);

export default memoFreezeImg;
