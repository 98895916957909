import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import styles from './index.scss';

const cx = classNames.bind(styles);

const defaultMessage = (
  <div className={cx(['ModalSocialButton__Message'])}>
    <p>
      <FormattedMessage
        id="ModalSocialButton_Message"
        defaultMessage="Enter your username."
      />
    </p>
  </div>
);

const urlTags = {
  twitter: 'twitter.com/',
  facebook: 'facebook.com/',
  tumblr: 'tumblr.com/blog/',
  furaffinity: 'furaffinity.net/user/',
  'hentai foundry': 'hentai-foundry.com/user/',
  'ko-fi': 'ko-fi.com/user/',
};

const Messages = {
  twitter: (
    <div className={cx(['ModalSocialButton__Message'])}>
      <p>
        <FormattedMessage
          id="ModalSocialButton_MessageTwitter"
          defaultMessage="Enter your username, example @username123."
        />
      </p>
    </div>
  ),
  facebook: (
    <div className={cx(['ModalSocialButton__Message'])}>
      { defaultMessage }
      <p>
        <FormattedMessage
          id="ModalSocialButton_MessageFacebookHelp"
          defaultMessage="To find your facebook username, log in to facebook and go to {link}."
          values={{ link: (<a target="_blank" href="https://facebook.com/username" rel="noreferrer">https://facebook.com/username</a>) }}
        />
      </p>
    </div>
  ),
  paypal: (
    <div className={cx(['ModalSocialButton__Message'])}>
      <p>
        <FormattedMessage
          id="ModalSocialButton_MessagePaypalHelp"
          defaultMessage="To get your PayPal donation button go to {link} and copy the link."
          values={{ link: (<a target="_blank" href="https://www.paypal.com/paypalme/my/landing" rel="noreferrer">https://www.paypal.com/paypalme/my/landing</a>) }}
        />
      </p>
    </div>
  ),
  tumblr: defaultMessage,
  furaffinity: defaultMessage,
  'hentai foundry': defaultMessage,
};

const ModalSocialButton = ({
  socialMediaTag, close, isOpen, addButtonSocial,
}) => {
  const [username, setUsername] = useState('');

  const handleChange = (event) => {
    setUsername(event.target.value);
  };

  const parseUsername = () => {
    let usrname;

    if (socialMediaTag === 'twitter') {
      [, usrname] = username.split('@');
    }

    if (socialMediaTag === 'ko-fi') {
      [, usrname] = username.split('ko-fi.com/');
    }

    if (socialMediaTag === 'paypal') {
      [, usrname] = username.split('paypal.me/');
    }

    if (!usrname) {
      [, usrname] = username.split(urlTags[socialMediaTag]);
      usrname = !usrname ? username : usrname;
    }

    return usrname;
  };

  const addButton = () => {
    addButtonSocial({ socialMediaTag, username: parseUsername() });
  };

  const handleOnkeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      addButton();
    }
  };

  return (
    <Modal onRequestClose={close} isOpen={isOpen}>
      <div className={cx(['ModalSocialButton'])}>
        <h3>
          <FormattedMessage
            id="ModalSocialButton_Title"
            defaultMessage="Add button for your {socialMediaTag} account"
            values={{ socialMediaTag }}
          />
        </h3>
        <input
          autoFocus
          type="text"
          value={username}
          placeholder={socialMediaTag === 'paypal' ? 'Link' : 'username'}
          onChange={handleChange}
          onKeyDown={handleOnkeyDown}
        />
        {Messages[socialMediaTag]}
        <div className={cx(['ModalSocialButton__Save'])}>
          <button type="button" onClick={addButton}>
            <FormattedMessage
              id="Button_Add"
              defaultMessage="Add"
            />
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalSocialButton.propTypes = {
  socialMediaTag: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  addButtonSocial: PropTypes.func.isRequired,
};

export default ModalSocialButton;
