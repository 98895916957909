import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import emojiMap from '@piczel/emoji/emojiMap.json';
import * as emojis from '@piczel/emoji/frontend';
import debounce from 'debounce';

import { getEmojiTone, getAutocompleteSuggestions, loadAutocompleteSuggestions, addUsedEmoticon, setAutocompleteQuery } from '~/modules/chat';
import {
  AutocompletePane, AutocompleteTitle, AutocompleteSuggestions,
  AutocompleteSuggestion, SuggestionIcon,
} from './AutocompleteUI';
import UsernameAutocomplete from './UsernameAutocomplete';
import SlashCommandAutocomplete from './SlashCommandAutocomplete';

//const composingEmoteRegex = /.*:(\w+|-+)$/;
const composingEmoteRegex = /.*(\s|^):(\w+|-+)$/;

const SUGGESTION_LIMIT = 5;

/**
 * 
 * @param {any[]} array 
 */
const limitArray = (array, limit = SUGGESTION_LIMIT) => {
  if (array.length <= SUGGESTION_LIMIT) return array;

  return array.slice(0, limit);
};

export const EmoticonAutocomplete = ({ message, setMessage, focusInput, roomName }) => {
  const match = message.match(composingEmoteRegex);
  const dispatch = useDispatch();
  const emojiTone = useSelector(getEmojiTone);
  const [localSuggestions, setLocalSuggestions] = useState([]);
  const suggestions = useSelector(getAutocompleteSuggestions);
  const portion = match[2];

  useEffect(() => {    
    if (portion.length >= 2) {
      dispatch(loadAutocompleteSuggestions(portion, roomName));

      const emojis = emojiMap.emoji.filter(emoji => (emoji.diversity === emojiTone || (!emoji.diversity && !emoji.has_diversity)) && emoji.plain.indexOf(portion) !== -1);

      setLocalSuggestions(limitArray(emojis));
    }
  }, [message, emojiTone, portion]);

  const autocomplete = useCallback((emote) => {
    setMessage(composing => composing.replace(/:(\w|-)+$/, emote.plain));
    dispatch(addUsedEmoticon({
      ...emote,
      image: emote.image || emojis[emote.id],
    }));
    focusInput();
  }, [setMessage, dispatch]);

  const allSuggestions = useMemo(() => {
    const merged = [...localSuggestions, ...suggestions];

    merged.sort((emoteA, emoteB) => {
      const indexA = emoteA.plain.toLowerCase().indexOf(portion);
      const indexB = emoteB.plain.toLowerCase().indexOf(portion);

      if (indexA > indexB) {
        return 1;
      }
      
      if (indexA === indexB) {
        if (emoteA.plain.length > emoteB.plain.length) {
          return 1;
        }

        return -1;
      }

      return -1;
    });

    return limitArray(merged);
  }, [localSuggestions, suggestions, portion]);

  if (portion && portion.length < 2) return null;

  return (
    <AutocompletePane>
      <AutocompleteTitle subTitle={portion}>
        Emoticons matching
      </AutocompleteTitle>

      <AutocompleteSuggestions blur={focusInput}>
        {
          (onFocus, focused) => (
            allSuggestions.map(emote => (
              <AutocompleteSuggestion
                onFocus={onFocus}
                label={emote.plain}
                icon={emote.image ? (
                  <SuggestionIcon src={emote.image} alt={emote.plain} />
                ) : (
                  <SuggestionIcon
                    src={emojis[emote.id]}
                    alt={emote.plain}
                  />
                )}
                key={emote.id}
                onClick={() => autocomplete(emote)}
                focused={focused}
              />
            ))
          )
        }
      </AutocompleteSuggestions>
    </AutocompletePane>
  );
};

EmoticonAutocomplete.regex = composingEmoteRegex;


const Autocomplete = ({ message, setMessage, focusInput, roomName, matchers }) => {
  const [debouncedMessage, setDebouncedMessage] = useState(message);
  const updateMessage = useCallback(debounce(setDebouncedMessage, 120), []);
  const AutocompleteComponent = useMemo(() => matchers.find((component) => {
    return component.regex.test(debouncedMessage);
  }), [debouncedMessage, matchers]);

  useEffect(() => {
    updateMessage(message);
  }, [message, updateMessage]);

  if (!AutocompleteComponent) return null;

  return <AutocompleteComponent focusInput={focusInput} message={debouncedMessage} setMessage={setMessage} roomName={roomName} />;
};

Autocomplete.propTypes = {
  /**
   * the full message that's being composed
   */
  message: PropTypes.string.isRequired,

  /**
   * Allows us to change the current message
   */
  setMessage: PropTypes.func.isRequired,

  /**
   * gives focus to the text input
   */
  focusInput: PropTypes.func.isRequired,

  roomName: PropTypes.string.isRequired,

  matchers: PropTypes.arrayOf(PropTypes.element),
};

Autocomplete.defaultProps = {
  /**
   * The idea of this is to eventually add other 'matchers' like username autocomplete or slash autocomplete
   */
  matchers: [
    EmoticonAutocomplete,
    SlashCommandAutocomplete,
    UsernameAutocomplete,
  ],
}

export default Autocomplete;
