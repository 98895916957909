import Cookies from 'js-cookie';

export const PREFERENCES_KEY = 'preferences_v4';
export const OLD_PREFERENCES_KEY = 'preferences_v3';

/**
 * Represents 1 cookie, that will store a JSON object this way we don't store 1
 * cookie for every single setting
 */
class CookieStorage {
  name: string;
  data: any;
  
  constructor(name: string, data: any = null) {
    this.setData(data || {});
    this.name = name;
  }

  setData(data) {
    this.data = data;

    return this;
  }

  getItem(key, default_val = null) {
    if (key in this.data) {
      return this.data[key];
    }

    return default_val;
  }

  setItem(key, value) {
    this.data[key] = value;

    this.serializeAndPersist();
  }

  removeItem(key) {
    delete this.data[key];

    this.serializeAndPersist();
  }

  destroy() {
    return Cookies.remove(this.name);
  }

  serializeAndPersist(data: any = null) {
    const expires = 30;

    if (data) {
      this.data = data;
    }

    setCookies(this.name, this.data, {
      path: '/',
      expires,
    });
  }
}

const preferences = new CookieStorage(
  PREFERENCES_KEY,
  getCookies(PREFERENCES_KEY),
);

const authHeaders = new CookieStorage(
  'authHeaders',
  getCookies('auth-headers'),
);

function getCookies(key) {
  const cookiesString = Cookies.get(key);
  if (cookiesString) {
    try {
      return JSON.parse(cookiesString);
    } catch (error) {
      return cookiesString;
    }
  } else {
    return null;
  }
};

function setCookies(key, value, options) {
  const valueString = JSON.stringify(value);
  Cookies.set(key, valueString, options);
};

function resetCookies() {
  preferences.setData({});
  authHeaders.setData({});
};

export { preferences, authHeaders, resetCookies };
