/// <reference path="../../types/index.d.ts" />

import React from 'react';
import { useAddStream } from '~/hooks';
import { shape, string, oneOfType, arrayOf, node } from 'prop-types';

/**
 * wraps a component so that when it's clicked it adds the stream to the current watch page
 * @type {React.FC<{children: React.ReactNode[]|React.ReactNode, stream: Partial<Stream>}>}
 */
const Wrapper = ({ children, stream }) => {
  const addStream = useAddStream();

  return (
    <button style={{ display: 'contents' }} type="button" onClick={e => addStream(stream, e)}>
      {children}
    </button>
  )
};

Wrapper.propTypes = {
  stream: shape({
    slug: string,
    username: string,
  }).isRequired,
  children: oneOfType([node, arrayOf(node)]).isRequired,
};

export default Wrapper;

/**
 * HOC to add onClick listener that adds a stream to the currently playing streams
 * @param {typeof React.Component} Component
 */
export const withAddStream = Component => (props) => {
  const addStream = useAddStream();

  return <Component {...props} onClick={e => addStream(props.stream, e)} />;
};
