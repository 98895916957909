import { updateSubscription } from '~/modules/currentUser';

function deleteSubscription(sub) {
  return updateSubscription(sub, 'DELETE');
}

class ServiceWorker {
  static isSupported() {
    return (
      typeof window !== 'undefined'
      && 'serviceWorker' in navigator
      && 'PushManager' in window
    );
  }

  static getSubscription() {
    return navigator.serviceWorker.ready.then(reg => reg.pushManager.getSubscription());
  }

  static requestPermission() {
    return new Promise((resolve, reject) => {
      if (Notification.permission === 'granted') {
        resolve('granted');
      }

      const permissionResult = Notification.requestPermission((result) => {
        // In case notification api requires a callback
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then((result) => {
      if (result !== 'granted') {
        throw new Error("Permission wasn't granted");
      }
    });
  }

  /**
   * Register the service worker without subscribing to push notifications yet
   */
  static register() {
    return navigator.serviceWorker
      .register('/service-worker.js', {
        scope: '/',
      })
      .then(() => this.getSubscription());
  }

  static subscribe() {
    return this.requestPermission()
      .then(() => navigator.serviceWorker.ready)
      .then(swReg => swReg.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: new Uint8Array([
            4,
            98,
            89,
            105,
            47,
            9,
            223,
            34,
            4,
            61,
            60,
            252,
            136,
            99,
            38,
            10,
            40,
            52,
            177,
            243,
            188,
            180,
            188,
            123,
            184,
            36,
            196,
            176,
            206,
            118,
            17,
            170,
            118,
            115,
            1,
            163,
            164,
            14,
            46,
            17,
            20,
            164,
            71,
            187,
            12,
            249,
            56,
            253,
            136,
            218,
            237,
            190,
            11,
            235,
            136,
            69,
            152,
            148,
            239,
            150,
            220,
            227,
            195,
            222,
            114,
          ]),
        }));
  }

  static unsubscribe() {
    return this.getSubscription()
      .then((subscription) => {
        subscription.unsubscribe();

        return subscription;
      });
  }
}

export default ServiceWorker;
