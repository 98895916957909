/* eslint-disable */

const MAX_COUNT_COLUMNS = 4;
const MIN_COUNT_FOR_MODE_MIDDLE = 5;

const MODE_UNIFORM = 0;
const MODE_EXPANDED_TALL = 1;
const MODE_EXPANDED_MIDDLE = 2;
const MODE_EXPANDED_WIDE = 3;

const COLUMN_ORIENTATION_HORIZONTAL = 0;
const COLUMN_ORIENTATION_VERTICAL = 1;

const o_videoRatio = new Ratio(16, 9);

// -----------------------------------------

// can return undefined
// expanded frame is always the first entry
// smallest frame is always the last entry
// returned frames always are within the parent frame
/**
* NOTE: Under what conditions would this return undefined?
*/
export function getStreamLayout(
  o_parentFrame,
  n_frameCount,
  b_hasExpandedFrame = false
) {
  var a_layoutOptions = compileLayoutOptionList(
    o_parentFrame,
    n_frameCount,
    b_hasExpandedFrame
  );

  var o_bestLayoutOption = getBestLayoutOption(a_layoutOptions);
  if (o_bestLayoutOption == undefined) return undefined;

  return compileFrameList(o_parentFrame, o_bestLayoutOption);
}

export function compileLayoutOptionList(
  o_parentFrame,
  n_frameCount,
  b_hasExpandedFrame
) {
  const o_parentRatio = new Ratio(o_parentFrame.w, o_parentFrame.h);
  if (n_frameCount == 1) b_hasExpandedFrame = false;

  var n_middleOption =
    n_frameCount >= MIN_COUNT_FOR_MODE_MIDDLE && b_hasExpandedFrame ? 1 : 0;
  var n_options = b_hasExpandedFrame
    ? Math.min(n_frameCount - 1, MAX_COUNT_COLUMNS) * 2 + n_middleOption
    : n_frameCount;
  var a_layoutOptions = new Array(n_options);

  for (var i = 0; i < n_options - n_middleOption; ++i) {
    if (b_hasExpandedFrame) {
      a_layoutOptions[i] = new LayoutOption(
        MODE_EXPANDED_TALL,
        o_parentRatio,
        n_frameCount,
        i * 0.5 + 1
      );
      a_layoutOptions[i + 1] = new LayoutOption(
        MODE_EXPANDED_WIDE,
        o_parentRatio,
        n_frameCount,
        i * 0.5 + 1
      );
      ++i;
    } else {
      a_layoutOptions[i] = new LayoutOption(
        MODE_UNIFORM,
        o_parentRatio,
        n_frameCount,
        i + 1
      );
    }
  }

  if (b_hasExpandedFrame && n_frameCount >= MIN_COUNT_FOR_MODE_MIDDLE) {
    a_layoutOptions[n_options - 1] = new LayoutOption(
      MODE_EXPANDED_MIDDLE,
      o_parentRatio,
      n_frameCount
    );
  }

  return a_layoutOptions;
}

export function compileFrameList(o_parentFrame, o_layoutOption) {
  switch (o_layoutOption.i_mode) {
    case MODE_UNIFORM:
      return compileSmallFrameList(
        o_parentFrame,
        o_layoutOption.o_smallFrameCollection,
        o_parentFrame
      );

    case MODE_EXPANDED_TALL:
      var offset = {
        x: o_parentFrame.x,
        y: o_parentFrame.y + o_layoutOption.o_expandedSectionRatio.h
      };
      var a_frames = compileSmallFrameList(
        o_layoutOption.o_smallSectionRatio,
        o_layoutOption.o_smallFrameCollection,
        offset
      );
      var size = fitRatioInside(
        o_layoutOption.o_expandedSectionRatio,
        o_videoRatio
      );
      var n_offsetX = (o_layoutOption.o_expandedSectionRatio.w - size.w) * 0.5;
      a_frames.unshift(
        new Frame(o_parentFrame.x + n_offsetX, o_parentFrame.y, size.w, size.h)
      );
      return a_frames;

    case MODE_EXPANDED_WIDE:
      var size = fitRatioInside(
        o_layoutOption.o_expandedSectionRatio,
        o_videoRatio
      );
      var i_splitAt = Math.max(
        Math.floor(o_layoutOption.o_smallFrameCollection.n_columns * 0.5),
        1
      );
      var a_frames = compileSmallFrameList(
        o_layoutOption.o_smallSectionRatio,
        o_layoutOption.o_smallFrameCollection,
        o_parentFrame,
        i_splitAt,
        size.w
      );
      a_frames.unshift(
        new Frame(
          a_frames[0].x + a_frames[0].w * (i_splitAt + 0),
          o_parentFrame.y,
          size.w,
          size.h
        )
      );
      return a_frames;

    case MODE_EXPANDED_MIDDLE:
      var size = {
        w:
          o_layoutOption.o_expandedSectionRatio.w +
          o_layoutOption.o_smallSectionRatio.w,
        h:
          o_layoutOption.o_expandedSectionRatio.h +
          o_layoutOption.o_smallSectionRatio.h
      };
      var offset = {
        x: (o_parentFrame.w - size.w) * 0.5 + o_parentFrame.x,
        y: (o_parentFrame.h - size.h) * 0.5 + o_parentFrame.y
      };
      var a_frames = new Array(o_layoutOption.n_frameCount);
      a_frames[0] = new Frame(
        offset.x,
        offset.y,
        o_layoutOption.o_expandedSectionRatio.w,
        o_layoutOption.o_expandedSectionRatio.h
      );
      var n_smallFrameLow = Math.floor((o_layoutOption.n_frameCount - 1) * 0.5);
      var n_smallFrameHigh = Math.ceil((o_layoutOption.n_frameCount - 1) * 0.5);
      var n_offsetLastRowX =
        (1 - (n_smallFrameHigh - n_smallFrameLow)) *
        o_layoutOption.o_smallSectionRatio.w *
        0.5;
      for (var i = 0; i < n_smallFrameHigh; ++i) {
        a_frames[i + 1 + n_smallFrameLow] = new Frame(
          a_frames[0].x +
            i * o_layoutOption.o_smallSectionRatio.w +
            n_offsetLastRowX,
          a_frames[0].y + a_frames[0].h,
          o_layoutOption.o_smallSectionRatio.w,
          o_layoutOption.o_smallSectionRatio.h
        );
        if (i >= n_smallFrameLow) break;
        a_frames[i + 1] = new Frame(
          a_frames[0].x,
          a_frames[0].y + i * o_layoutOption.o_smallSectionRatio.h,
          o_layoutOption.o_smallSectionRatio.w,
          o_layoutOption.o_smallSectionRatio.h
        );
      }
      a_frames[0].x += a_frames[1].w;
      return a_frames;

    default:
      // TODO: need some sort of fallback layout
      return undefined;
  }
}

export function compileSmallFrameList(
  o_parentFrame,
  o_frameCollection,
  o_offset,
  i_splitAtIndex,
  n_splitWidth
) {
  var b_split = !isNaN(i_splitAtIndex) && !isNaN(n_splitWidth);
  var n_frameCount = o_frameCollection.n_frameCount;
  var a_frames = new Array(n_frameCount);
  var n_columns = o_frameCollection.n_columns;
  var n_rows = o_frameCollection.n_rows;
  var o_frameRatio = scaleRatioToWidth(
    o_videoRatio,
    o_frameCollection.o_ratio.w / n_columns
  );
  var n_offsetX = (o_parentFrame.w - o_frameRatio.w * n_columns) * 0.5;
  var n_offsetLastRowH =
    (o_frameCollection.n_frameCapacity - o_frameCollection.n_frameCount) *
    o_frameRatio.w *
    0.5;
  for (var row = 0; row < n_rows; ++row) {
    for (var col = 0; col < n_columns; ++col) {
      var i = row * n_columns + col;
      if (i >= n_frameCount) break;
      if (b_split) {
        a_frames[i] = new Frame(
          o_offset.x +
            col * o_frameRatio.w +
            n_offsetX +
            (col < i_splitAtIndex ? 0 : n_splitWidth),
          o_offset.y + row * o_frameRatio.h,
          o_frameRatio.w,
          o_frameRatio.h
        );
      } else {
        a_frames[i] = new Frame(
          o_offset.x +
            col * o_frameRatio.w +
            n_offsetX +
            (row < n_rows - 1 ? 0 : n_offsetLastRowH),
          o_offset.y + row * o_frameRatio.h,
          o_frameRatio.w,
          o_frameRatio.h
        );
      }
    }
  }

  if (typeof o_parentFrame.suggestedHeight == "function") {
    o_parentFrame.suggestedHeight(
      a_frames.map(f => f.h).reduce((prev, current) => prev + current)
    );
    o_parentFrame.suggestedWidth(Math.max.apply(null, a_frames.map(f => f.w)));
  }

  return a_frames;
}

// -----------------------------------------
/**
* Seems to be a container for position and dimensions for a div
* used later in a applyFrameToDiv function
*/
export class Frame {
  constructor(x, y, w, h) {
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
  }

  get style() {
    return this.getDivBounds();
  }

  getDivBounds(unit = 'px') {
    return {
      left: this.x + unit,
      top: this.y + unit,
      width: this.w + unit,
      height: this.h + unit
    };
  }

  suggestedHeight(height = null) {
    if (height) {
      this._suggestedHeight = height;
    }
    return this._suggestedHeight;
  }

  suggestedWidth(width = null) {
    if (width) {
      this._suggestedWidth = width;
    }
    return this._suggestedWidth;
  }
}

// -----------------------------------------

export function Ratio(w, h) {
  this.w = !isNaN(w) ? w : 0;
  this.h = !isNaN(h) ? h : 0;

  this.getWidthToHeight = function() {
    return this.w / this.h;
  };

  this.getArea = function() {
    return this.w * this.h;
  };
}

export function fitRatioInside(o_ratioOuter, o_ratioInner) {
  var n_w2h_inner = o_ratioInner.getWidthToHeight();
  var n_w2h_outer = o_ratioOuter.getWidthToHeight();
  if (n_w2h_outer > n_w2h_inner) {
    return new Ratio(
      o_ratioOuter.w / n_w2h_outer * n_w2h_inner,
      o_ratioOuter.h
    );
  } else {
    return new Ratio(
      o_ratioOuter.w,
      o_ratioOuter.h / n_w2h_inner * n_w2h_outer
    );
  }
}

export function scaleRatioToWidth(o_ratio, n_width) {
  return new Ratio(n_width, o_ratio.h / o_ratio.w * n_width);
}

// -----------------------------------------

export function UniformFrameCollection(
  n_streamCount,
  n_columns,
  i_orientation
) {
  this.i_orientation = i_orientation;
  this.n_frameCount = n_streamCount;
  this.n_columns = n_columns;
  this.n_rows = Math.ceil(this.n_frameCount / this.n_columns);
  this.n_frameCapacity = this.n_rows * this.n_columns;
  this.n_occupationRatio = this.n_frameCount / this.n_frameCapacity;
  if (i_orientation == COLUMN_ORIENTATION_VERTICAL) {
    var n_temp = this.n_columns;
    this.n_columns = this.n_rows;
    this.n_rows = n_temp;
  }
  this.o_ratio = new Ratio(
    o_videoRatio.w * this.n_columns,
    o_videoRatio.h * this.n_rows
  );
}

export function compileFrameCollection(n_frameCount, n_columns, i_mode) {
  switch (i_mode) {
    case MODE_UNIFORM:
    case MODE_EXPANDED_TALL:
      return new UniformFrameCollection(
        n_frameCount,
        n_columns,
        COLUMN_ORIENTATION_HORIZONTAL
      );

    case MODE_EXPANDED_WIDE:
      return new UniformFrameCollection(
        n_frameCount,
        n_columns,
        COLUMN_ORIENTATION_VERTICAL
      );

    case MODE_EXPANDED_MIDDLE:
    default:
      return undefined;
  }
}

// -----------------------------------------

export function LayoutOption(i_mode, o_parentRatio, n_frameCount, n_columns) {
  this.i_mode = i_mode;
  this.n_frameCount = n_frameCount;
  this.o_smallFrameCollection = compileFrameCollection(
    n_frameCount - (i_mode == MODE_UNIFORM ? 0 : 1),
    n_columns,
    i_mode
  );
  this.o_smallSectionRatio = o_parentRatio;
  this.n_occupationRatio = 1;
  switch (this.i_mode) {
    case MODE_EXPANDED_TALL:
      var n_maxSubdivisions = Math.min(
        this.o_smallFrameCollection.n_columns + 1,
        MAX_COUNT_COLUMNS
      );
      var n_expanded2content =
        1 + 1 / n_maxSubdivisions * this.o_smallFrameCollection.n_rows;
      var o_contentRatio = fitRatioInside(
        o_parentRatio,
        new Ratio(o_videoRatio.w, o_videoRatio.h * n_expanded2content)
      );
      this.o_expandedSectionRatio = new Ratio(
        o_parentRatio.w,
        o_contentRatio.h / n_expanded2content
      );
      this.o_smallSectionRatio = new Ratio(
        o_parentRatio.w,
        o_parentRatio.h - this.o_expandedSectionRatio.h
      );
      this.n_occupationRatio -=
        this.o_expandedSectionRatio.h /
        o_parentRatio.h *
        (1 - o_contentRatio.w / o_parentRatio.w);
      break;

    case MODE_EXPANDED_WIDE:
      var n_maxSubdivisions = Math.min(
        this.o_smallFrameCollection.n_rows + 1,
        MAX_COUNT_COLUMNS
      );
      var n_expanded2content =
        1 + 1 / n_maxSubdivisions * this.o_smallFrameCollection.n_columns;
      var o_contentRatio = fitRatioInside(
        o_parentRatio,
        new Ratio(o_videoRatio.w * n_expanded2content, o_videoRatio.h)
      );
      this.o_expandedSectionRatio = new Ratio(
        o_contentRatio.w / n_expanded2content,
        o_parentRatio.h
      );
      this.o_smallSectionRatio = new Ratio(
        o_parentRatio.w - this.o_expandedSectionRatio.w,
        o_parentRatio.h
      );
      this.n_occupationRatio -=
        this.o_expandedSectionRatio.w /
        o_parentRatio.w *
        (1 - o_contentRatio.h / o_parentRatio.h);
      break;

    case MODE_EXPANDED_MIDDLE:
      var o_contentRatio = fitRatioInside(o_parentRatio, o_videoRatio);
      var n_smallFrameLow = Math.floor((n_frameCount - 1) * 0.5);
      var n_smallFrameHigh = Math.ceil((n_frameCount - 1) * 0.5);
      var n_content2expanded = 1 / (n_smallFrameLow + 1) * n_smallFrameLow;
      var n_content2small = 1 / (n_smallFrameLow + 1);

      this.o_expandedSectionRatio = new Ratio(
        o_contentRatio.w * n_content2expanded,
        o_contentRatio.h * n_content2expanded
      );
      this.o_smallSectionRatio = new Ratio(
        o_contentRatio.w * n_content2small,
        o_contentRatio.h * n_content2small
      );

      var n_parentArea = o_parentRatio.getArea();
      this.n_occupationRatio -=
        (n_parentArea -
          this.o_expandedSectionRatio.getArea() -
          this.o_smallSectionRatio.getArea() *
            (n_smallFrameLow + n_smallFrameHigh)) /
        n_parentArea;
      return;
  }
  this.o_smallFrameCollection.o_ratio = fitRatioInside(
    this.o_smallSectionRatio,
    this.o_smallFrameCollection.o_ratio
  );
  var n_parentArea = o_parentRatio.getArea();
  var n_smallContentArea = this.o_smallFrameCollection.o_ratio.getArea();
  this.n_occupationRatio -=
    (this.o_smallSectionRatio.getArea() - n_smallContentArea) / n_parentArea;
  this.n_occupationRatio -=
    (1 - this.o_smallFrameCollection.n_occupationRatio) *
    n_smallContentArea /
    n_parentArea;
}

export function getBestLayoutOption(a_layoutOptions) {
  var bestOccupation = 0;
  var bestLayout = undefined;
  for (var i = 0; i < a_layoutOptions.length; i++) {
    if (a_layoutOptions[i] == undefined) {
      continue;
    }
    if (bestOccupation < a_layoutOptions[i].n_occupationRatio + i * 0.0001) {
      bestOccupation = a_layoutOptions[i].n_occupationRatio;
      bestLayout = a_layoutOptions[i];
    }
  }
  return bestLayout;
}
