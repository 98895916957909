
// State
const INITIAL_STATE: LoadingState = {};

// Actions
const SET_LOADING = 'piczel/loading/SET_LOADING';

// reducer
export default function loadingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        [action.section]: action.value,
      };

    default:
      return state;
  }
}

// Action creators
export function setLoading(section: string, value: boolean) {
  return {
    type: SET_LOADING,
    section,
    value,
  };
}

// selectors
export function isLoading(state: LoadingState, section: string): boolean {
  return state[section] === true;
}
