import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import classNames from 'classnames/bind';

import { followStream, fetchStream, getStreamByUsername } from '~/modules/streams';
import FormattedMessage from '~/components/common/FormattedMessage';

import Button from '..';

import styles from './FollowButton.scss';

const cx = classNames.bind(styles);

class FollowButton extends React.Component {
  constructor(props) {
    super(props);

    this.toggleFollow = this.toggleFollow.bind(this);
  }

  toggleFollow(e) {
    e.preventDefault();
    e.stopPropagation();

    const {
      dispatch,
      isSignedIn,
      stream,
    } = this.props;

    if (!isSignedIn) {
      window.location.href = '/login';
      return;
    }

    const isFollowing = stream.following !== undefined && stream.following.value;

    dispatch(followStream(stream.username, !isFollowing));
  }

  componentDidMount() {
    const {
      dispatch,
      username,
      stream
    } = this.props;

    if (!stream) dispatch(fetchStream(username));
  }

  render() {
    const { stream, className, small } = this.props;

    if (!stream) return null;

    const isFollowing = stream.following !== undefined && stream.following.value;

    return (
      <Button
        className={cx(className, { 'FollowButton--small': small })}
        color="black"
        onClick={e => this.toggleFollow(e)}
      >
        {isFollowing
          ? (
            <FormattedMessage
              id="FollowButton_Following"
              defaultMessage="Following"
            />
          )
          : (
            <FormattedMessage
              id="FollowButton_Follow"
              defaultMessage="Follow"
            />
          )}
      </Button>
    );
  }
}

FollowButton.propTypes = {
  small: propTypes.bool,
  username: propTypes.string.isRequired,
  isSignedIn: propTypes.bool.isRequired,
  className: propTypes.string,
  dispatch: propTypes.func.isRequired,
};

FollowButton.defaultProps = {
  small: false,
  className: null,
  stream: {
    following: { value: false },
  },
};

export default connect((state, ownProps) => ({
  isSignedIn: state.currentUser.isSignedIn,
  stream: getStreamByUsername(state, ownProps.username)
}))(FollowButton);
