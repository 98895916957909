import Config from '~/config';
import propTypes from 'prop-types';
import { sortById } from '~/helpers/data';
import { setLoading } from './loading';
import errorHandling from './_errorHandling';

/**
 * Emoticons
 */

// prop-type emoticon shape
export const emoticonProps = propTypes.shape({
  id: propTypes.number,
  image: propTypes.string.isRequired,
  nsfw: propTypes.bool,
  pattern: propTypes.string.isRequired,
  plain: propTypes.string.isRequired,
  username: propTypes.string,
});

/**
 * @type {EmoticonsState}
 */
const INITIAL_STATE = {
  byId: {},
  allIds: [],
};

export const GLOBAL = 'piczel/global';

// actions
export const SET_EMOTICON = 'piczel/emoticons/SET_EMOTICON';
export const REMOVE_EMOTICON = 'piczel/emoticons/REMOVE_EMOTICON';

export const UPDATE_EMOTICONS = 'piczel/emoticons/UPDATE_EMOTICONS';

// reducer
export default function emoticonsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_EMOTICONS:
      return sortById(state, action.payload);

    case SET_EMOTICON:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: action.payload,
        },
      };

    case REMOVE_EMOTICON:
      return {
        ...state,
        allIds: state.allIds.filter(id => id !== action.id),
      };

    default:
      return state;
  }
}

// action creators
export function updateEmoticons(payload) {
  return {
    type: UPDATE_EMOTICONS,
    payload,
  };
}

export function setEmoticon(id, payload) {
  return {
    type: SET_EMOTICON,
    id,
    payload,
  };
}

export function removeEmoticon(id) {
  return {
    type: REMOVE_EMOTICON,
    id,
  };
}

// async action creators
export function loadEmoticons(username = 'me') {
  return async function (dispatch, getState, fetch) {
    dispatch(setLoading('EMOTICONS', true));
    const response = await fetch(`${Config.api}/users/${username}/emoticons`);
    const json = await errorHandling(dispatch, response);

    if (response.ok && json) {
      dispatch(updateEmoticons(json));
    }

    dispatch(setLoading('EMOTICONS', false));
  };
}

export function loadUsersEmoticons() {
  return async function (dispatch, getState, fetch) {
    const response = await fetch(`${Config.api}/emoticons`);

    const json = await errorHandling(dispatch, response);

    if (response.ok && json) {
      dispatch(updateEmoticons(Object.values(json).flat()));
    }
  };
}

export function loadGlobalEmoticons() {
  return async function (dispatch, getState, fetch) {
    const response = await fetch(`${Config.api}/emoticons/global`);

    const json = await errorHandling(dispatch, response);

    if (response.ok && json) {
      dispatch(updateEmoticons(json.map(item => ({
        ...item,
        username: GLOBAL,
      }))));
    }
  };
}

export function uploadEmoticon(data) {
  return async function (dispatch, getState, fetch) {
    const response = await fetch(`${Config.api}/emoticons`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ emoticon: data }),
    });

    const json = await errorHandling(dispatch, response);

    if (response.ok && json) {
      if (data.global) json.username = GLOBAL;
      dispatch(updateEmoticons([json]));
    }

    return json;
  };
}

export function updateEmoticon(data) {
  return async function (dispatch, getState, fetch) {
    const response = await fetch(`${Config.api}/emoticons/${data.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emoticon: data,
      }),
    });

    const json = await errorHandling(dispatch, response);

    if (response.ok && json) {
      dispatch(setEmoticon(data.id, json));
    }
  };
}

export function deleteEmoticon(id) {
  return async function (dispatch, getState, fetch) {
    const response = await fetch(`${Config.api}/emoticons/${id}`, {
      method: 'DELETE',
    });

    const json = await errorHandling(dispatch, response);

    if (response.ok) {
      dispatch(removeEmoticon(id));
    }

    return json;
  };
}

/**
 * @returns {AsyncAction}
 */
export function loadMyEmoticons() {
  return async (dispatch, getState, fetch) => {
    const response = await fetch(`${Config.api}/users/me/emoticons`);

    const json = await response.json();

    return json;
  };
}

// selectors
export function getEmoticons(state) {
  return state.emoticons.allIds.map(id => state.emoticons.byId[id]);
}

/**
 * @param {RootState} state
 * @param {string} username
 */
export function getEmoticonsByUsername(state, username) {
  return getEmoticons(state).filter(emote => emote.username === username);
}

export function groupEmoticonsByUsername(state) {
  return getEmoticons(state).reduce((obj, item) => {
    if (!obj[item.username]) obj[item.username] = [];

    obj[item.username].push(item);
    return obj;
  }, {});
}

export function getGlobalEmoticons(state) {
  return getEmoticonsByUsername(state, GLOBAL);
}
