import React, {
  useContext, useCallback, useState, useRef, useEffect, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as emoji from '@piczel/emoji/frontend';
import { getCategory } from '@piczel/emoji';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import loadable from '@loadable/component';
import { ChatContext, events, roleLevels } from './ChatContext';

import {
  getActiveRoom, setModal, getColor, setPerformingAction, patchRoom,
} from '~/modules/chat';

import EmoticonPicker from './EmoticonPicker';
import Autocomplete from './Autocomplete';
import { processMacro } from './SlashCommandAutocomplete';
import CooldownDisplay from './CooldownDisplay';
import CharacterCountIndicator from './CharacterCountIndicator';

import styles from './MessageInput.scss';

const MarkdownPreview = loadable(() => import('./MarkdownPreview').then(module => ({ default: module.default })));

const cx = classNames.bind(styles);

const EmoticonButton = ({ onClick }) => {
  const [icon, setIcon] = useState(getCategory('people').emoji[0]);

  const changeIcon = useCallback(() => {
    const { emoji: people } = getCategory('people');

    setIcon(people[Math.floor(Math.random() * people.length - 1)]);
  }, []);

  return (
    <button onClick={onClick} title="Emoticons..." onFocus={changeIcon} onMouseOver={changeIcon} type="button" className={styles.MessageInput__Action}>
      <img src={emoji[icon]} alt="Emoticon" />
    </button>
  );
};

const INPUT_HEIGHT = 38;
const MAX_MESSAGE_LENGTH = 512;
const GLOBAL_COOLDOWN = 0.5;
const canvas = typeof document === 'undefined' ? null : document.createElement('canvas');
const drafts = {};

const MessageInput = ({ currentUser, disabled, width }) => {
  const intl = useIntl();
  const inputRef = useRef();

  const dispatch = useDispatch();
  const currentRoomId = useSelector(getActiveRoom);
  const activeRoom = useSelector(state => state.entities.chatRooms[currentRoomId]);
  const color = useSelector(getColor);
  const [hasCooldown, setHasCooldown] = useState(false);
  const [message, setMessage] = useState('');
  const [showingAnimation, setShowingAnimation] = useState(false);
  const [emoticonsOpen, setEmoticonsOpen] = useState(false);
  const [inputHeight, setInputHeight] = useState(INPUT_HEIGHT);
  const { client: socket } = useContext(ChatContext);
  const isAndroid = useMemo(() => /Android/.test(navigator.userAgent), []);

  useEffect(() => {
    if (drafts[currentRoomId]) setMessage(drafts[currentRoomId]);

    return () => {
      setMessage((currentMessage) => {
        drafts[currentRoomId] = currentMessage;

        return '';
      });
    };
  }, [currentRoomId]);

  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      input.focus();
    }
  }, [currentRoomId, inputRef]);

  useEffect(() => {
    const input = inputRef.current;
    const addCommandReply = (e) => {
      setMessage(`/w ${e.detail.to} `);

      setTimeout(() => {
        input.focus();
      }, 200);
    };
    if (input) {
      document.addEventListener('reply-message', addCommandReply);
    }

    return () => {
      if (input) {
        document.removeEventListener('reply-message', addCommandReply);
      }
    };
  }, [inputRef, setMessage]);

  useEffect(() => {
    if (!showingAnimation) return;

    const timeout = setTimeout(() => {
      setShowingAnimation(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [showingAnimation]);

  useEffect(() => {
    if (!hasCooldown) return;

    const cooldown = activeRoom.cooldown || GLOBAL_COOLDOWN;

    const timeout = setTimeout(() => {
      setHasCooldown(false);
    }, cooldown * 1000);

    return () => {
      clearTimeout(timeout);
      setHasCooldown((Date.now() - activeRoom.lastMessage) < cooldown);
    };
  }, [hasCooldown, activeRoom]);

  const onChange = useCallback((text) => {
    const inputWidth = parseInt(width, 10) - 8;

    if (canvas) {
      const ctx = canvas.getContext('2d');
      const previewMarkdown = document.querySelector('#previewMarkdown');
      const { font } = window.getComputedStyle(previewMarkdown);

      if (ctx) {
        ctx.font = font;
        const metrics = ctx.measureText(text);
        const lines = Math.ceil(metrics.width / inputWidth);
        if (lines <= 1) {
          setInputHeight(INPUT_HEIGHT);
        } else if (lines <= 7) {
          setInputHeight(`calc(${lines * 1.2}em + 18px)`);
        }
      }
    }

    // setMessage(text);
  }, [width]);

  const sendMessage = useCallback(() => {
    if (!socket) return;

    if (hasCooldown || message.length > MAX_MESSAGE_LENGTH) {
      return setShowingAnimation(true);
    }

    let messageText = processMacro(message).trim();

    /**
     * TODO: A better way to do this
     */
    if (messageText === '/raffle' && currentUser.permissions.raffle) {
      dispatch(setModal('raffle'));
      setMessage('');
      return;
    }

    if (messageText === '/help') {
      dispatch(setModal('help'));
      setMessage('');
      return;
    }

    if (messageText.startsWith('/spoiler') && messageText.length > 8) {
      messageText = `||${messageText.split('/spoiler ')[1]}||`;
    }

    const action = messageText.match(/^\/((kick)|(ban)) (\w+)$/);

    if (action) {
      dispatch(setPerformingAction(action[4], action[1]));
      setMessage('');

      return;
    }

    socket.emit(events.ADD_MESSAGE, {
      roomId: currentRoomId,
      color,
      message: {
        text: messageText,
      },
    });

    setMessage('');
    setInputHeight(INPUT_HEIGHT);

    dispatch(patchRoom({
      id: currentRoomId,
      lastMessage: Date.now(),
    }));


    const canBypassCooldown = currentUser && roleLevels[currentUser.role] >= roleLevels.mod;
    console.log(canBypassCooldown);

    if (!canBypassCooldown) {
      setHasCooldown(true);
    }
  }, [socket, message, currentRoomId, dispatch, color, hasCooldown, setMessage, currentUser, activeRoom]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    sendMessage();
  }, [sendMessage]);

  const focusInput = () => inputRef.current && inputRef.current.focus();

  const closeEmoticons = useCallback(() => {
    setEmoticonsOpen(false);
    focusInput();
  }, []);

  /**
   * @param {React.KeyboardEvent<HTMLTextAreaElement>} e
   */
  const onKeyDownCapture = (e) => {
    if ((e.key === 'Enter') && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }

    if (e.key === 'Tab') {
      e.preventDefault();
    }
  };

  const onCompositionEnd = (e) => {
    if (e.data.endsWith('\n')) {
      e.preventDefault();
      sendMessage();
    }
  };

  const sendMessageLabel = intl.formatMessage({ id: 'Chat_HelpMessage', defaultMessage: '/help to list commands' });

  const messageProgress = (message.length) / MAX_MESSAGE_LENGTH;

  const charactersRemaining = MAX_MESSAGE_LENGTH - (message.length);

  return (
    <div style={{ height: isAndroid ? inputHeight : 'Auto' }} className={cx('MessageInput', { 'MessageInput--hasCooldown': hasCooldown, 'MessageInput--showingAnimation': showingAnimation })}>
      <Autocomplete
        roomName={activeRoom.name}
        setMessage={setMessage}
        message={message}
        focusInput={focusInput}
      />

      <form
        className={styles.MessageInput__Form}
        onSubmit={onSubmit}
      >
        {
          isAndroid && (
            <textarea
              id="previewMarkdown"
              disabled={disabled || activeRoom.disabled}
              ref={inputRef}
              autoComplete="off"
              required
              onKeyDownCapture={onKeyDownCapture}
              onCompositionEnd={onCompositionEnd}
              placeholder={sendMessageLabel}
              className={styles.MessageInput__Field}
              maxLength={MAX_MESSAGE_LENGTH}
              name="chatMessage"
              spellCheck
              onChange={(e) => {
                const { value } = e.target;
                onChange(value);
                setMessage(value);
              }}
              value={message}
              title={sendMessageLabel}
            />
          )
        }

        {
          !isAndroid && (
            <MarkdownPreview
              disabled={disabled || activeRoom.disabled}
              onCompositionEnd={onCompositionEnd}
              setMessage={setMessage}
              message={message}
              ref={inputRef}
              onChange={(value) => {
                onChange(value);
                setMessage(value);
              }}
              className={styles.MessageInput__Field}
              placeholder={sendMessageLabel}
              maxLength={MAX_MESSAGE_LENGTH}
              name="chatMessage"
              onKeyDownCapture={onKeyDownCapture}
              title={sendMessageLabel}
            />
          )
        }
      </form>

      { hasCooldown && (
        <CooldownDisplay
          className={cx('MessageInput__Cooldown')}
          startTime={activeRoom.lastMessage}
          cooldown={activeRoom.cooldown || GLOBAL_COOLDOWN}
        />
      )}
      <div>
        {
          messageProgress > 0.6 && (
            <CharacterCountIndicator className={styles.MessageInput__CharacterCount} percent={messageProgress} charactersRemaining={charactersRemaining} />
          )
        }

        <div style={{ display: 'flex' }}>
          <EmoticonButton
            onClick={(e) => {
              e.stopPropagation();
              setEmoticonsOpen(true);
            }}
          />
          { !hasCooldown && (
            <button type="button" className={styles.MessageInput__Action} onClick={sendMessage}>
              <span role="img" className="ion-android-send" aria-label="Send" />
            </button>
          ) }
        </div>
      </div>
      { emoticonsOpen && <EmoticonPicker open={emoticonsOpen} onClose={closeEmoticons} setMessage={setMessage} roomName={activeRoom.name} /> }
    </div>
  );
};

MessageInput.propTypes = {
  currentUser: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  width: PropTypes.number,
};

MessageInput.defaultProps = {
  disabled: false,
};

export default React.memo(MessageInput);
