import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from '../Avatar';
import Button from '~/components/Button';
import confetti from 'canvas-confetti';
import { getCurrentRoom, getCurrentUser, getOptions, setShowingRaffleEntrants } from '~/modules/chat';

import styles from './RaffleWinner.scss';

const confettiCooldown = 5000;
let lastConfetti = null;

const cx = classNames.bind(styles);

const RaffleWinner = ({ winners, startedBy, raffleId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentRoom = useSelector(getCurrentRoom);
  const currentUser = useSelector(getCurrentUser);
  const options = useSelector(getOptions);

  useEffect(() => {
    if (!currentRoom.raffle || currentRoom.raffle.id !== raffleId) return;

    const currentUserWon = winners.some(winner => winner === currentUser.username);
    const notificationsEnabled = options.mentionNotifications;

    if (currentUserWon && notificationsEnabled) {
      const title = intl.formatMessage({
        id: 'RaffleWinner_NotificationTitle',
        defaultMessage: 'You won {startedBy}\'s raffle!',
      }, {
        startedBy,
      });
      new Notification(title, {
        tag: raffleId,
        icon: `https://piczel.tv/api/avatars/${startedBy}`,
      });
    }

    // show confetti
    const canvas = document.getElementById('Homebrew_Canvas');

    if (canvas) {
      if (!canvas.confetti) {
        canvas.confetti = confetti.create(canvas, {
          resize: true,
        });
      }

      if ((Date.now() - lastConfetti > confettiCooldown) && options.raffleWinnerAnimation) {
        lastConfetti = Date.now();

        canvas.confetti({
          particleCount: 120,
          spread: 70,
          gravity: 0.3,
          origin: {
            y: 1.2,
          },
        });
      }
    }
  }, []);

  return (
    <div className={cx('RaffleWinner')}>
      <div className={cx('RaffleWinner__Title')}>
        <FormattedMessage
          id="RaffleWinner_Title"
          defaultMessage="Raffle results (started by {startedBy})"
          values={{
            startedBy,
          }}
        />
      </div>

      <div className={cx('RaffleWinner__Title', 'RaffleWinner__Title--Light')}>
        <FormattedMessage
          id="RaffleWinner_Winners"
          defaultMessage="{winners, plural, =0 {no winner} one {# Winner} other {# Winners}}"
          values={{
            winners: winners.length,
          }}
        />
      </div>

      {
        winners.map(username => (
          <div key={username} className={cx('RaffleWinner__Winner')}>
            <Avatar username={username} size="26px" />

            <span className={cx('RaffleWinner__Winner--Username')}>
              {username}
            </span>
          </div>
        ))
      }
      
      {
        currentRoom.raffle && currentRoom.raffle.id === raffleId && (
          <Button onClick={() => dispatch(setShowingRaffleEntrants(true))} size="small">
            <FormattedMessage id="RaffleWinner_ShowEntrants" defaultMessage="Entrants" />
          </Button>
        )
      }
    </div>
  );
};

export default RaffleWinner;
