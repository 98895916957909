import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { setModal, getShowingRaffleEntrants } from '~/modules/chat';

import MessageInput from './MessageInput';
import RaffleTypeModal from './RaffleTypeModal';
import Help from './Help';
import RaffleParticipants from './RaffleParticipants';
import styles from './Homebrew.scss';

const cx = classNames.bind(styles);

const HomebrewFooter = ({ currentRoom, currentUser, modalOpen, connectionState, isMobile, unreadPM, width, defaultWidth}) => {
  const dispatch = useDispatch();
  const showingParticipants = useSelector(getShowingRaffleEntrants);

  return (
    <>
      <div className={styles.Homebrew__MessageInputWrapper}>
        {
          connectionState !== 'CONNECTED' && connectionState !== 'RECONNECT_FAILED' && (
            <div className={styles.Homebrew__ConnectionStatus}>
              <FormattedMessage id="Chat_Disconnected" defaultMessage="Chat disconnected, attempting to reconnect" />
            </div>
          )
        }
        {
          connectionState === 'RECONNECT_FAILED' && (
            <button type="button" onClick={() => window.location.reload()} className={cx('Homebrew__ConnectionStatus', 'Homebrew__ConnectionStatus--ReconnectFailed')}>
              <FormattedMessage id="Chat_ReconnectFailed" defaultMessage="Failed to reconnect, click here to reload" />
              &nbsp;
              <span role="img" className="ion-refresh" />
            </button>
          )
        }
        {
          connectionState === 'CONNECTED' && unreadPM && (
            <button type="button" className={cx('Homebrew__UnreadPMs')}>
              <FormattedMessage id="Chat_UnreadPM" defaultMessage="You have unread PMs" />
            </button>
          )
        }
        <MessageInput width={(isMobile ? `${window.innerWidth}px` : width) || `${defaultWidth}px`} disabled={connectionState !== 'CONNECTED'} currentUser={currentUser} />
      </div>
      { modalOpen === 'raffle' && <RaffleTypeModal users={currentRoom.users} close={() => dispatch(setModal(null))} />}
      { modalOpen === 'help' && <Help close={() => dispatch(setModal(null))} />}
      {
        currentRoom.raffle && showingParticipants && (
          <RaffleParticipants
            roomId={currentRoom.id}
            raffle={currentRoom.raffle}
            allowDeletion={!currentRoom.raffle.finished && currentUser.permissions.raffle}
          />
        )
      }
    </>
  );
};

export default HomebrewFooter;
