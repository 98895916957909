import fromEntries from './polyfills';
import { TOGGLE_STATES } from '~/components/AdultToggle';

/**
 * Normalizes an array of objects
 */
export function sortById(state, arr, identifier = 'Id') {
  const id = identifier.toLowerCase();

  const byId = arr.reduce((obj, item) => ({
    ...obj,
    [item[id]]: item,
  }), {});

  const allIds = arr.map(item => item[id]);

  return {
    [`by${identifier}`]: { ...state[`by${identifier}`], ...byId },
    [`all${identifier}s`]: [...new Set([...state[`all${identifier}s`], ...allIds])],
  };
}

/**
 * Removes duplicates from an array of objects
 */
export function removeDupes(obj) {
  return Array.from(new Set(obj.map(a => a.id)))
    .map(id => obj.find(a => a.id === id));
}

/**
 * Filters according to toggle state. Items need "adult" attribute.
 * Adds "blur" attribute to items with adult tag while on blur.
 * Returns an array.
 *
 * Backend filtering should be preferred, this is to add the "blur"!
 */
export function visibilityFilter(items, adultToggleState) {
  return items.reduce((array, item) => {
    if (item.adult || item.nsfw) {
      if (adultToggleState === TOGGLE_STATES.SFW) return array;
      if (adultToggleState === TOGGLE_STATES.BLUR) {
        array.push({
          ...item,
          blur: true,
        });

        return array;
      }
    }

    array.push(item);

    return array;
  }, []);
}

/**
 * Makes a shallow copy of an object
 * Useful for excluding certain keys from an object
 * @param {any} source The source object
 * @param {string[]} exclude List of object keys to be excluded from the copy
 */
export function copyObject(source, exclude = []) {
  const entries = Object.entries(source).filter(([key]) => exclude.indexOf(key) === -1);

  return fromEntries(entries);
}
