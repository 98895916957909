import React from 'react';
import classNames from 'classnames/bind';
import * as Sentry from '@sentry/browser';

import style from './index.scss';

const cx = classNames.bind(style);

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    Sentry.captureException(error);

    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      if (process.env.NODE_ENV !== 'development') {
        window.location.reload();
      }
    }, 5000);
  }

  render() {
    return (
      <div>
        <div className={cx('ErrorBoundary')}>
          <img alt="Error logo" src="/img/piczel-error.png" />
          <h1>Something went wrong</h1>

          <p>
            There was an error while processing your request, our developers have
            been notified and will be working on a fix shortly.
          </p>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary;
