import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FormattedDuration from 'react-intl-formatted-duration';
import classNames from 'classnames';

import styles from './CooldownDisplay.scss';

const CooldownDisplay = ({ startTime, cooldown, className }) => {
  const [progressPercent, setProgressPercent] = useState(0);

  useEffect(() => {
    const updateProgress = () => setProgressPercent(Math.max(1, Date.now() - startTime) / (cooldown * 1000));
    updateProgress();

    const interval = setInterval(updateProgress, 16);

    return () => {
      clearInterval(interval);
    };
  }, [cooldown, startTime]);

  const seconds = Math.round(cooldown * (1 - progressPercent));
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds - (minutes * 60);
 
  if (seconds < 0) return null;

  return (
    <div className={classNames(styles.CooldownDisplay, className)}>
      <div className={styles.CooldownDisplay__Message}>
        <FormattedMessage
          id="CooldownDisplay_TimeRemaining"
          defaultMessage="You must wait {cooldown} before sending another message"
          values={{
            cooldown: (
              <FormattedDuration
                seconds={seconds}
              />
            ),
          }}
        />
      </div>
      <span role="img" className="ion-android-stopwatch" />
      &nbsp;
      {minutes ? `${minutes}:` : null} 
      {secondsLeft || null} 
    </div>
  );
};

CooldownDisplay.propTypes = {
  startTime: PropTypes.number.isRequired,
  cooldown: PropTypes.number.isRequired,
};

export default CooldownDisplay;
