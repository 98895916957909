import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { setNotificationReminder } from '~/modules/chat';
import Button from '~/components/Button';
import { SimpleTooltip } from '~/components/Tooltip';
import Topic from './Topic';

import styles from './Homebrew.scss';

const styleDottedText = {
  borderBottom: '1px dotted white',
  display: 'inline-block',
};

const VoiceUsersText = () => (
  <span style={styleDottedText}>
    <FormattedMessage
      id="Chat_VoiceOnlyVoiceUsers"
      defaultMessage="voiced users"
    />
  </span>
);

const VoiceUsersTooltipMessage = () => (
  <>
    <FormattedMessage
      id="Chat_VoiceOnlyVoiceUsersTooltipMessage"
      defaultMessage="Mods can click a user to grant voice"
    />
  </>
);

const VoiceUsersTooltip = () => (
  <SimpleTooltip text={<VoiceUsersTooltipMessage />}>
    <VoiceUsersText />
  </SimpleTooltip>
);


const ChatRoomSubject = ({ onClick, notificationReminder, currentUser, currentRoom, setModeratedChat }) => {
  const dispatch = useDispatch();

  return (
    <div tabIndex={0} role="button" onClick={onClick} className={styles.Homebrew__RoomSubject}>
      {
        notificationReminder && (
          <div className={`${styles.Homebrew__NotificationReminder} ${styles.Homebrew__OverlayHidden}`}>
            <FormattedMessage id="Chat_NotificationReminder" defaultMessage="Turn on notifications" />

            <button
              type="button"
              onClick={() => {
                dispatch(setNotificationReminder(false));
                localStorage.setItem('notificationReminder', 'false');
              }}
              className={styles.Homebrew__NotificationReminder__Dismiss}
            >
              <FormattedMessage id="Chat_NotificatoinReminder_Dismiss" defaultMessage="Don't remind me" />
            </button>
          </div>
        )
      }

      {
        currentRoom.moderated && (
          <div className={`${styles.Homebrew__VoiceOnlyWarning} ${styles.Homebrew__OverlayHidden}`}>
            <div className={styles.Homebrew__VoiceOnlyWarningText}>
              <span role="img" className="ion-chatbubble" />
              &nbsp;
              <FormattedMessage
                id="Chat_VoiceOnlyNotice"
                defaultMessage="Only {typeUsers} can speak"
                values={{
                  typeUsers: <VoiceUsersTooltip />,
                }}
              />
            </div>

            {
              currentUser.username === currentRoom.name && (
                <Button className={styles.Homebrew__NotificationReminder__Dismiss} onClick={() => setModeratedChat(false)} color="transparent">
                  <FormattedMessage id="Chat_VoiceOnlyDisable" defaultMessage="Disable" />
                </Button>
              )
            }
          </div>
        )
      }
      <Topic
        currentRoom={currentRoom}
        currentUser={currentUser}
      />
    </div>
  );
};

export default ChatRoomSubject;
