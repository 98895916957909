import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { removeFlash } from '~/modules/flashes';
import FormattedMessage from '~/components/common/FormattedMessage';
import Button from '../Button';

import styles from './FlashDrawer.scss';

const c = classNames.bind(styles);

export const Flash = ({
  kind,
  message,
  autoclose,
  close,
  actions,
}) => {
  const [isFading, setFading] = useState(false);
  const closeTimeout = typeof autoclose === 'number' ? autoclose : 2000;

  const fadeOut = () => {
    setFading(true);
    setTimeout(close, closeTimeout);
  };

  useEffect(() => {
    if (autoclose) setTimeout(fadeOut, Math.max(150, closeTimeout - 250));
  }, []);

  const classes = c([
    'FlashItem',
    `Flash__${kind}`,
  ], {
    fade: isFading,
  });

  return (
    <div className={classes}>
      <p>
        {
        typeof message === 'string'
          ? message
          : (
            <FormattedMessage {...message} />
          )
        }
      </p>
      {
        actions && (
          actions.map(action => (
            <Button onClick={action.onClick ? (() => action.onClick(close)) : undefined} to={action.to} color="transparent">
              {
                typeof action.label === 'string'
                  ? (action.label)
                  : (
                    <FormattedMessage {...action.label} />
                  )
              }
            </Button>
          ))
        )
      }
      <i className="ion-close" onClick={close} />
    </div>
  );
};

Flash.propTypes = {
  kind: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  messageId: PropTypes.string,
  autoclose: PropTypes.bool,
  close: PropTypes.func.isRequired,
};

Flash.defaultProps = {
  messageId: null,
  autoclose: true,
};

const FlashDrawer = ({
  closeFlash,
  flashes,
}) => (
  <div className={styles.FlashDrawer}>
    {flashes.map(flash => <Flash close={() => closeFlash(flash.id)} key={flash.id} {...flash} />)}
  </div>
);

FlashDrawer.propTypes = {
  closeFlash: PropTypes.func.isRequired,
  flashes: PropTypes.arrayOf(PropTypes.shape({
    kind: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
      id: PropTypes.string,
      defaultMessage: PropTypes.string,
    })]).isRequired,
    messageId: PropTypes.string,
    autoclose: PropTypes.bool,
  })).isRequired,
};

class ConnectedFlashDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.deleteFlash = this.deleteFlash.bind(this);
  }

  deleteFlash(id) {
    const { dispatch } = this.props;

    dispatch(removeFlash(id));
  }

  render() {
    return <FlashDrawer {...this.props} closeFlash={this.deleteFlash} />;
  }
}

const mapStateToProps = state => ({
  flashes: state.flashes,
});

export default connect(mapStateToProps)(ConnectedFlashDrawer);
