import React from 'react';
import { FormattedMessage } from 'react-intl';
import { bool, func } from 'prop-types';

import Checkbox from '~/components/Form/Checkbox';

const StreamAdultToggle = ({ checked, onChange, ...props }) => (
  <Checkbox
    name="adult"
    checked={checked}
    onChange={onChange}
    {...props}
  >
    <FormattedMessage
      id="StreamBasic_Adult"
      defaultMessage="Mark my stream as {adult}"
      values={{
        adult: (
          <span title="Adult includes partial and full nudity, suggestive or sexual content, or any sort of disturbing content.">
            <FormattedMessage id="adult" defaultMessage="adult" />
          </span>
        ),
      }}
    />
  </Checkbox>
);

StreamAdultToggle.propTypes = {
  checked: bool.isRequired,
  onChange: func.isRequired,
};

export default StreamAdultToggle;
