import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import styles from './index.scss';

const InfoBox = ({ text, className, ...props }) => {
  const message = typeof text === 'string' ? text : <FormattedMessage {...text} />;
  return (
    <span {...props} className={`${styles.InfoBox} ${className}`}>{message}</span>
  );
};

InfoBox.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      defaultMessage: PropTypes.string,
    }),
  ]).isRequired,
  className: PropTypes.string,
};

InfoBox.defaultProps = {
  className: null,
};

export default InfoBox;
