import React, { useState, useRef, useEffect } from 'react';
import ChromePicker from 'react-color';

import styles from './ColorPicker.scss';

const ColorPicker = ({ children, color, setColor, className }) => {
  const [picking, setPicking] = useState(false);
  const ref = useRef(null);
  const [pickingColor, setPickingColor] = useState(color);

  useEffect(() => {
    if (!picking) return;

    function clickawayListener(e) {
      const container = ref.current;

      if (!container) return;

      if (!container.contains(e.target) && e.target !== container) setPicking(false);
    }

    function escListener(e) {
      if (e.keyCode === 27) setPicking(false);
    }

    document.addEventListener('keydown', escListener);
    document.addEventListener('click', clickawayListener);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('click', clickawayListener);
      document.removeEventListener('keydown', escListener);
    };
  }, [picking]);

  return (
    <div ref={ref} className={className}>
      <div className={styles.ColorPicker__Label}>
        { children }
      </div>
      <button style={{ backgroundColor: color }} onClick={() => setPicking(true)} type="button" className={styles.ColorPicker__Preview} />

      { picking && <ChromePicker disableAlpha className={styles.ColorPicker__Input} color={pickingColor} onChange={val => setPickingColor(val.hex)} onChangeComplete={val => setColor(val.hex)} />}
    </div>
  );
};

export default ColorPicker;
