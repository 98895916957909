/**
 * object fromentries polyfill for node
 * @param {[string, any][]} entries
 */
function fromEntries(entries) {
  return entries.reduce((obj, [key, value]) => {
    obj[key] = value;
 
    return obj;
  }, {});
}

/**
 * Currently exporting the fromEntries polyfill as default
 * if we add more stuff in the future this line should be changed to a named export
 */
export default Object.fromEntries || fromEntries;