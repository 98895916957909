/**
 * A new and imporved public user
 */
import { batch } from 'react-redux';
import { normalize, denormalize, schema } from 'normalizr';
import { createSelector } from 'reselect';
import config from '~/config';
import handleError from './_errorHandling';
import { sortById } from '~/helpers/data';
import { setLoading } from './loading';
import { setFromId, resetFromId } from './pagination';
import { updateImages, galleryImageSchema } from './gallery';
import { getUsersByName, updateUsers, userSchema } from './user';
import { setStreams as updateStreams, streamSchema } from './streams';
import { ADD_ENTITIES } from './entity';

// Schema
export const thingSchema = new schema.Union({
  galleryImages: galleryImageSchema,
  streams: streamSchema,
}, (input, parent) => (parent.type === 'gallery_image' ? 'galleryImages' : 'streams'));

export const postSchema = new schema.Entity('posts', {
  user: userSchema,
  thing: thingSchema,
});

export const feedSchema = new schema.Array(postSchema);

// State
const INITIAL_STATE = {
  byId: {},
  allIds: [],
};

// Consts
export const USER_FEED = 'piczel/feed/USER_FEED';

// Loading
export const FETCHING_FEED = 'piczel/feed/FETCHING_FEED';

// Actions
const RESET_FEED = 'piczel/feed/RESET_FEED';
const APPEND_FEED = 'piczel/feed/APPEND_FEED';

// Reducer
export default function feedReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_FEED:
      return INITIAL_STATE;

    case APPEND_FEED:
      return {
        byId: {
          ...state.byId,
          ...action.payload.byId,
        },
        allIds: [...state.allIds, ...action.payload.allIds],
      };

    default: return state;
  }
}

// Action creators
export function resetFeed() {
  return { type: RESET_FEED };
}

export function fetchFeed(opts = {}) {
  return function (dispatch, getState, fetch) {
    if (!opts.silent) dispatch(setLoading(FETCHING_FEED, true));
    let fetchUrl = `${config.api}/feed?hideNsfw=${opts.hideNsfw}`;

    if (opts.fromId) fetchUrl += `&from_id=${opts.fromId}`;
    if (opts.reset) {
      dispatch(resetFromId(USER_FEED));
      dispatch(resetFeed());
    }

    return fetch(fetchUrl)
      .then(res => handleError(dispatch, res))
      .then((feed) => {
        const {
          entities: {
            posts,
            ...entities
          },
          result: allIds,
        } = normalize(feed, feedSchema);
        
        batch(() => {
          dispatch({
            type: ADD_ENTITIES,
            payload: entities,
          });

          let newFromId = -1;

          if (posts) {
            dispatch({
              type: APPEND_FEED,
              payload: {
                allIds,
                byId: posts,
              },
            });

            newFromId = allIds[allIds.length - 1];
          }
          
          dispatch(setFromId(USER_FEED, newFromId));
          dispatch(setLoading(FETCHING_FEED, false));
        });
      });
  };
}

// Selectors
export const getFeed = createSelector(
  state => state.feed.allIds.map(id => state.feed.byId[id]),
  state => state.entities,
  (posts, entities) => posts.map(post => denormalize(post, postSchema, entities)),
);
