import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import List from '../List/List';
import ListItem from '../List/ListItem';
import Modal from './Modal';
import OptInRaffle from './OptInRaffle';
import Raffle from './Raffle';

const RaffleTypeModal = ({ close, users }) => {
  const [raffleType, setRaffleType] = useState(null);

  if (raffleType === 'manual') {
    return (
      <Raffle users={users} close={close} />
    );
  }

  if (raffleType === 'optin') {
    return (
      <OptInRaffle close={close} />
    );
  }

  return (
    <Modal title={<FormattedMessage id="RaffleType_Title" defaultMessage="Raffle type" />} close={close}>
      <List>
        <ListItem
          icon="✍️"
          title={<FormattedMessage id="RaffleType_Normal" defaultMessage="Manual raffle" />} 
          description={<FormattedMessage id="RaffleType_NormalDescription" defaultMessage="Let me choose who will participate" />}
          onClick={() => setRaffleType('manual')}
        />
        <ListItem
          icon="🗳️"
          title={<FormattedMessage id="RaffleType_OptIn" defaultMessage="Opt-in raffle" />}
          description={<FormattedMessage id="RaffleType_OptIn" defaultMessage="Participants enter themselves in the raffle" />}
          onClick={() => setRaffleType('optin')}
        />
      </List>
    </Modal>
  )  
}

export default RaffleTypeModal;
