/**
 * Constants used to refer to sections of the site
 *
 * Used in modules like loading and pagination
 */

/**
  * The streams in the stream page/list
  */
export const STREAMS_PAGE = 'streams';
export const STREAMS_PLAYER = 'piczel/watch/STREAMS_PLAYER';

// I don't think this should be here. Better to put it into the files of the views,
// modularity!
