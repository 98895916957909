import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  deleteComment, fetchCommentReplies, fetchComments, getCommentsById, sendComment,
} from '~/modules/comments';

import Button from '../Button';
import Modal from '../Modal';
import CommentBox from './Box';
import CommentList from './List';

/**
 * Top level container for comments on gallery images
 */
const CommentContainer = ({
  className,
  disallowComment,
  owner,
  type,
  id,
}) => {
  const [deleting, setDeleting] = useState(null);

  const dispatch = useDispatch();
  const postComment = (content, replyTo, commentHidden) => dispatch(sendComment(type, id, content, replyTo, commentHidden));

  useEffect(() => {
    dispatch(fetchComments(type, id));
  }, [type, id, dispatch]);

  const isSignedIn = useSelector(state => state.currentUser.isSignedIn);
  const currentUser = useSelector(state => state.currentUser.data);
  const comments = useSelector(state => getCommentsById(state, id));

  const loadReplies = commentId => dispatch(fetchCommentReplies(commentId));

  return (
    <div className={className}>
      {deleting && (
      <Modal isOpen={deleting} onRequestClose={() => setDeleting(null)}>
        <h1>
          <FormattedMessage id="Comment_ConfirmDeleteTitle" defaultMessage="Confirm Delete" />
        </h1>

        <div>
          <FormattedMessage id="Comment_ConfirmDelete" defaultMessage="Are you sure you want to delete this comment?" />
        </div>

        <br />
        <hr />
        <br />

        <Button onClick={() => setDeleting(null)}>
          <FormattedMessage id="Comment_ConfirmDeleteNo" defaultMessage="No" />
        </Button>

        <Button
          color="red"
          onClick={() => {
            dispatch(deleteComment(deleting));
            setDeleting(null);
          }}
        >
          <span role="img" className="ion-ios-trash" />
          &nbsp;
          <FormattedMessage id="Comment_ConfirmDeleteYes" defaultMessage="Yes" />
        </Button>
      </Modal>
      )}
      {isSignedIn && !disallowComment && <CommentBox currentUser={currentUser} sendComment={postComment} reply_to={null} sending={false} />}
      {comments && (
      <CommentList
        owner={owner}
        deleteComment={setDeleting}
        isSignedIn={isSignedIn}
        currentUser={currentUser}
        sendComment={postComment}
        loadReplies={loadReplies}
        comments={comments}
      />
      )}
    </div>
  );
};

CommentContainer.propTypes = {
  id: PropTypes.number.isRequired,
  className: PropTypes.string,
  disallowComment: PropTypes.bool,
  owner: PropTypes.string,
  type: PropTypes.string,
};

CommentContainer.defaultProps = {
  className: '',
  disallowComment: false,
  owner: null,
  type: null,
};

export default CommentContainer;
