/* node_modules */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { FormattedRelativeTime } from 'react-intl';

/* local imports */
import ServiceWorker from '~/helpers/serviceWorker';

import { removeNotification, clearNotifications } from '~/modules/notifications';
import {
  updateMultiRequest,
  updateSubscription,
} from '~/modules/currentUser';

import FormattedMessage from '~/components/common/FormattedMessage';
import Button from '~/components/Button';

/* style imports */
import styles from './NotificationBox.scss';
import { withAddStream } from '~/components/AddStream/Wrapper';

const cx = classNames.bind(styles);

export class Notification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.updateRequest = this.updateRequest.bind(this);
    this.acceptRequest = this.acceptRequest.bind(this);
    this.declineRequest = this.declineRequest.bind(this);

    this.remove = this.remove.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const {
      link,
    } = this.props;

    if (!link) return;

    setTimeout(() => {
      this.remove();
    }, 0);
  }

  /**
   * @param {boolean} [accept]
   */
  updateRequest(accept = true) {
    const {
      dispatch,
      thing: {
        id,
        host_stream: {
          slug: username,
        },
      },
    } = this.props;

    return dispatch(updateMultiRequest(username, id, accept)).then(() => this.remove());
  }

  acceptRequest(e) {
    e.stopPropagation();
    e.preventDefault();

    this.updateRequest(true);
  }

  declineRequest(e) {
    e.stopPropagation();
    e.preventDefault();

    this.updateRequest(false);
  }

  getActionButtons() {
    const {
      props: {
        notification_type: type,
        action,
        thing,
        count,
        link,
        location,
        addStreamEnabled
      },
      state: {
        isLoading,
      },
    } = this;

    if (type === 'multi' && action === 'invite' && !thing.accepted && count === 1) {
      return (
        <div className={cx(['Notification__Actions', 'Notification__Actions--multistream'])}>
          <Button size="small" color="black" onClick={this.acceptRequest}>
            <i className="ion-checkmark" />
            Accept
          </Button>
          <Button size="small" color="black" onClick={this.declineRequest}>
            <i className="ion-close" />
            Decline
          </Button>
        </div>
      );
    }

    if (type === 'premium') {
      return (
        <div className={cx(['Notification__Actions', 'Notification__Actions--premium'])}>
          <Button size="small" color="black" to={link}>
            <i className="ion-bag" />
            <FormattedMessage id="NotificationBox_PurchasePremium" defaultMessage="Purchase premium" />
          </Button>
        </div>
      );
    }

    if (type === 'premium-gift') {
      return (
        <div className={cx(['Notification__Actions', 'Notification__Actions--premium-gift'])}>
          <Button size="small" color="black" to="/account/patreon">
            <i className="ion-bag" />
            <FormattedMessage id="NotificationBox_Redeem" defaultMessage="Redeem" />
          </Button>
        </div>
      );
    }

    if (type === 'follow' && action === 'live') {
      const isWatchPage = location.pathname.startsWith('/watch');
      const AddStream = withAddStream(Button);

      return (
        <div className={cx(['Notification__Actions', 'Notification__Actions--follow'])}>
          {
            (isWatchPage && addStreamEnabled && thing) && (
              <AddStream stream={thing} size="small" color="black">
                <i className="ion-android-add" />
                <FormattedMessage id="NotificationBox_addToPage" defaultMessage="Add to page" />
              </AddStream>
            )
          }
          <Button size="small" color="black" to={link}>
            <i className="ion-ios-redo" />
            <FormattedMessage id="NotificationBox_goToStream" defaultMessage="Go to stream" />
          </Button>
        </div>
      );
    }
  }

  remove(e) {
    const {
      remove,
      id,
    } = this.props;

    if (e) e.stopPropagation();

    remove(id);
  }

  render() {
    const {
      props: {
        notification_type: type,
        removable,
        link,
        read,
        icon,
        text,
        location,
        created_at: createdAt,
      },
      state: {
        isLoading,
      },
    } = this;

    return (
      <li
        className={cx('Notification', {
          'Notification--unremovable': !removable,
          'Notification--unread': !read,
          'Notification--working': isLoading,
        })}
      >
        {React.createElement(link ? Link : 'div', {
          className: cx('Notification__Content', { 'Notification__Content--noIcon': !icon }),
          onClick: link ? this.onClick : undefined,
          to: link,
        }, (
          <Fragment>
            {icon && <img src={icon} className={cx('Notification__Icon')} />}
            {text && <div className={cx('Notification__Text')}>{link ? <Link to={link}>{text}</Link> : text}</div>}
            {this.getActionButtons()}
          </Fragment>
        ))}
        {removable && <i className={cx(['Notification__ButtonClose', 'ion-close-circled'])} onClick={this.remove} />}
        {createdAt && (
          <span className={cx`Notification__TimeAgo`}>
            <FormattedRelativeTime
              value={(new Date(createdAt).getTime() - new Date().getTime()) / 1000}
              numeric="auto"
              updateIntervalInSeconds={10}
            />
          </span>
        )}
      </li>
    );
  }
}

export class NotificationBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pushSubscribed: false,
      pushSupported: false,
    };

    this.removeAllNotifications = this.removeAllNotifications.bind(this);
    this.removeNote = this.removeNote.bind(this);
    this.togglePush = this.togglePush.bind(this);
  }

  removeAllNotifications() {
    const { dispatch } = this.props;

    dispatch(clearNotifications());
  }

  removeNote(notification) {
    const { dispatch } = this.props;

    dispatch(removeNotification(notification));
  }

  togglePush() {
    const { dispatch } = this.props;
    const { pushSubscribed } = this.state;

    ServiceWorker[pushSubscribed
      ? 'unsubscribe'
      : 'subscribe']().then((sub) => {
      dispatch(updateSubscription(sub, pushSubscribed));
      this.setState({ pushSubscribed: !pushSubscribed });
    });
  }

  componentDidMount() {
    const { dispatch } = this.props;

    if (ServiceWorker.isSupported()) {
      this.setState({ pushSupported: true });

      ServiceWorker.getSubscription().then((sub) => {
        const pushSubscribed = sub !== null;
  
        this.setState({ pushSubscribed });
        if (pushSubscribed) dispatch(updateSubscription(sub));
      });
    } 
    
  }

  render() {
    const {
      dispatch,
      notifications,
      location,
      currentUser,
      isFetching,
      addStreamEnabled,
    } = this.props;
    const { pushSupported, pushSubscribed } = this.state;

    let visibleNotifications = null;

    if (isFetching) {
      visibleNotifications = (
        <FormattedMessage id="NotificationBox_loading" defaultMessage="Loading...">
          {msg => <Notification removable={false} text={msg} />}
        </FormattedMessage>
      );
    }

    if (notifications.length > 0) {
      visibleNotifications = notifications.map((notif, i) => (
        <Notification
          {...notif}
          {...{
            dispatch, location, currentUser, addStreamEnabled,
          }}
          remove={this.removeNote}
          arrayId={i}
          key={notif.id}
        />
      ));
    }

    return (
      <div id="notif-box" className={styles.NotificationBox}>
        <div className={styles.NotificationBox__Title}>
          <span>
            <FormattedMessage id="NotificationBox_Notifications" defaultMessage="Notifications" />
          </span>
          <span className={styles.NotificationBox__ButtonClear} onClick={this.removeAllNotifications}>
            <FormattedMessage id="NotificationBox_Clear" defaultMessage="Clear" />
          </span>
        </div>
        <ul className={styles.NotificationBox__List}>
          {
            (notifications.length === 0 && !isFetching) && (
            <FormattedMessage id="NotificationBox_NoNew" defaultMessage="No new notifications">
              {msg => <Notification removable={false} text={msg} />}
            </FormattedMessage>
            )
          }
          {visibleNotifications}
        </ul>

        {pushSupported && (
        <div className={styles.NotificationBox__PushToggle}>
          <label>
            <FormattedMessage id="NotificationBox_PushNotification" defaultMessage="Push Notifications" />
            <input onChange={() => this.togglePush()} type="checkbox" checked={pushSubscribed} />
            <div className={styles.NotificationBox__PushTogglePlaceholder} />
          </label>
        </div>
        )}
      </div>
    );
  }
}

NotificationBox.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default NotificationBox;
