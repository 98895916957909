import { updateCurrentUser } from './currentUser';

//
// Content visibility state for both anon. and signed-in users.
//
// eslint-disable-next-line spaced-comment
/// <reference path="../types/index.d.ts" />

/**
* @enum {number}
*/
export const ToggleState = {
  SFW: 0,
  BLUR: 1,
  NSFW: 2,
};

export const SET_VIBILITY = 'SET_VIBILITY';

/**
 * @param {ToggleState} state
 * @param {import('redux').Action<string> & { payload: ToggleState }} action
 * @returns {ToggleState}
 */
export default (state = ToggleState.SFW, action) => {
  if (action.type === SET_VIBILITY) {
    return action.payload;
  }

  return state;
};

// action creator

/**
 * @param {ToggleState} visibility
 * @returns {import('~/types/state').AsyncAction}
 */
export function setContentVisibility(visibility) {
  return async (dispatch, getState) => {
    const { isSignedIn } = getState().currentUser;

    dispatch({
      type: SET_VIBILITY,
      payload: visibility,
    });

    if (isSignedIn) {
      return dispatch(updateCurrentUser({
        settings: {
          nsfw_filter: {
            state: visibility,
          },
        },
      }));
    }

    return visibility;
  };
}

/**
 * @param {import('~/types/state').RootState} state
 */
export function getVisibilitySettings(state) {
  if (state.currentUser.isSignedIn && state.currentUser.data) {
    return state.currentUser.data.settings.nsfw_filter.state;
  }

  return state.contentVisibility;
}
