import { combineReducers } from 'redux';

// Modules
import auth from '~/modules/auth';
import translations from '~/modules/translations';
import flashes from '~/modules/flashes';
import loading from '~/modules/loading';
import fromId from '~/modules/pagination';
import users from '~/modules/user';
import currentUser from '~/modules/currentUser';
import notifications from '~/modules/notifications';
import streams, { watchUIReducer } from '~/modules/streams';
import feed from '~/modules/feed';
import gallery from '~/modules/gallery';
import search from '~/modules/search';
import comments from '~/modules/comments';
import preferences from '~/modules/preferences';
import emoticons from '~/modules/emoticons';
import contentVisibility from '~/modules/contentVisibility';
import admin from '~/modules/admin';
import { errorReducer as status } from '~/modules/_errorHandling';
import chat from './chat';
import entities from './entity';
import mascotContestReducer from './mascotcontest';
import chatMessages from './chatMessages';
import holidayBanners from './holidayBanner';
import personalAccessTokens from './personalAccessTokens';

const rootReducer = combineReducers({
  auth,
  translations,
  flashes,
  loading,
  fromId,
  users,
  currentUser,
  notifications,
  streams,
  watchUI: watchUIReducer,
  feed,
  gallery,
  search,
  comments,
  preferences,
  emoticons,
  contentVisibility,
  admin,
  status,
  chat,
  entities,
  mascotContest: mascotContestReducer,
  chatMessages,
  holidayBanners,
  personalAccessTokens,
});

export default rootReducer;
