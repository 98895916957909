import React from 'react';
import PropTypes from 'prop-types';
import styles from './HideToggle.scss';

export default function HideToggle({ direction, className, onHide }) {
  return (
    <button
      className={`${styles.HideToggle} ${className || ''} ${styles[`toggle-${direction}`]}`}
      onClick={onHide}
      type="button"
      aria-label="Toggle stream description panel"
      title="Toggle stream description panel"
    />
  );
}

HideToggle.propTypes = {
  direction: PropTypes.string.isRequired,
  className: PropTypes.string,
  onHide: PropTypes.func.isRequired,
};

HideToggle.defaultProps = {
  className: '',
};
