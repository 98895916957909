import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './List.scss';

const cx = classNames.bind(styles);

const List = ({ className, children, ...props }) => (
  <div className={cx('List', className)} {...props}>
    { children }
  </div>
);

List.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

List.defaultProps = {
  className: null,
};

export default List;
