/**
 * A button that once clicked sends a multistream invite
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Default';
import { isLoading } from '~/modules/loading';
import { SENDING_MULTISTREAM_INVITE, sendMultiRequest } from '~/modules/currentUser';
import { FormattedMessage } from 'react-intl';
import { string } from 'prop-types';

/**
 * 
 * @type {React.FC<{username: string}>}
 */
const MultistreamButton = ({ username, ...props }) => {
  const dispatch = useDispatch();
  const invited = useSelector(state => state.currentUser.multistream.sent.findIndex(req => req.target_stream.slug === username) !== -1);
  const loading = useSelector(state => isLoading(state.loading, SENDING_MULTISTREAM_INVITE));

  let label = (
    <>
      <i className="ion-person-add" />
      {' '}
      <FormattedMessage id="Multistream_Button_Invite" defaultMessage="Invite to multistream" />
    </>
  );

  if (loading) {
    label = <FormattedMessage id="Multistream_Button_Loading" defaultMessage="Sending..." />;
  } else if (invited) {
    label = <FormattedMessage id="Multistream_Button_Invited" defaultMessage="Invite sent" />;
  }

  return (
    <Button data-loading={loading} disabled={loading || invited} color="black" size="small" onClick={() => dispatch(sendMultiRequest(username))} {...props}>
      { label }
    </Button>
  );
};

MultistreamButton.propTypes = {
  /**
   * Username of the stream to be invited
   */
  username: string.isRequired,
};

export default MultistreamButton;
