import React, { useReducer, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import styles from './Raffle.scss';
import Avatar from '../Avatar';
import Checkbox from '../Form/Checkbox';
import Button from '../Button';
import Modal from './Modal';

import { ChatContext, events } from './ChatContext';
import { getActiveRoom } from '~/modules/chat';

const raffleReducer = (state, action) => {
  switch (action.type) {
    case 'select':
      return state.map(user => (user.uid === action.uid ? { ...user, selected: true } : user));

    case 'deselect':
      return state.map(user => (user.uid === action.uid ? { ...user, selected: false } : user));

    case 'all':
      return state.map(user => ({ ...user, selected: action.value }));

    default:
      throw new Error(`unknown action ${action.type}`);
  }
};

/**
 * @type {React.FC<{ users: any[], close: () => void }>}
 */
const Raffle = ({ users, close }) => {
  const [state, dispatch] = useReducer(raffleReducer, users);
  const { client: socket } = useContext(ChatContext);
  const currentRoomId = useSelector(getActiveRoom);
  const doRaffle = useCallback(() => {
    if (!socket) return;

    socket.emit(events.ADD_MESSAGE, {
      roomId: currentRoomId,
      message: {
        text: `/raffle ${state.filter(user => user.selected).map(user => user.username).join(' ')}`,
      },
    });

    close();
  }, [socket, currentRoomId, state, close]);

  return (
    <Modal close={close} title={<FormattedMessage id="Raffle_Title" defaultMessage="Select users for raffle" />} className={styles.Raffle}>
      <Button className={styles.Raffle__SelectAll} onClick={() => dispatch({ type: 'all', value: !state.every(user => user.selected) })} color="black" size="small">
        <FormattedMessage id="Raffle_SelectAll" defaultMessage="Select all" />
      </Button>

      <div className={styles.Raffle__UserList}>
        {
          state.map(user => (
            <Checkbox className={styles.Raffle__Checkbox} key={user.uid} name={`User_${user.uid}`} checked={user.selected === true} onChange={e => dispatch({ type: e.target.checked ? 'select' : 'deselect', uid: user.uid })}>
              <div className={styles.Raffle__User}>
                <Avatar className={styles.Raffle__UserAvatar} username={user.username} />
                <b className={styles.Raffle__UserUsername}>
                  {user.username}
                </b>
              </div>
            </Checkbox>
          ))
        }
      </div>

      <Button onClick={doRaffle}>
        <FormattedMessage id="Raffle_Go" defaultMessage="Go" />
      </Button>
    </Modal>
  );
};

export default Raffle;
