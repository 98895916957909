// @ts-check
import { createConsumer } from '@rails/actioncable';
import { createContext } from 'react';
import Config from '~/config';
import { updateStream, addPrompting, fetchPlayingStream, setViewType, removeStream } from '~/modules/streams';
import { fetchNotifications } from '~/modules/notifications';
import { waitHLSPlaylistAvailable } from '~/helpers/util';

const USER_CHANNEL = 'UserChannel';
const STREAM_CHANNEL = 'StreamChannel';

const Cable = createConsumer(`${Config.api}/ws`);

if (typeof window !== 'undefined') window.$Cable = Cable;

/**
 * @type {React.Context<StreamSubscriptionGroup>}
 */
export const SubscriptionContext = createContext(null);

class Subscription {
  /**
   * @param {String} channel Name of the channel
   * @param {Object} params Any parameters required to connect to the channel
   */
  constructor(channel, params) {
    this.channel = channel;
    this.options = params;

    this.subscription = Cable.subscriptions.create({
      channel,
      ...params,
    }, {
      received: data => this.received(data),
    });

    /**
     * @type {{ [key: string]: ((data: any) => void)[]}}
     */
    this.handlers = {};
  }
 
  /**
   * @callback EventHandler
   * @param {Object} data The data sent along with the event
   * @returns {void}
   *
   * @param {String} event
   * @param {EventHandler} callback
   */
  on(event, callback) {
    if (this.handlers[event]) {
      if (this.handlers[event].indexOf(callback) === -1) {
        this.handlers[event].push(callback);
      }
    } else {
      this.handlers[event] = [callback];
    }

    return this;
  }

  /**
  * @param {string} event
  * @param {EventHandler} callback
  * @returns {this}
  */
  off(event, callback) {
    if (this.handlers[event]) {
      this.handlers[event] = this.handlers[event].filter(fn => fn === callback);
    }

    return this;
  }

  trigger(event, data) {
    if (this.handlers[event]) {
      this.handlers[event].forEach(callback => callback(data));
    }
  }

  received(data) {
    const event = data.type;

    if (!this.handlers[event]) {
      console.error(`No handler registered for websocket event "${event}"`);
      return;
    }

    this.trigger(event, data);
  }

  unsubscribe() {
    this.subscription.unsubscribe();
  }
}

export class UserSubscription {
  /**
   * @param {Number} userId
   * @param {import('redux').Dispatch} dispatch
   */
  constructor(userId, dispatch) {
    const subscription = new Subscription(USER_CHANNEL, {
      userId,
    });

    subscription.on('NEW_NOTIFICATIONS', () => {
      dispatch(fetchNotifications());
    }).on('STATUS_BAN', () => {
      console.log('You\'re banned, grats!');
    });

    this.subscription = subscription;
  }

  unsubscribe() {
    this.subscription.unsubscribe();
  }
}

export class StreamSubscription extends Subscription {
  /**
   * @param {Stream} stream
   * @param {import('redux').Dispatch} dispatch
   */
  constructor(stream, dispatch) {
    super(STREAM_CHANNEL, {
      streamId: stream.id,
    });
    this.stream = stream;

    this.interval = setInterval(() => {
      this.subscription.perform('view');
    }, 15000);
  
    this.on('VIEWERS_UPDATE', ({ count: viewers }) => {
      dispatch(updateStream(stream.username, {
        viewers,
      }));
    })
      .on('LIVE_CHANGE', async ({ live }) => {
        if (live) {
          await waitHLSPlaylistAvailable(stream);
        }

        dispatch(updateStream(stream.username, {
          live,
          live_since: live ? Date.now() : null,
        }));
      })
      .on('SET_STREAM_PRIVATE', () => {
        dispatch(addPrompting(stream.username));
      })
      .on('LEFT_MULTI', ({ peer_name: peerName }) => {
        dispatch(removeStream(peerName));
        console.warn('Reimplement chat event handler for LEFT_MULTI');
      })
      /**
       * The stream we're watching started hosting a multistream
       */
      .on('STARTED_MULTI', ({ peer_name: peerName }) => {
        console.warn('Reimplement chat event handler for STARTED_MULTI');
        dispatch(updateStream(stream.username, {
          in_multi: true,
        }));
        dispatch(fetchPlayingStream(peerName, true));
      })
      /**
       * The multistream we're watching added a member
       */
      .on('ADDED_MEMBER', ({ peer_name: peerName }) => {
        console.warn('Reimplement chat event handler for ADDED_MEMBER');
        dispatch(fetchPlayingStream(peerName, true));
      })
      /**
       * The stream we're watching joined another stream
       */
      .on('JOINED_MULTI', ({ peer_name: peerName }) => {
        console.warn('Reimplement chat event handler for JOINED_MULTI');
        dispatch(fetchPlayingStream(peerName));
        dispatch(updateStream(stream.username, {
          parent_streamer: peerName,
          in_multi: true,
        }));
      });
  }

  trigger(event, data) {
    console.log(event);
    
    if (this.handlers[event]) {
      this.handlers[event].forEach(callback => callback(data, this.stream));
    }
  }

  unsubscribe() {
    super.unsubscribe();
    clearInterval(this.interval);
  }
}

export default Cable;

export class StreamSubscriptionGroup {
  /**
   * @param {import('redux').Dispatch} dispatch
   * @param {Stream[]} streams
   */
  constructor(dispatch, streams = []) {
    console.log(`Streamsubscriptiongroupcalled`);
    
    this.handlers = [];

    this.streams = [];
    this.dispatch = dispatch;
    /**
     * @type {{[username: string]: StreamSubscription}}
     */
    this.subscriptions = {};

    if (streams.length) {
      this.sync(streams);
    }
  }

  /**
   * @param {Stream} stream
   */
  add(stream) {
    const { username } = stream;

    if (!(username in this.subscriptions)) {
      this.subscriptions[username] = new StreamSubscription(stream, this.dispatch);
      this.handlers.forEach(([event, handler]) => {
        this.subscriptions[username].on(event, handler);
      });
    }
  }

  /**
   * @param {Stream} stream
   */
  remove({ username }) {
    if (username in this.subscriptions) {
      this.subscriptions[username].unsubscribe();
      delete this.subscriptions[username];
    }
  }

  /**
   * Updates the current stream list to match newStreams
   *
   * Removes and adds websocket subscriptions as needed
   *
   * @param {Stream[]} newStreams
   */
  sync(newStreams) {
    const added = this.diff(this.streams, newStreams);
    const removed = this.diff(newStreams, this.streams);

    removed.forEach(stream => this.remove(stream));
    added.forEach(stream => this.add(stream));

    this.streams = newStreams;
  }

  /**
   * Attaches a new event handler
   * @param {string} event 
   * @param {(data: any) => void} handler 
   */
  on(event, handler) {
    this.handlers.push([ event, handler ]);
    Object.values(this.subscriptions).forEach((sub) => {
      sub.on(event, handler);
    });

    return this;
  }

  /**
   * Removes all listeners added through this class's .on method
   */
  off() {
    this.handlers.forEach(([event, handler]) => {
      Object.values(this.subscriptions).forEach((sub) => {
        sub.off(event, handler);
      });
    });

    return this;
  }

  /**
   * Removes all the current subscriptions
   */
  clearAll() {
    this.streams.forEach(stream => this.remove(stream));
  }

  /**
   * Compares 2 stream lists
   *
   * Returns an array of streams from streamsB that ARE NOT in streamsA
   *
   * @param {Stream[]} streamsA
   * @param {Stream[]} streamsB
   * @returns {Stream[]}
   */
  diff(streamsA, streamsB) {
    return streamsB.filter(streamB => streamsA.findIndex(streamA => streamA.id === streamB.id) === -1);
  }

  forEach(callback) {
    return Object.values(this.subscriptions).forEach(callback);
  }
}
