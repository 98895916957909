import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { signOut } from '~/modules/auth';
import { signOut as resetCurrentUser } from '~/modules/currentUser';
import { authHeaders } from '~/services/storage';
import { setAccessToken } from '~/modules/chat';

/**
 * @type {React.FC}
 */
const SignOut = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(signOut()).then(() => {
      authHeaders.destroy();
      dispatch(resetCurrentUser());
      dispatch(setAccessToken({ uid: null, acessToken: null }));
    });
  }, [dispatch]);

  return (
    <Navigate to="/" />
  );
};

export default SignOut;
