/* node_modules */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* style imports */
import styles from './Loading.scss';

const cx = classNames.bind(styles);

const Loading = ({ message, className, size, icon, color }) => (
  <div
    className={cx('Loading', className, {
      [`Loading--${size}`]: size,
    })}
  >
    <div className={cx('Loading__Icon')} style={{ backgroundImage: icon ? `url(${icon})` : undefined, backgroundColor: color, }} />
    {message && <p>{message}</p>}
  </div>
);

Loading.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
};

Loading.defaultProps = {
  message: null,
  className: null,
  size: null,
  icon: null,
  color: '#fff',
};

export default Loading;
