// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* class names here don't get mangled by css-loader
*/
.spoiler {
  transition: color 700ms, background-color 700ms;
}

.spoiler:not([aria-expanded="true"]) {
  line-height: 1.7em;
  color: #222;
  background-color: #222;
  border-radius: 4px;
  padding: 0;
  cursor: pointer;
  user-select: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Chat/Message.global.css"],"names":[],"mappings":"AAAA;;CAEC;AACD;EACE,+CAA+C;AACjD;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["/**\n* class names here don't get mangled by css-loader\n*/\n.spoiler {\n  transition: color 700ms, background-color 700ms;\n}\n\n.spoiler:not([aria-expanded=\"true\"]) {\n  line-height: 1.7em;\n  color: #222;\n  background-color: #222;\n  border-radius: 4px;\n  padding: 0;\n  cursor: pointer;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
