/**
 * A new and improved public user
 */
import { normalize, schema } from 'normalizr';
import config from '~/config';
import handleError from './_errorHandling';
import { setLoading } from './loading';
import { sortById } from '~/helpers/data';
import { ADD_ENTITIES } from './entity';

// Schema
export const userSchema = new schema.Entity('users', {}, {
  idAttribute: 'username',
});

// State
const INITIAL_STATE = {
  byUsername: {},
  allUsernames: [],
};

// Loading
export const FETCHING_USER = 'piczel/user/FETCHING_USER';

// Actions
const UPDATE = 'piczel/user/UPDATE';

// Reducer
export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE:
      return sortById(state, action.payload, 'Username');

    default: return state;
  }
}

// Action creators
export function updateUsers(users) {
  return { type: UPDATE, payload: users };
}

export function fetchUser(username) {
  return function (dispatch, getState, fetch) {
    dispatch(setLoading(FETCHING_USER, true));

    return fetch(`${config.api}/users/${username}?friendly=1`)
      .then(response => handleError(dispatch, response))
      .then((user) => {
        if (!user) return false;
        const normalized = normalize(user, userSchema);

        if (normalized.entities && normalized.entities.users) {
          dispatch(({
            type: ADD_ENTITIES,
            payload: {
              users: {
                [username.toLowerCase()]: normalized.entities.users[user.username],
              },
            },
          }));
        }

        dispatch(setLoading(FETCHING_USER, false));

        return user;
      });
  };
}

// Selectors
export function getUserByName(state, username) {
  return state.entities.users[username.toLowerCase()];
}

export function getUsersByName(state) {
  return state.entities.users;
}
