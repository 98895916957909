import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { commands, useCommandsAvailable } from './SlashCommandAutocomplete';
import Modal from './Modal';

import styles from './Help.scss';

const Help = ({ close }) => {
  const commandsAvailable = useCommandsAvailable();

  return (
    <Modal close={close} title={<FormattedMessage id="Chat_HelpTitle" defaultMessage="Commands available" />}>
      {
        commandsAvailable.map(command => (
          <div className={styles.Help__Item}>
            <div className={styles.Help__ItemCommand}>
              /
              {command}
            </div>

            <div className={styles.Help__ItemDescription}>
              { commands[command].description }
            </div>
          </div>
        ))
      }
    </Modal>
  );
};

Help.propTypes = {
  close: PropTypes.func.isRequired,
};

export default Help;
