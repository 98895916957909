import propTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { getOptions } from '~/modules/chat';


import styles from './Resizer.scss';

const cx = classNames.bind(styles);

function Resizer({ setWidth, containerId }) {
  const [active, setActive] = useState(false);
  const { viewLayout } = useSelector(getOptions);

  const startDrag = useCallback(() => setActive(true), []);
  const stopDrag = useCallback(() => {
    setActive(false);

    const container = document.getElementById(containerId);

    if (container) {
      setWidth(container.style.width);
    }
  }, [setWidth, containerId]);

  useEffect(() => {
    const container = document.getElementById(containerId);
    if (!active || !container) return;

    const moveHandler = (e) => {
      requestAnimationFrame(() => {
        container.style.width = `${window.innerWidth - e.clientX}px`;
      });
    };

    document.addEventListener('mousemove', moveHandler);
    document.addEventListener('mouseup', stopDrag);
    document.addEventListener('click', stopDrag);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('mousemove', moveHandler);
      document.removeEventListener('mouseup', stopDrag);
      document.addEventListener('click', stopDrag);
    };
  }, [active, containerId, stopDrag]);

  return (
    <button
      type="button"
      tabIndex={0}
      aria-label="Resize chat"
      className={cx('Resizer__Handle', { mobile: viewLayout === 'Mobile', theater: viewLayout === 'Theater' })}
      onMouseDown={startDrag}
    />
  );
}

Resizer.propTypes = {
  setWidth: propTypes.func.isRequired,
  containerId: propTypes.string.isRequired,
};

export default React.memo(Resizer);
