import React from 'react';
import { shape, string } from 'prop-types';

import styles from './index.scss';

/**
 * @param {{stream: Stream}} props
 */
export default function StreamBanner({ stream }) {
  const banner = (<div className={styles.StreamBanner} style={{ backgroundImage: `url(${stream.banner.url})` }} />);

  if (stream.banner_link) {
    return (
      <a target="_blank" rel="noopener noreferrer" onClick={(e) => !confirm('Navigating to an external site, click OK to continue') && e.preventDefault()} href={stream.banner_link}>
        {banner}
      </a>
    );
  }

  return banner;
}

StreamBanner.propTypes = {
  stream: shape({
    banner_link: string,
    banner: shape({
      banner: shape({
        url: string,
      }),
    }),
  }).isRequired,
};
