import React, { createRef } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import propTypes from 'prop-types';

/* local imports */
import config from '~/config';
import { UserSubscription } from '~/services/cable';

import {
  fetchNotifications,
  allNotifications,
  gotNewNotifications,
  LOADING_NOTIFICATIONS,
} from '~/modules/notifications';
import { updateCurrentUser } from '~/modules/currentUser';
import { getPlayingStreams, addStreamEnabled, startPlayingStreams, setPlayingStreams } from '~/modules/streams';
import { addFlash } from '~/modules/flashes';
import { isLoading } from '~/modules/loading';

import FormattedMessage from '~/components/common/FormattedMessage';
import QuickSettings from '~/components/QuickSettings';
import Avatar from '~/components/Avatar';
import { NotificationBox } from './NotificationBox';
import LanguageSelector from './LanguageSelector';
import { withRouter } from '~/hooks';

/* style imports */
import styles from './ProfileNav.scss';
import headerStyles from '../Header.scss';

const cx = classNames.bind(styles);

/**
 * ProfileNav to show when user is logged in
 */
class ProfileNavUser extends React.Component {
  static propTypes = {
    currentUser: propTypes.shape({
      id: propTypes.number,
    }).isRequired,
    dispatch: propTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      expandedQuickSettings: false,
      expandedNavigation: false,
    };

    this.subscription = null;
    this.submitUser = this.submitUser.bind(this);
    this.expanded = createRef();
  }

  componentDidMount() {
    const {
      dispatch,
      currentUser,
    } = this.props;

    /**
     * @param {MouseEvent} e
     */
    this.clickListener = (e) => {
      const { expandedNavigation } = this.state;
      if (expandedNavigation && !this.expanded.current.contains(e.target)) {
        this.setState({
          expandedNavigation: false,
        });
      }
    };

    window.addEventListener('click', this.clickListener);
    this.subscription = new UserSubscription(currentUser.id, dispatch);
    dispatch(fetchNotifications());
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.clickListener) {
      window.removeEventListener('click', this.clickListener);
    }
  }

  handleImageClick(e) {
    const { dispatch } = this.props;
    const { expandedNavigation } = this.state;

    e.stopPropagation();

    this.setState({
      expandedNavigation: !expandedNavigation,
    });

    if (!expandedNavigation) dispatch(fetchNotifications());
  }

  checkIfOnMyStream(e) {
    const { dispatch, currentUser } = this.props;
    if (location.pathname === e.target.pathname) {
      e.preventDefault();
      e.stopPropagation();
      dispatch(setPlayingStreams([]));
      dispatch(startPlayingStreams([currentUser.username]));
    }
  }

  submitUser(data) {
    const { dispatch } = this.props;

    if (typeof data.stream.tags === 'string' && data.stream.tags !== '') { data.stream.tags = data.stream.tags.split(','); }

    dispatch(updateCurrentUser(data)).then(() => {
      dispatch(addFlash('success', 'Your settings have been saved', true));
    }).catch((err) => {
      if (err.message) {
        dispatch(addFlash('error', err.message));
      } else {
        for (const error_field in err) {
          if (Array.isArray(err[error_field])) {
            for (const error of err[error_field]) {
              dispatch(addFlash('error', error));
            }
          } else {
            dispatch(addFlash('error', err[error_field]));
          }
        }
      }
    });
  }

  render() {
    const {
      dispatch,
      currentUser,
      currentLocale,
      notifications,
      alert,
      location,
      navigate,
      isLoading,
    } = this.props;

    const {
      expandedNavigation,
      expandedQuickSettings,
    } = this.state;

    const { username } = currentUser;

    return (
      <nav className={cx(['ProfileNav', 'ProfileNav--anonymous'])}>
        <LanguageSelector {...{ dispatch, currentLocale }} />

        <div
          onClick={this.handleImageClick.bind(this)}
          className={cx(['ProfileNav__Picture'], {
            'ProfileNav__Picture--alert': alert,
          })}
        >
          <Avatar username={currentUser.username} userId={currentUser.id} size="40px" />
        </div>

        {expandedNavigation && (
        <div ref={this.expanded} className={styles.ProfileNavExpandable}>
          <div className={styles.ProfileNavExpandable__AccountLinks}>
            <NavLink to={`/gallery/${username}`}>
              {' '}
              <i className="ion-images" />
              {' '}
              <FormattedMessage id="ProfileNav_Gallery" defaultMessage="My Gallery" />
            </NavLink>
            <NavLink onClick={e => this.checkIfOnMyStream(e)} to={`/watch/${username}`}>
              {' '}
              <i className="ion-person" />
              {' '}
              <FormattedMessage id="ProfileNav_Stream" defaultMessage="My Stream" />
            </NavLink>
            <NavLink to="/account">
              <i className="ion-gear-b" />
              {' '}
              <FormattedMessage id="ProfileNav_Account" defaultMessage="Account" />
            </NavLink>
            {currentUser.role == 'admin' && (
            <NavLink to="/admin">
              <i className="ion-settings" />
              {' '}
              <FormattedMessage id="ProfileNav_Admin" defaultMessage="Admin" />
            </NavLink>
            )}
            <NavLink to="/signout">
              <i className="ion-android-exit" title="Sign out" />
              {' '}
              <FormattedMessage id="ProfileNav_SignOut" defaultMessage="Sign out" />
            </NavLink>
          </div>
          <div className="quick_settings">
            <button className={styles.QuickSettings__Link} type="button" onClick={() => this.setState({ expandedQuickSettings: !expandedQuickSettings })}>
              <i className="ion-settings" />
              <FormattedMessage id="ProfileNav_Quicksettings" defaultMessage="Quick Settings" />
            </button>
            { expandedQuickSettings
              && (
                <QuickSettings />
              )
            }
          </div>
          <NotificationBox
            {...{
              dispatch,
              location,
              navigate,
              notifications,
              addStreamEnabled,
            }}
            isFetching={isLoading}
          />
        </div>
        )}
      </nav>
    );
  }
}

/**
 * ProfileNav to show when user is not logged in
 * @type {React.FC}
 */
const ProfileNavAnonymous = (props) => {
  const { dispatch, currentLocale, location } = props;
  const referralParam = `?referral=${location.pathname}`;

  return (
    <nav className={cx(['ProfileNav', 'ProfileNav--anonymous'])}>
      <LanguageSelector {...{ dispatch, currentLocale }} />
      <NavLink to={`/login${referralParam}`} className={({ isActive }) => (isActive ? headerStyles.Header__ActiveLink : '')}><FormattedMessage id="ProfileNav_Login" defaultMessage="Login" /></NavLink>
      <NavLink to="/signup" className={({ isActive }) => (isActive ? headerStyles.Header__ActiveLink : '')}><FormattedMessage id="ProfileNav_Signup" defaultMessage="Sign up" /></NavLink>
    </nav>
  );
};

/**
 * Renders conditionally: Anonymous or not
 * @type {React.FC}
 */
const ProfileNav = (props) => {
  const {
    isSignedIn,
    currentUser,
  } = props;

  if (isSignedIn && currentUser) {
    return (
      <ProfileNavUser
        {...props}
      />
    );
  }

  return <ProfileNavAnonymous {...props} />;
};

const mapStateToProps = (state) => {
  const currentUser = state.currentUser.data;
  const notifications = allNotifications(state.notifications);

  return ({
    isSignedIn: state.currentUser.isSignedIn,
    currentUser,
    currentLocale: state.translations.currentLocale,
    notifications,
    alert: gotNewNotifications(state.notifications),
    watching: getPlayingStreams(state),
    addStreamEnabled: addStreamEnabled(state),
    isLoading: isLoading(state.loading, LOADING_NOTIFICATIONS),
  });
};

export default withRouter(connect(mapStateToProps)(ProfileNav));
