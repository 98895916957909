import React, { useMemo } from 'react';
import { arrayOf, shape, string, number, func, bool } from 'prop-types';
import className from 'classnames/bind';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import SidePane, { Collapsible } from './SidePane';
import { getBlockList } from '~/modules/chat';

import styles from './ChatRoster.scss';

const cx = className.bind(styles);

/**
 * weights used for sorting, higher values mean higher on the user list
 */
const knownRoles = {
  admin: 100,
  streamer: 90,
  mod: 80,
  visitor: 10,
  echo: 1,
  banned: 0,
};

const weight = role => knownRoles[role] || 50;

const roleLabels = {
  echo: {
    id: 'ChatRole_Echo',
    defaultMessage: 'Shadowbanned',
  },
};

const filterRepeated = array => {
  let usernames = {};
  return array.filter(user => {
    if(!usernames[user.username]){
      usernames[user.username] = true;
      return true;
    }
    return false;
  })
}

const ChatRoster = ({ users, isOpen, setOverview, members }) => {
  const intl = useIntl();
  const blockList = useSelector(getBlockList);
  const blockedUsers = useMemo(() => {
    const blocked = {};

    blockList.forEach((user) => {
      if (user) {
        blocked[user.uid] = user;
      }
    });

    return blocked;
  }, [blockList]);
  
  const usersGrouped = useMemo(() => {
    /**
     * @type {string[]}
     */
    const groups = users.reduce((roles, user) => {
      if (roles.indexOf(user.role) === -1) roles.push(user.role);

      return roles;
    }, []);

    const grouped = {};

    groups.sort((a, b) => weight(b) - weight(a));

    groups.forEach((group) => {
      const groupUsers = users.filter(user => user.role === group);
      groupUsers.sort((a, b) => (a.username.toLowerCase() > b.username.toLowerCase() ? 1 : -1));

      grouped[group] = groupUsers;
    });

    return grouped;
  }, [users]);

  /**
   * Currently we only want to show banned users regardless of their presence in the room
   */
  const banned = useMemo(() => (members ? filterRepeated(members.filter(user => user.role === 'banned')) : []), [members]);
  const echo = useMemo(() => (members ? members.filter(user => user.role === 'echo' || user.serverRole === 'echo') : []), [members]);

  return (
    <SidePane className={styles.ChatRoster} isOpen={isOpen}>
      <div className={styles.ChatRoster__UserList}>
        {
          Object.keys(usersGrouped).map(role => (
            <div className={styles.ChatRoster__Group} key={role}>
              <div className={styles.ChatRoster__GroupTitle}>
                { (role in roleLabels) ? intl.formatMessage(roleLabels[role]) : role }
                &nbsp;
                &mdash;
                &nbsp;
                {usersGrouped[role].length}
              </div>
              {
                usersGrouped[role].map(user => (
                  <button title={process.env.NODE_ENV === 'development' ? user.uid : user.username} type="button" onClick={e => setOverview(user, e)} className={cx('ChatRoster__UserListItem', { 'ChatRoster__UserListItem--Blocked': user.userId in blockedUsers })} key={user.uid}>
                    {/* <Avatar username={user.username} className={styles.ChatRoster__UserListItemAvatar} /> */}
                    <b className={styles.ChatRoster__UserListItemUsername}>{user.username}</b>
                  </button>
                ))
              }
            </div>
          ))
        }

        {
        echo.length > 0 ? (
          <Collapsible
            startOpen={false}
            title={(
              <>
                { intl.formatMessage(roleLabels.echo) }
                &nbsp;
                &mdash;
                &nbsp;
                {echo.length}
              </>
            )}
          >
            {
              echo.map(user => (
                <button type="button" onClick={e => setOverview(user, e)} className={cx('ChatRoster__UserListItem', { 'ChatRoster__UserListItem--Blocked': user.userId in blockedUsers })} key={user.uid}>
                  <b className={styles.ChatRoster__UserListItemUsername}>
                    { user.username }
                  </b>
                </button>
              ))
            }
          </Collapsible>
        ) : null
      }

        {
        banned.length > 0 ? (
          <Collapsible
            startOpen={false}
            title={(
              <>
                <FormattedMessage id="ChatRoster__Banned" defaultMessage="Banned" />
                &nbsp;
                &mdash;
                &nbsp;
                {banned.length}
              </>
            )}
          >
            {
              banned.map(user => (
                <button type="button" onClick={e => setOverview(user, e)} className={cx('ChatRoster__UserListItem', { 'ChatRoster__UserListItem--Blocked': user.userId in blockedUsers })} key={user.uid}>
                  <b className={styles.ChatRoster__UserListItemUsername}>
                    { user.username }
                  </b>
                </button>
              ))
            }
          </Collapsible>
        ) : null
      }
      </div>
    </SidePane>
  );
};

ChatRoster.propTypes = {
  users: arrayOf(shape({
    uid: string,
    username: string,
    piczel_user_id: number,
    role: string,
  })).isRequired,
  members: arrayOf(shape({
    uid: string,
    username: string,
    piczel_user_id: number,
    role: string,
  })),
  isOpen: bool.isRequired,
  setOverview: func.isRequired,
};

ChatRoster.defaultProps = {
  members: [],
};

export default ChatRoster;
