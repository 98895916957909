import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import HideToggle from '../../HideToggle';
import ShareButtons from './ShareButtons';
import Button, { FollowButton } from '~/components/Button';
import FormattedMessage from '~/components/common/FormattedMessage';
import AddStreamList from '../../AddStream/AddStreamList';
import { ReactMarkdownWithHtml } from "~/components/Markdown";

import styles from './Description.scss';
import AddStream from '~/components/AddStream';
import { setAddingStream, addStreamEnabled } from '~/modules/streams';
import RecordingPicker from '~/components/RecordingPicker';

const cx = classNames.bind(styles);

export class StreamTags extends React.Component {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
    })).isRequired,
  };

  render() {
    return (
      <div className={styles.streamTags}>
        {this.props.tags.map((x, i) => (
          <Link
            className={styles.streamTag}
            key={i}
            to={`/search/streams/${encodeURIComponent(x.title)}`}
          >
            <i className="ion-pricetag" />
            {' '}
            {x.title}
          </Link>
        ))}
      </div>
    );
  }
}
export class StreamDescription extends React.Component {
  handleClick(e) {
    let elem = e.target;

    if (elem.parentElement.href) {
      elem = elem.parentElement;
    }

    if (elem.href) {
      elem.target = '_blank';
    }
  }

  render() {
    const {
      stream, name, title, tags, content,
    } = this.props;
    return (
      <div className={styles.StreamDescription}>
        <div className={styles.StreamDescription__Header}>
          <h2 className={styles.descriptionTitle}>{title}</h2>
          <ShareButtons name={name} title={title} />

          <div className={styles.followerCount}>
            <b>{stream.user.follower_count}</b>
            <p>
              <FormattedMessage
                id="SocialFollowers_Subheading"
                defaultMessage="Followers"
              />
            </p>
          </div>
          <Button to={`/gallery/${stream.user.username}`}>
            <FormattedMessage
              id="StreamDescription_GoToGallery"
              defaultMessage="{name}'s Gallery"
              values={{ name: stream.user.username }}
            />
          </Button>
          <FollowButton username={stream.user.username} />
          {stream.recordings.length > 0 && (
              <RecordingPicker stream={{
                ...stream,
                recordings: [...stream.recordings].reverse(),
              }} />
          )}
        </div>
        <div
          onClick={e => this.handleClick(e)}
          className={styles.StreamDescription__Content}
        >
          <ReactMarkdownWithHtml children={content} />
        </div>

        {tags.length ? <StreamTags tags={tags} /> : null}
      </div>

    );
  }
}

export class StreamDescriptionNav extends React.Component {
  static propTypes = {
    setActive: PropTypes.func.isRequired,
    toggleAdding: PropTypes.func.isRequired,
    addingStream: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    addStreamDisabled: PropTypes.bool.isRequired,
  }

  handleClick(index) {
    this.props.setActive(index);
  }

  render() {
    const {
      children, addingStream, toggleAdding, addStreamDisabled,
    } = this.props;

    return (
      <div className={styles.streamDescriptionNav}>
        {this.props.streams.map((stream, i) => (
          <button
            role="tab"
            type="button"
            key={stream.id}
            onClick={this.handleClick.bind(this, i)}
            className={cx(['navLink'], { navLinkActive: i == this.props.activeItem })}
          >
            {stream.user.username}
          </button>
        ))}

        {children}
        <AddStream disabled={addStreamDisabled} adding={addingStream} toggleAdding={toggleAdding} />
      </div>
    );
  }
}

export class StreamDescriptionContainer extends React.Component {
  static propTypes = {
    addingStream: PropTypes.bool.isRequired,
    descHidden: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      'premium?': PropTypes.bool,
    }),
    streams: PropTypes.arrayOf(PropTypes.shape({
      username: PropTypes.string,
    })).isRequired,
    setAdding: PropTypes.func.isRequired,
    addStreamDisabled: PropTypes.bool.isRequired,
    viewLayout: PropTypes.string.isRequired,
  }

  static defaultProps = {
    user: {
      'premium?': false,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      activeItem: 0, // Index 0
    };
  }

  setActive(index) {
    this.setState({ activeItem: index });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { streams } = nextProps;
    const { activeItem } = this.state;

    if (!streams[activeItem]) {
      this.setState({
        activeItem: 0,
      });
    }
  }

  render() {
    const containerStyle = {
      height: null,
    };
    const {
      descHidden, addingStream, user, streams, setAdding, addStreamDisabled,
      viewLayout,
    } = this.props;

    const activeItem = streams[this.state.activeItem];
    const activeDescription = (
      <StreamDescription
        stream={activeItem}
        name={activeItem.user.username}
        tags={activeItem.tags}
        content={activeItem.description}
        title={activeItem.title}
      />
    );


    return (
      <div
        style={containerStyle}
        className={`${cx('StreamDescription__Container', { mobile: viewLayout === 'Mobile', desktop: viewLayout === 'Desktop', theater: viewLayout === 'Theater' })} ${cx({ hide: descHidden && viewLayout !== 'Theater' })}`}
      >
        <StreamDescriptionNav
          user={user}
          toggleAdding={() => setAdding(!addingStream)}
          addStreamDisabled={addStreamDisabled}
          addingStream={addingStream}
          router={this.props.router}
          streams={this.props.streams}
          setActive={this.setActive.bind(this)}
          activeItem={this.state.activeItem}
        >
          <HideToggle
            className={cx('toggle', { mobile: viewLayout === 'Mobile', theater: viewLayout === 'Theater' })}
            direction={this.props.hide}
            onHide={this.props.onHide}
          />
        </StreamDescriptionNav>
        {!addingStream && activeDescription}
        {!descHidden && addingStream && <AddStreamList />}
      </div>
    );
  }
}

/**
 * @param {RootState} state
 */
const mapStateToProps = function (state) {
  const { viewLayout } = state.chat.options;

  return {
    viewLayout,
    descHidden: state.watchUI.descHidden,
    addingStream: state.watchUI.addingStream,
    addStreamDisabled: !addStreamEnabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  setAdding(value) {
    dispatch(setAddingStream(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StreamDescriptionContainer);
