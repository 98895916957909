import React from 'react';

export const events = {
  JOIN_ROOM: 'JOIN_ROOM',
  ADD_MESSAGE:'ADD_MESSAGE',
  JOIN_STREAM: 'JOIN_STREAM',
  UPDATE_ROOM: 'UPDATE_ROOM',
  LEAVE_ROOM: 'LEAVE_ROOM',
  NOTIFY: 'NOTIFY',
  OPEN_PM: 'OPEN_PM',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
  SET_DELETION: 'SET_DELETION',
  SET_VOICE: 'SET_VOICE',
  SET_MOD: 'SET_MOD',
  SET_BLOCKED: 'SET_BLOCKED',
  SET_BLOCKLIST: 'SET_BLOCKLIST',
  SET_ECHO: 'SET_ECHO',
  START_RAFFLE: 'START_RAFFLE',
  JOIN_RAFFLE: 'JOIN_RAFFLE',
  CANCEL_RAFFLE: 'CANCEL_RAFFLE',
  KICK_RAFFLE: 'KICK_RAFFLE',
  TRIGGER_RAFFLE: 'TRIGGER_RAFFLE',
};

export const ChatContext = React.createContext({
  /**
   * @type {SocketIOClient.Socket | null}
   */
  client: null,
  /**
   * @param {SocketIOClient.Socket} client
   * @returns {void}
   */
  setClient: (client) => {},
});

export const roleLevels = {
  admin: 100,
  streamer: 75,
  mod: 70,
  user: 50,
  visitor: 45,
  banned: 0,
};
