import React from 'react';
import propTypes from 'prop-types';
import Config from '~/config';

import poster from './poster.png';

import styles from './index.scss';

function StreamPoster(props) {
  const { stream: { offline_image } } = props;
  const { url } = 'offline_image' in offline_image ? offline_image.offline_image : offline_image;

  return (
    <div style={{ backgroundImage: `url(${url || poster})` }} className={styles.StreamPoster} />
  );
}

StreamPoster.propTypes = {
  stream: propTypes.shape({
    username: propTypes.string.isRequired,
    offline_image: propTypes.shape({
      offline_image: propTypes.shape({
        url: propTypes.string,
      }),
    }),
  }),
};

StreamPoster.defaultProps = {
  stream: {
    offline_image: {
      offline_image: {
        url: null,
      },
    },
  },
};

export default StreamPoster;
