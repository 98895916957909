/* node_modules */
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

/* local imports */
import { preferences } from '~/services/storage';
import { getVisibilitySettings, setContentVisibility } from '~/modules/contentVisibility';

/* style imports */
import styles from './AdultToggle.scss';

const cx = classNames.bind(styles);

// These are the states the AdultToggle can be in,
// use to compare values from db.
export const TOGGLE_STATES = {
  SFW: 0,
  BLUR: 1,
  NSFW: 2,
};

/**
 * Connected component to toggle the visibility settings. Automatically
 * saves and differentiates between signed in and anonymous users.
 */
class AdultToggle extends React.Component {
  constructor(props) {
    super(props);

    this.updateState = this.updateState.bind(this);
  }

  updateState(toggleState) {
    const {
      dispatch,
      isSignedIn,
      currentUser,
    } = this.props;

    preferences.setItem('adultToggleState', toggleState);

    // If blur or nsfw is selected, display the '18?' prompt TODO: REIMPLEMENT
    // if (toggleState !== SFW) dispatch(setDisplayPrompt(true));
    dispatch(setContentVisibility(toggleState));
  }

  render() {
    const {
      toggleState,
      className
    } = this.props;

    return (
      <div className={cx('AdultToggle', className)}>
        <button
          type="button"
          className={cx({
            AdultToggle__Label: true,
            'AdultToggle__Label--sfw': true,
            'AdultToggle__Label--active': toggleState === TOGGLE_STATES.SFW,
          })}
          onClick={() => this.updateState(TOGGLE_STATES.SFW)}
          title="Hide all adult content"
        >
          SFW
        </button>
        <button
          type="button"
          className={cx({
            AdultToggle__Label: true,
            'AdultToggle__Label--blur': true,
            'AdultToggle__Label--active': toggleState === TOGGLE_STATES.BLUR,
          })}
          onClick={() => this.updateState(TOGGLE_STATES.BLUR)}
          title="Blur all adult content"
        >
          Blur
        </button>
        <button
          type="button"
          className={cx({
            AdultToggle__Label: true,
            'AdultToggle__Label--nsfw': true,
            'AdultToggle__Label--active': toggleState === TOGGLE_STATES.NSFW,
          })}
          onClick={() => this.updateState(TOGGLE_STATES.NSFW)}
          title="Show all adult content"
        >
          NSFW
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isSignedIn: state.currentUser.isSignedIn,
  currentUser: state.currentUser.data,
  toggleState: getVisibilitySettings(state),
});

export default connect(mapStateToProps)(AdultToggle);
