/**
 * Validates usernames according to Piczel's username rules
 */

const patterns = {
  /**
  * The username must match these patterns
  */
  with: [
    {
      regex: /^(?=.{1,24}$)[a-zA-Z0-9_]+$/,
      message: 'must contain only letters, numbers or underscores (_)',
    },
  ],
  without: [
    {
      regex: /^_/,
      message: 'cannot start with an underscore',
    },
    {
      regex: /_{2}/,
      message: 'cannot contain 2 or more consecutive underscores (_)',
    },
    {
      regex: /\./,
      message: 'cannot contain periods',
    },
  ],
};

const getValidationErrors = (username) => [
  ...patterns.with.filter((rule) => !rule.regex.test(username)).map((rule) => rule.message),
  ...patterns.without.filter((rule) => rule.regex.test(username)).map((rule) => rule.message),
];

module.exports = getValidationErrors;
