import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import styles from './index.scss';

const cx = classNames.bind(styles);

const checkImg = (url) => {
  const img = new Image();
  return new Promise((res) => {
    try {
      img.onload = () => {
        res(true);
      };
      img.onerror = () => {
        res(false);
      };
      img.src = url;
    } catch (error) {
      res(false);
    }
  });
};

const ModalInsertImg = ({ close, isOpen, insertImg }) => {
  const [messageValid, setMessageValid] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const url = e.currentTarget.url_img.value;
    const tag = e.currentTarget.alt_text.value;
    const link = e.currentTarget.imgLink.value;

    insertImg({ url, tag, link });
  };

  const checkUrl = (e) => {
    const url = e.currentTarget.value;
    if (url === '') {
      setShowMessage(false);
      return;
    }
    checkImg(url).then((res) => {
      setShowMessage(true);

      setMessageValid(res);
    });
  };

  return (
    <Modal onRequestClose={close} isOpen={isOpen}>
      <div className={cx(['ModalInsertImg'])}>
        <form onSubmit={onSubmit}>
          <h3>
            <FormattedMessage
              id="ModalInsertImg_Title"
              defaultMessage="Insert Image"
            />
          </h3>
          <label htmlFor="url_img" className={cx(['ModalInsertImg__Message'])}>
            <FormattedMessage
              id="ModalInsertImg_Url"
              defaultMessage="Image URL"
            />
            &nbsp;
            {
              showMessage && messageValid && (
              <span className={cx(['ModalInsertImg__Message--ImgValid'])}>
                <FormattedMessage
                  id="ModalInsertImg_UrlValid"
                  defaultMessage="(Url valid)"
                />
              </span>
              )
            }
            {
              showMessage && !messageValid && (
              <span className={cx(['ModalInsertImg__Message--ImgInvalid'])}>
                <FormattedMessage
                  id="ModalInsertImg_UrlInvalid"
                  defaultMessage="(Url invalid)"
                />
              </span>
              )
            }
            <input
              name="url_img"
              id="url_img"
              type="text"
              onChange={checkUrl}
              placeholder="https://example.com/img.png"
            />
          </label>

          <label htmlFor="alt_text" className={cx(['ModalInsertImg__Message'])}>
            <FormattedMessage
              id="ModalInsertImg_AltText"
              defaultMessage="Alt text (optional)"
            />
            <input
              name="alt_text"
              id="alt_text"
              type="text"
              placeholder="example"
            />
          </label>

          <label htmlFor="imgLink">
            <FormattedMessage
              id="ModalInsertImg_Link"
              defaultMessage="Image link (optional)"
            />

            <input type="url" id="imgLink" name="imgLink" placeholder="https://example.com" />
          </label>
          <div className={cx(['ModalInsertImg__Save'])}><button type="submit">Add</button></div>
        </form>
      </div>
    </Modal>
  );
};

ModalInsertImg.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  insertImg: PropTypes.func.isRequired,
};


export default ModalInsertImg;
