import reduceReducers from 'reduce-reducers';
import { schema, normalize, denormalize } from 'normalizr';
import config from '~/config';
import handleError from './_errorHandling';
import { addFlash } from './flashes';
import { setFromId, getFromId } from './pagination';
import { setLoading } from './loading';
import { sortById } from '~/helpers/data';
import adminChatIconsReducer, { INITIAL_STATE_CHATICONS } from './admin_chatIcons';
import { ADD_ENTITIES } from './entity';

const blacklistedWordSchema = new schema.Entity('blacklistedWords');
const chatGlobalUserSchema = new schema.Entity('chatGlobalUsers', {}, {
  idAttribute: 'uid',
});

const INITIAL_STATE = {
  patreon: {
    byId: {},
    allIds: [],
  },
  users: {
    byId: {},
    allIds: [],
  },
  notifications: {
    byId: {},
    allIds: [],
  },
  queue: {
    byId: {},
    allIds: [],
  },
  chatIcons: INITIAL_STATE_CHATICONS,
  blacklist: [],
  shadowbanned: [],
};

// Actions
const SET_USER = 'piczel/admin/SET_USER';

const RESET_USERS = 'piczel/admin/RESET_USERS';
const SET_USERS = 'piczel/admin/SET_USERS';

const RESET_PATREONS = 'piczel/admin/RESET_PATREONS';
const SET_PATREONS = 'piczel/admin/SET_PATREONS';

const RESET_NOTIFICATIONS = 'piczel/admin/RESET_NOTIFICATIONS';
const SET_NOTIFICATIONS = 'piczel/admin/SET_NOTIFICATIONS';

const RESET_QUEUE = 'piczel/admin/RESET_QUEUE';
const SET_QUEUE = 'piczel/admin/SET_QUEUE';
const SET_BLACKLIST = 'piczel/admin/SET_BLACKLIST';
const SET_SHADOWBANNED = 'piczel/admin/SET_SHADOWBANNED';

// Constants
export const LOADING_USERS = 'piczel/admin/LOADING_USERS';
export const LOADING_PATREONS = 'piczel/admin/LOADING_PATREONS';

// Reducer
function adminReducer(state, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        users: {
          ...state.users,
          byId: {
            ...state.users.byId,
            [action.payload.id]: action.payload,
          },
        },
      };
    case RESET_USERS:
      return { ...state, users: INITIAL_STATE.users };
    case SET_USERS:
      return { ...state, users: sortById(state.users, action.payload) };

    case RESET_PATREONS:
      return { ...state, patreon: INITIAL_STATE.patreon };
    case SET_PATREONS:
      return { ...state, patreon: sortById(state.patreon, action.payload) };

    case RESET_NOTIFICATIONS:
      return { ...state, notifications: INITIAL_STATE.notifications };
    case SET_NOTIFICATIONS:
      return { ...state, notifications: sortById(state.notifications, action.payload) };

    case RESET_QUEUE:
      return { ...state, queue: INITIAL_STATE.queue };
    case SET_QUEUE:
      return { ...state, queue: sortById(state.queue, action.payload) };

    case SET_BLACKLIST:
      return { ...state, blacklist: action.payload };
    
    case SET_SHADOWBANNED:
      return { ...state, shadowbanned: action.payload };
    
    default: return state;
  }
}

export default reduceReducers(INITIAL_STATE, adminReducer, adminChatIconsReducer);

// Action creators
export function setUser(payload) {
  return { type: SET_USER, payload };
}

export function resetUsers() {
  return { type: RESET_USERS };
}

export function setUsers(payload) {
  return { type: SET_USERS, payload };
}

export function resetPatreons() {
  return { type: RESET_PATREONS };
}

export function setPatreons(payload) {
  return { type: SET_PATREONS, payload };
}

export function resetNotifications() {
  return { type: RESET_NOTIFICATIONS };
}

export function setNotifications(payload) {
  return { type: SET_NOTIFICATIONS, payload };
}

export function resetQueue() {
  return { type: RESET_QUEUE };
}

export function setQueue(payload) {
  return { type: SET_QUEUE, payload };
}

export const setBlacklist = (payload) => ({
  type: SET_BLACKLIST,
  payload,
});

interface FetchPatreonOpts {
  reset?: boolean;
  fromId?: number;
  silent?: boolean;
  search?: string;
}

export function fetchPatreonRewards(opts: FetchPatreonOpts = {}) {
  return function (dispatch, getState, fetch: typeof window.fetch) {
    dispatch(setLoading(LOADING_PATREONS, true));

    if (opts.reset || !opts.fromId) dispatch(resetPatreons());

    const url = new URL(`${config.api}/patreon/rewards`);
    if (opts.fromId) url.searchParams.set('from_id', String(opts.fromId));

    return fetch(url.toString())
      .then(res => handleError(dispatch, res))
      .then((res) => {
        dispatch(setPatreons(res));
      });
  };
}

export function fetchUsers(opts: FetchPatreonOpts = {}) {
  return function (dispatch, getState, fetch: typeof window.fetch) {
    dispatch(setLoading(LOADING_USERS, true));

    if (opts.reset || !opts.fromId) dispatch(resetUsers());

    const url = new URL(`${config.api}/admin/${opts.search ? opts.search : 'users'}`);
    if (opts.fromId) url.searchParams.set('from_id', String(opts.fromId));

    return fetch(url.toString())
      .then(res => handleError(dispatch, res))
      .then((res) => {
        const prevId = getFromId(getState(), 'admin_users');

        if (!(res.data.length > 0)) {
          dispatch(setFromId('admin_users', -1));
        } else {
          dispatch(setFromId('admin_users', prevId ? prevId + res.data.length : res.data.length));
        }

        dispatch(setUsers(res.data));
      });
  };
}

export function banUser(id, unban = false) {
  return function (dispatch, getState, fetch) {
    const url = new URL(`${config.api}/users/me/${id}/${unban ? 'unban' : 'ban'}`);

    return fetch(url.toString(), {
      method: 'PATCH',
    }).then(res => handleError(dispatch, res))
      .then((res) => {
        const user = getState().admin.users.byId[id];

        if (res.status === 'success') {
          user.role = unban ? 'user' : 'banned';
        }

        dispatch(setUser(user));
      });
  };
}

export function sendMail(data) {
  return function (dispatch, getState, fetch) {
    const url = new URL(`${config.api}/admin/email`);

    return fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subject: data.subject,
        to: data.recipient,
        body: data.body,
      }),
    });
  };
}

export function fetchNotifications() {
  return function (dispatch, getState, fetch) {
    dispatch(resetNotifications());

    return fetch(`${config.api}/admin/notifications`)
      .then(response => handleError(dispatch, response))
      .then(json => dispatch(setNotifications(json.notifications)));
  };
}

export function sendNotification(data) {
  return function (dispatch, getState, fetch) {
    const url = new URL(`${config.api}/admin/notification`);

    return fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => handleError(dispatch, res))
      .then(() => {
        dispatch(fetchNotifications());
        dispatch(addFlash('success', 'The notification has been sent', true));
      });
  };
}

export function deleteNotification(message) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/admin/notification`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        notification: message, // WTF? Never do this. Use IDs.
      }),
    }).then(res => handleError(dispatch, res))
      .then(() => {
        dispatch(fetchNotifications());
        dispatch(addFlash('success', 'The notification has been deleted', true));
      });
  };
}

export function fetchQueue(opts: FetchPatreonOpts = {}) {
  return function (dispatch, getState, fetch) {
    const url = new URL(`${config.api}/admin/queue`);

    if (opts.fromId) url.searchParams.set('from_id', String(opts.fromId));

    return fetch(url.toString())
      .then(res => handleError(dispatch, res))
      .then((json) => {
        if (!opts.silent) dispatch(resetQueue());

        dispatch(setFromId('admin_queue', json.length > 0 ? json[json.length - 1].id : -1));
        dispatch(setQueue(json));
      });
  };
}

// Change backend to use userId
export function confirmUser(user) {
  return function (dispatch, getState, fetch) {
    const url = new URL(`${config.api}/admin/${user.username}/activate`);

    return fetch(url.toString(), {
      method: 'POST',
    }).then(res => handleError(dispatch, res))
      .then((res) => {
        const newUser = user;

        if (res.status === 'success') newUser.confirmed_at = true;

        dispatch(setUser(newUser));
      });
  };
}

export function sendPremium(userId, premiumDays) {
  return function (dispatch, getState, fetch) {
    const url = `${config.api}/admin/users/gift_premium`;

    return fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        premiumDays,
      }),
    }).then(res => handleError(dispatch, res))
      .then(() => {
        dispatch(addFlash('success', `You've gifted ${premiumDays} days of premium to the user with ID ${userId}.`));
      });
  };
}

export const loadBlacklistedWords = () => async (dispatch, getState, fetch) => {
  const response = await fetch(`${config.api}/admin/chat/blacklist`);


  const json = await handleError(dispatch, response);

  if (json.data) {
    const normalized = normalize(json.data, [blacklistedWordSchema]);
    console.log(normalized);
    dispatch({
      type: ADD_ENTITIES,
      payload: normalized.entities,
    });

    dispatch({
      type: SET_BLACKLIST,
      payload: normalized.result,
    })
  }
};

export const deleteBlacklistedWord = id => async (dispatch, getState, fetch) => {
  const response = await fetch(`${config.api}/admin/chat/blacklist/${id}`, {
    method: 'DELETE',
  });

  const { blacklist } = getState().admin;

  if (response.ok) {
    dispatch(setBlacklist(blacklist.filter(wordId => wordId !== id)));
  }

  handleError(dispatch, response);
};

export const addBlacklistedWord = data => async (dispatch, getState, fetch) => {
  const response = await fetch(`${config.api}/admin/chat/blacklist`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const json = await handleError(dispatch, response);
  if (!json.error) {
    const normalized = normalize(json.data, blacklistedWordSchema);

    dispatch({
      type: ADD_ENTITIES,
      payload: normalized.entities,
    });

    dispatch({
      type: SET_BLACKLIST,
      payload: getState().admin.blacklist.concat([normalized.result])
    });
  }  
};

export const loadShadowbannedUsers = () => async (dispatch, getState, fetch) => {
  const response = await fetch(`${config.api}/admin/chat/users?filter[role]=echo`);
  
  const json = await handleError(dispatch, response);

  if (json.data) {
    const normalized = normalize(json.data, [chatGlobalUserSchema]);

    dispatch({
      type: ADD_ENTITIES,
      payload: normalized.entities,
    });

    dispatch({
      type: SET_SHADOWBANNED,
      payload: normalized.result,
    });
  }
};

export const setUserShadowbanned = (userId, value = true) => async (dispatch, getState, fetch) => {
  const response = await fetch(`${config.api}/admin/chat/user/${userId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      role: value ? 'echo' : 'user',
    }),
  });

  if (response.ok) {
    const json = await response.json();

    if (json.data) {
      const normalized = normalize(json.data, chatGlobalUserSchema);
      console.log(normalized);
      dispatch({
        type: ADD_ENTITIES,
        payload: normalized.entities,
      });

      if (value) {
        dispatch({
          type: SET_SHADOWBANNED,
          payload: getState().admin.shadowbanned.concat([json.data.uid]),
        });
      } else {
        dispatch({
          type: SET_SHADOWBANNED,
          payload: getState().admin.shadowbanned.filter(id => id !== userId),
        });
      }
    }
  }
};

// Selectors
export function getNotifications(state) {
  return state.admin.notifications.allIds.map(id => state.admin.notifications.byId[id]);
}

export const getShadowbanned = state => denormalize(state.admin.shadowbanned, [chatGlobalUserSchema], state.entities);
export const getBlacklist = state => denormalize(state.admin.blacklist, [blacklistedWordSchema], state.entities);
