import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames/bind';
import { ReactMarkdownWithHtml } from '~/components/Markdown';

import ModalSocialButton from './ModalSocialButton';
import ModalInsertImg from './ModalInsertImg';
import ModalInsertLink from './ModalInsertLink';
import styles from './Editor.scss';

const cx = classNames.bind(styles);

const Tags = {
  b: {
    markdown: ['**', '**'],
    html: ['<b>', '</b>'],
  },
  i: {
    markdown: ['_', '_'],
    html: ['<i>', '</i>'],
  },
  a: {
    markdown: '[{text}]({url})',
    html: '<a href="{url}">{text}</a>',
  },
  img: {
    markdown: ['![{tag}]({url}', ')'],
    html: ['<img src="', '" />'],
  },
  twitter: {
    markdown: '[![Twitter](/img/logo-button-twitter.png)](https://twitter.com/{username})',
    html: '<a href="https://twitter.com/{username}"><img src="/img/logo-button-twitter.png"/></a>',
  },
  facebook: {
    markdown: '[![Facebook](/img/logo-button-facebook.png)](https://facebook.com/{username})',
    html: '<a href="https://facebook.com/{username}"><img src="/img/logo-button-facebook.png"/></a>',
  },
  tumblr: {
    markdown: '[![Tumblr](/img/logo-button-tumblr.png)](https://tumblr.com/blog/{username})',
    html: '<a href="https://tumblr.com/blog/{username}"><img src="/img/logo-button-tumblr.png"/></a>',
  },
  furaffinity: {
    markdown: '[![Furaffinity](/img/logo-button-furaffinity.png)](https://furaffinity.net/user/{username})',
    html: '<a href="https://furaffinity.net/user/{username}"><img src="/img/logo-button-furaffinity.png"/></a>',
  },
  'hentai foundry': {
    markdown: '[![Hentai Foundry](/img/logo-button-hf.png)](https://hentai-foundry.com/user/{username})',
    html: '<a href="https://hentai-foundry.com/user/{username}"><img src="/img/logo-button-hf.png"/></a>',
  },
  paypal: {
    markdown: '[![Paypal](/img/logo-button-paypal.png)](https://paypal.me/{username})',
    html: '<a href="https://paypal.me/{username}"><img src="/img/logo-button-hf.png"/></a>',
  },
  'ko-fi': {
    markdown: '[![ko-fi](/img/logo-button-ko-fi.png)](https://ko-fi.com/{username})',
    html: '<a href="https://ko-fi.com/{username}"><img src="/img/logo-button-ko-fi.png"/></a>',
  },
};

export default class DescriptionEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: true,
      showModal: false,
      tag: 'twitter',
    };

    this.textarea = React.createRef();
    this.handleOnkeyDown = this.handleOnkeyDown.bind(this);
    this.adjustTextarea = this.adjustTextarea.bind(this);
  }

  componentDidMount() {
    if (this.textarea && typeof this.textarea.addEventListener === 'function') {
      this.textarea.addEventListener('input', this.adjustTextarea);
      this.textarea.addEventListener('keydown', this.handleOnkeyDown);
    }
  }

  insertLink = ({ url, text, wrap }) => {
    const { markdown } = this.props;
    const urlRegex = new RegExp('{url}');
    const textRegex = new RegExp('{text}');

    const markup = markdown ? 'markdown' : 'html';

    const tag = Tags.a[markup].replace(urlRegex, url).replace(textRegex, wrap ? '|' : text);
    if (wrap) {
      const [firstSlice, secondSlice] = tag.split('|');
      this.toggleTags(firstSlice, secondSlice);
    } else {
      this.insertString(tag);
    }
    this.setState({ showModal: false });
  }

  insertImg = ({ url, tag, link }) => {
    const { markdown } = this.props;
    const urlRegex = new RegExp('{url}');
    const tagRegex = new RegExp('{tag}');

    const markup = markdown ? 'markdown' : 'html';

    const openTag = Tags.img[markup][0].replace(urlRegex, url).replace(tagRegex, tag);
    const closeTag = Tags.img[markup][1];
    let wrappedImg = `${openTag}${closeTag}`;

    if (link) {
      if (markup === 'markdown') {
        wrappedImg = `[${openTag}${closeTag}](${link})`;
      } else {
        wrappedImg = `<a href="${link}" target="_blank">${openTag}${closeTag}</a>`;
      }
    }

    this.insertString(wrappedImg);
    this.setState({ showModal: false });
  }

  addButtonSocial = ({ socialMediaTag, username }) => {
    const { markdown } = this.props;
    if (username === '') return;

    const regExp = new RegExp('{username}', 'g');
    const tag = Tags[socialMediaTag][markdown ? 'markdown' : 'html'].replace(regExp, username);
    this.toggleTags(tag, '');
    this.setState({ showModal: false });
  }

  handleOnkeyDown(event) {
    const { onKeyDown } = this.props;
    if (onKeyDown) {
      onKeyDown(event);
    }
  }

  openModal(tag) {
    const { editing } = this.state;
    if (!editing) return;
    this.setState({ showModal: true, tag });
  }

  adjustTextarea() {
    const { small } = this.props;

    this.textarea.style.height = 0;

    const sHeight = this.textarea.scrollHeight;
    const minHeight = small ? 75 : 200;

    this.textarea.height = `${Math.max(sHeight, minHeight) + 10}px`;
  }

  toggleTags(openTag, closeTag) {
    const { editing } = this.state;
    if (!editing) return;

    const area = this.textarea;
    const selStart = area.selectionStart;
    const selEnd = area.selectionEnd;
    let selRange = [0, 0];
    let newValue = '';
    const areaText = area.value;

    // Check if selected text is wrapped by the tags
    const openTagPresent = areaText.substring(selStart - openTag.length, selStart) === openTag;
    const closeTagPresent = areaText.substring(selEnd, selEnd + closeTag.length) === closeTag;

    // Warning: code below may not be too readable
    if (openTagPresent && closeTagPresent) {
      // Set the new value, skipping the tags
      newValue = areaText.substring(0, selStart - openTag.length)
        + areaText.substring(selStart, selEnd)
        + areaText.substring(selEnd + closeTag.length, areaText.length);

      selRange = [selStart - openTag.length, selEnd - openTag.length];
    } else {
      // Add the tags to the selected text
      newValue = areaText.substring(0, selStart)
        + openTag
        + areaText.substring(selStart, selEnd)
        + closeTag
        + areaText.substring(selEnd, areaText.length);

      selRange = [selStart + openTag.length, selEnd + openTag.length];
    }

    // Re-focus textarea after it's changed
    // Not clean but it works
    Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value').set.call(area, newValue);
    area.dispatchEvent(new Event('input', { bubbles: true }));
    area.focus();
    area.setSelectionRange(selRange[0], selRange[1]);
  }

  toggleEditor() {
    const { editing } = this.state;
    this.setState({
      editing: !editing,
    });
  }

  tagButton(tag) {
    const { markdown, socialMedia = false } = this.props;

    const selectedTags = Tags[tag][markdown ? 'markdown' : 'html'];
    switch (tag) {
      case 'b':
        return (
          <div
            onClick={this.toggleTags.bind(this, selectedTags[0], selectedTags[1])}
            className={cx(['DescriptionEditor__ToolbarItem', 'pz', 'pz-bold'])}
            aria-hidden="true"
          />
        );

      case 'i':
        return (
          <div
            onClick={this.toggleTags.bind(this, selectedTags[0], selectedTags[1])}
            className={cx(['DescriptionEditor__ToolbarItem', 'pz', 'pz-italic'])}
            aria-hidden="true"
          />
        );

      case 'a':
        return (
          <div
            onClick={this.openModal.bind(this, tag)}
            className={cx(['DescriptionEditor__ToolbarItem', 'pz', 'pz-link'])}
            aria-hidden="true"
          />
        );

      case 'img':
        return (
          <div
            onClick={this.openModal.bind(this, tag)}
            className={cx(['DescriptionEditor__ToolbarItem', 'pz', 'pz-image'])}
            aria-hidden="true"
          />
        );

      case 'twitter':
        if (!socialMedia) return null;

        return (
          <div
            onClick={this.openModal.bind(this, tag)}
            className={`${cx(['DescriptionEditor__ToolbarItem', 'pz'])} ion-social-twitter`}
            aria-hidden="true"
          />
        );

      case 'facebook':
        if (!socialMedia) return null;

        return (
          <div
            onClick={this.openModal.bind(this, tag)}
            className={`${cx(['DescriptionEditor__ToolbarItem', 'pz'])} ion-social-facebook`}
            aria-hidden="true"
          />
        );

      case 'tumblr':
        if (!socialMedia) return null;

        return (
          <div
            onClick={this.openModal.bind(this, tag)}
            className={`${cx(['DescriptionEditor__ToolbarItem', 'pz'])} ion-social-tumblr`}
            aria-hidden="true"
          />
        );

      case 'furaffinity':
        if (!socialMedia) return null;

        return (
          <div
            onClick={this.openModal.bind(this, tag)}
            className={cx(['DescriptionEditor__ToolbarItem', 'pz', 'pz-furaffinity'])}
            aria-hidden="true"
          />
        );
      case 'hentai foundry':
        if (!socialMedia) return null;

        return (
          <div
            onClick={this.openModal.bind(this, tag)}
            className={cx(['DescriptionEditor__ToolbarItem', 'pz', 'pz-hentai-foundry'])}
            aria-hidden="true"
          />
        );

      case 'paypal':
        if (!socialMedia) return null;

        return (
          <div
            onClick={this.openModal.bind(this, tag)}
            className={cx(['DescriptionEditor__ToolbarItem', 'pz', 'pz-paypal'])}
            aria-hidden="true"
          />
        );

      case 'ko-fi':
        if (!socialMedia) return null;

        return (
          <div
            onClick={this.openModal.bind(this, tag)}
            className={cx(['DescriptionEditor__ToolbarItem', 'pz', 'pz-ko-fi'])}
            aria-hidden="true"
          />
        );

      default:
        return null;
    }
  }

  /**
   * Insert string at the current cursor position
   */
  insertString(text) {
    const { setFieldValue, fieldProps: { name } } = this.props;
    const previous = this.textarea.value.substring(0, this.textarea.selectionStart);
    const next = this.textarea.value.substring(this.textarea.selectionEnd, this.textarea.value.length);

    const value = `${previous}${text}${next}`;

    if (setFieldValue && name) {
      setFieldValue(name, value);
    } else {
      this.textarea.value = value;
    }
  }

  render() {
    const {
      tags, small, markdown, previewDisabled, fieldProps,
    } = this.props;
    const { showModal, tag, editing } = this.state;

    return (
      <div className={cx('DescriptionEditor', { 'DescriptionEditor--Small': small })}>
        <div className={styles.DescriptionEditor__Toolbar}>
          {
            tags.map(t => this.tagButton(t))
          }

          {
           !previewDisabled && (
           <a
             onClick={this.toggleEditor.bind(this)}
             className={cx(['DescriptionEditor__ToolbarItem', 'DescriptionEditor__ToolbarItem--preview'])}
           >
             {editing ? 'Preview' : 'Edit'}
           </a>
           )
         }
        </div>
        <Field
          className={cx('DescriptionEditor__Field', { 'DescriptionEditor__Field--hidden': !editing })}
          component="textarea"
          innerRef={el => this.textarea = el}
          {...fieldProps}
        />
        {
          !markdown && (
            <div
              className={cx('DescriptionEditor__Preview', { 'DescriptionEditor__Preview--hidden': editing })}
              dangerouslySetInnerHTML={{ __html: this.textarea.value }}
            />
          )
        }
        {
          markdown && (
            <div
              className={cx('DescriptionEditor__Preview', { 'DescriptionEditor__Preview--hidden': editing })}
            >
              <ReactMarkdownWithHtml>{this.textarea.value}</ReactMarkdownWithHtml>
            </div>
          )
        }
        {
          showModal && (tag !== 'img' && tag !== 'a') && <ModalSocialButton addButtonSocial={this.addButtonSocial} isOpen={showModal} close={() => this.setState({ showModal: false })} socialMediaTag={tag} />
        }
        {
          showModal && tag === 'img' && <ModalInsertImg insertImg={this.insertImg} isOpen={showModal} close={() => this.setState({ showModal: false })} />
        }
        {
          showModal && tag === 'a' && <ModalInsertLink wrap={this.textarea.selectionStart !== this.textarea.selectionEnd} insertLink={this.insertLink} isOpen={showModal} close={() => this.setState({ showModal: false })} />
        }
        <a target="_blank" href="/help/markdown" className={cx('DescriptionEditor__MdSupport')}>
          <span role="img" className="ion-android-open" />
          {' '}
          Markdown is supported
        </a>
      </div>
    );
  }
}

DescriptionEditor.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  small: PropTypes.bool,
  markdown: PropTypes.bool,
  previewDisabled: PropTypes.bool,
  socialMedia: PropTypes.bool.isRequired,
  onKeyDown: PropTypes.func,
};

DescriptionEditor.defaultProps = {
  tags: [
    'a',
    'i',
    'b',
    'img',
    'twitter',
    'facebook',
    'tumblr',
    'furaffinity',
    'paypal',
    'ko-fi',
    'hentai foundry',
  ],
  small: false,
  markdown: true,
  previewDisabled: false,
  onKeyDown: null,
};
