export const OVENPLAYER = 'OVENPLAYER';
export const VIDEOJS = 'VIDEOJS';
// For people that had Dash set as their default
/**
 * @typedef {'hls_legacy'|'hls_nodvr'|'flash'|'STREAMSHOTS'|'beta_hls'|'beta_flash'|'beta_flash'|'webrtc'|'llhls'|'audio'} Tech
 */

/**
* @type {Tech}
*/
export const HLS = 'hls_legacy';
/**
* @type {Tech}
*/
export const HLS_NO_DVR = 'hls_nodvr';
/**
* @type {Tech}
*/
export const FLASH = 'flash';
/**
* @type {Tech}
*/
export const STREAMSHOTS = 'STREAMSHOTS';

/**
 * @type {Tech}
 */
export const WEBRTC = 'webrtc';

export const WEBRTC_TCP = 'webrtc_tcp';

export const AUDIO = 'audio';

export const LL_HLS = 'llhls';

export const LL_HLS_EXPERIMENTAL = 'llhls_exp';


/**
* Used for the techpicker options in streamshots player (PreviewPlayer) and videojsplayer
* @type {{[label: string]: Tech}}
*/
export const techPickerOptions = {
  HLS,
  Flash: FLASH,
  'WebRTC (beta)': WEBRTC,
  Streamshots: STREAMSHOTS,
  'New HLS': LL_HLS,
  // 'Audio Only': AUDIO,
};
