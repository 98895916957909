import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { shape, arrayOf, string, number } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';

import { startPlayingRecording } from '~/modules/streams';
import Button from '~/components/Button';

import styles from './RecordingPicker.scss';
import Config from '~/config';

const cx = classNames.bind(styles);

/**
 * @type {React.FC<{stream: Stream}>}
 */
const RecordingPicker = ({ stream }) => {
  const dispatch = useDispatch();
  const list = useRef(null);
  const [mousePos, setMousePos] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    if (list) {
      const width = list.current.scrollWidth;
      const widthParent = list.current.parentElement.offsetWidth;
      setShowButtons(width > widthParent);
    }
  }, [list]);

  const playRecording = useCallback((recordingId) => {
    dispatch(startPlayingRecording(stream, recordingId));
  }, [dispatch, stream]);

  /**
   * Horizontal scroll on desktop
   */
  const onMouseMove = useCallback((e) => {
    if (e.buttons && list.current) {
      const speed = 3;
      list.current.scrollLeft = scrollLeft - (e.pageX - mousePos) * speed;
    }
  }, [scrollLeft, mousePos]);

  const onMouseDown = useCallback((e) => {
    e.stopPropagation();
    setMousePos(e.pageX);
    setScrollLeft(list.current.scrollLeft);
  }, []);

  const leftScroll = useCallback((e) => {
    const button = e.currentTarget;
    const element = button.nextSibling;
    const width = element.firstChild.offsetWidth + 10;
    element.scrollBy(-width, 0);
  }, []);

  const rightScroll = useCallback((e) => {
    const button = e.currentTarget;
    const element = button.previousSibling;
    const width = element.firstChild.offsetWidth + 10;
    element.scrollBy(width, 0);
  }, []);

  /**
   * If stream is offline, recording list will be visible
   */
  const [collapsed, setCollapsed] = useState(stream.live);

  return (
    <div className={styles.RecordingPicker}>
      <button onClick={() => setCollapsed(!collapsed)} type="button" className={cx('RecordingPicker__Toggle', {'RecordingPicker__Toggle--Collapsed': collapsed })}>
        <i className="ion-android-arrow-dropdown" role="img" />
        {' '}
        <FormattedMessage id="RecordingPicker_Title" defaultMessage="Recordings" />
      </button>
      <div className={cx('Cover')}>
        {
          !collapsed && showButtons && (
            <Button
              onClick={leftScroll}
              className={cx('Button__Left')}
              color="transparent"
              title="Left"
            >
              <i class="icon ion-chevron-left"></i>
            </Button>
          )
        }
        <div ref={list} onMouseDown={onMouseDown} onMouseMove={onMouseMove} className={cx('RecordingPicker__List', { 'RecordingPicker__List--Collapsed': collapsed })}>
          {
            stream.recordings.map(recording => (
              <div className={styles.RecordingItem} key={recording.id}>
                <div title={`${recording.created_at} ago (${recording.size})`} className={cx('RecordingPicker__Preview', {'RecordingPicker__Preview--Private': recording.private})} style={{ backgroundImage: `url(${recording.thumb})` }} >
                  <button type="button" onClick={() => playRecording(recording.id)} title="Play this recording" className={styles.RecordingPicker__PlayButton}>
                    <i className="ion-play" />
                  </button>

                  <div className={styles.RecordingItem__CreatedAt}>
                    <span className="ion-ios-clock" role="img" />
                    {' '}
                    {recording.created_at}
                    {' '}
                    ago

                    <a className={styles.RecordingItem__Download} download target="_blank" rel="noopener noreferrer" href={recording.url}>Download</a>
                  </div>
                  {
                    recording.private && (
                      <div className={styles.RecordingPicker__PrivateIndicator}>
                        <span role="img" className="ion-eye-disabled" />
                        &nbsp;
                        Only you can see this recording
                      </div>
                    )
                  }
                </div>
              </div>
            ))
          }
        </div>
        {
          !collapsed && showButtons && (
            <Button
              onClick={rightScroll}
              className={cx('Button__Right')}
              color="transparent"
              title="Right"
            >
              <i class="icon ion-chevron-right"></i>
            </Button>
          )
        }
      </div>
    </div>
  );
};

RecordingPicker.propTypes = {
  stream: shape({
    recordings: arrayOf(shape({
      thumb: string,
      id: number,
    })),
  }).isRequired,
};

export default RecordingPicker;
