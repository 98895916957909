import React from 'react';

class DateTimeInput extends React.Component {
  constructor(props) {
    super(props);
    let date = new Date();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date
      .getDate()
      .toString()
      .padStart(2, '0');
    let hours = date
      .getHours()
      .toString()
      .padStart(2, '0');
    let minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0');

    if (this.props.value) {
      date = new Date(this.props.value);
      month = (date.getMonth() + 1).toString().padStart(2, '0');
      day = date
        .getDate()
        .toString()
        .padStart(2, '0');
      hours = date
        .getHours()
        .toString()
        .padStart(2, '0');
      minutes = date
        .getMinutes()
        .toString()
        .padStart(2, '0');
    }

    this.state = {
      date, // Datetime
      dateStr: `${date.getFullYear()}-${month}-${day}`,
      timeStr: `${hours}:${minutes}`,
    };
  }

  /**
   * Handle date changes
   */
  onDateChange(e) {
    return this.setState(
      {
        dateStr: e.target.value,
      },
      () => this.onChange(),
    );
  }

  onTimeChange(e) {
    return this.setState(
      {
        timeStr: e.target.value,
      },
      () => this.onChange(),
    );
  }

  onChange() {
    this.props.onChange(this.getValue());
  }

  getValue() {
    let { timeStr: time, dateStr: date } = this.state;

    time = time.split(':').map(t => parseInt(t, 10));
    date = date.split('-').map(d => parseInt(d, 10));

    // Months are 0 indexed
    date[1] -= 1;

    // Return the UTC time equivalent of whatever the user entered
    const equiv = new Date(date[0], date[1], date[2], time[0], time[1]);
    // console.log(equiv);
    return equiv.toUTCString();
  }

  render() {
    const { timeStr, dateStr } = this.state;

    /**
     * Ideally we'd use a single <input type="datetime-local" /> element
     * But it seems that it's not supported by some browsers (Firefox)
     */
    return (
      <div className="DateTimeInput">
        <span>Publish my post on </span>
        <input
          className="DateTimeInput-input"
          type="date"
          required
          value={dateStr}
          onChange={e => this.onDateChange(e)}
          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
        />
        <span> at </span>
        <input
          className="DateTimeInput-input"
          type="time"
          required
          value={timeStr}
          onChange={e => this.onTimeChange(e)}
        />
      </div>
    );
  }
}

export default DateTimeInput;
