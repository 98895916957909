/* node_modules */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import Dropzone from 'react-dropzone';
import classNames from 'classnames/bind';

/* local imports */
import { isLoading } from '~/modules/loading';
import {
  uploadImage,
  fetchFolders,
  getFolders,
  UPLOADING_IMAGE,
  UPLOADING_OTHERFILES,
} from '~/modules/gallery';

import Loading from '~/components/Loading';
import FormattedMessage from '~/components/common/FormattedMessage';
import SelectorButton, { Selection } from '~/components/Button/Selector';
import Checkbox from '~/components/Form/Checkbox';
import TextField from '~/components/Form/TextField';
import DateTime from '~/components/Form/DateTime';
import { FolderPicker, UPLOAD_MODES } from '~/views/Gallery/Image/Form';
import { addFlash } from '~/modules/flashes';

/* style imports */
import styles from '~/views/Gallery/Image/Form/ImageForm.scss';

const cx = classNames.bind(styles);

export class UploadPreview extends React.Component {
  constructor(props) {
    super(props);

    this.remove = this.remove.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  remove(e) {
    const { i, removeFile } = this.props;

    e.preventDefault();
    e.stopPropagation();

    removeFile(i);
  }

  onClick(e) {
    // e.preventDefault();
    e.stopPropagation();
  }

  onChange(e) {
    const { i, updateFile } = this.props;
    const {
      name,
      value,
      checked,
      type,
    } = e.currentTarget;

    if (name === 'folder_id') updateFile(i, 'folder_name', null);
    if (name === 'folder_name') updateFile(i, 'folder_id', null);

    updateFile(i, name, type === 'checkbox' ? checked : value);
  }

  render() {
    const {
      file,
      file: {
        name,
        path,
        preview,
        // States
        isUploading,
        success,
        failure,
        // Transmitted values
        title,
        tags,
        folder_id,
        folder_name,
        nsfw,
      },
      folders,
      i,
    } = this.props;

    return (
      <div
        className={cx(
          'ImageForm__UploadPreview',
          'ImageForm__UploadPreview--big', {
            'ImageForm__UploadPreview--isUploading': isUploading,
            'ImageForm__UploadPreview--status__success': success,
            'ImageForm__UploadPreview--status__failure': failure,
          },
        )}
        key={`prev:${i}`}
        onClick={this.onClick}
      >
        {isUploading && <Loading className={cx('ImageForm__UploadPreviewLoader')} />}
        <img src={file.data} alt={file.name} />
        <div>
          <TextField
            name="title"
            value={title}
            onChange={this.onChange}
          >
            <FormattedMessage
              id="Gallery_UploadForm_Title"
              defaultMessage="Image Title (required)"
            />
          </TextField>
          <TextField
            name="tags"
            value={tags}
            onChange={this.onChange}
          >
            <FormattedMessage
              id="Gallery_UploadForm_TagsShort"
              defaultMessage="Image Tags"
            />
          </TextField>
          <Checkbox
            name="nsfw"
            checked={nsfw}
            onChange={this.onChange}
          >
            Adult
          </Checkbox>
        </div>
        <div>
          <FolderPicker
            folders={folders}
            folder_id={folder_id}
            handleChange={this.onChange}
            hideTitle
          />
        </div>
        <a
          className="ion-close-circled"
          onClick={this.remove}
          title="Remove"
          href="javascript:void"
        />
      </div>
    );
  }
}

class BulkUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
    };

    this.onDrop = this.onDrop.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.updateFile = this.updateFile.bind(this);
  }

  onDrop(newFiles) {
    const { files } = this.state;
    this.setState({ loadingFiles: true });

    const processFiles = newFiles.map(file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve({
          title: file.name,
          name: file.name,
          size: file.size,
          type: file.type,
          data: reader.result,
        });
      };

      reader.onerror = reject;
    }));

    Promise.all(processFiles).then((values) => {
      this.setState({
        files: [
          ...files,
          ...values,
        ],
        loadingFiles: false,
      });
    });
  }

  onSubmit(values) {
    const { dispatch } = this.props;
    const { files } = this.state;

    // preferences.setItem("nsfw-last-value", values.nsfw.toString());

    // Upload files one by one
    files
      .filter(file => !file.success)
      .forEach((file, i) => this.uploadFile({
        ...file,
        index: i,
      }, values));
  }

  performUpload(file, values) {
    const { dispatch } = this.props;

    return dispatch(uploadImage({
      nsfw: file.nsfw === true,
      tags: file.tags ? file.tags.split(',') : [],
      folder_id: file.folder_id,
      folder_name: file.folder_name,
      files: [file],
      title: file.title || file.name,
      published_at: values.uploadMode === 'SCHEDULE' ? values.published_at : null,
    }, {
      queue: values.uploadMode === 'QUEUE' || values.uploadMode === 'SCHEDULE',
    }));
  }

  uploadFile(file, values) {
    const { dispatch } = this.props;

    this.updateFile(file.index, 'isUploading', true);

    this.performUpload(file, values)
      .then(() => {
        this.updateFile(file.index, 'success', true);
        this.updateFile(file.index, 'isUploading', false);
      })
      .catch(() => {
        dispatch(addFlash('error', 'Upload failed, please try again later'));
      });
  }

  updateFile(i, name, value) {
    const files = this.state.files.slice();
    files[i][name] = value;

    this.setState({ files });
  }

  removeFile(i) {
    const files = this.state.files.slice();
    files.splice(i, 1);

    this.setState({ files });
  }

  setMode(e, setFieldValue) {
    setFieldValue('uploadMode', e.currentTarget.dataset.name);
  }

  componentDidMount() {
    const { dispatch, currentUser } = this.props;

    dispatch(fetchFolders(currentUser.username));
  }

  render() {
    const {
      props: {
        folders,
      },
      state: {
        files,
      },
    } = this;

    return (
      <div>
        <Formik
          initialValues={{
            name: '',
            tags: '',
            uploadMode: null,
          }}
          onSubmit={this.onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form data-testid="form" style={{ maxWidth: '100%' }}>
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    {...getRootProps()}
                    className={cx('ImageForm__Dropzone', { 'ImageForm__Dropzone--active': isDragActive })}
                  >
                    <h2>
                      <FormattedMessage id="Gallery_file_prompt" />
                    </h2>
                    <input {...getInputProps()} data-testid="fileinput" />
                    <div className={cx('ImageForm__Uploads', 'ImageForm__Uploads--fullWidth')}>
                      {files.map((file, i) => (
                        <UploadPreview
                          file={file}
                          folders={folders}
                          i={i}
                          removeFile={this.removeFile}
                          updateFile={this.updateFile}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </Dropzone>
              <SelectorButton type="submit" disabled={isLoading} value={UPLOAD_MODES[values.uploadMode]}>
                <Selection data-name="PUBLISH" onClick={e => this.setMode(e, setFieldValue)}>
                  Publish now
                </Selection>
                <Selection data-name="QUEUE" onClick={e => this.setMode(e, setFieldValue)}>
                  Add to queue
                </Selection>
                <Selection data-name="SCHEDULE" onClick={e => this.setMode(e, setFieldValue)}>
                  Publish at...
                </Selection>
              </SelectorButton>
              {values.uploadMode === 'SCHEDULE' && (
                <Fragment>
                  <DateTime value={values.published_at} onChange={value => setFieldValue('published_at', value)} />
                </Fragment>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser.data,
  folders: getFolders(state),
  isLoading: isLoading(state.loading, UPLOADING_IMAGE)
    || isLoading(state.loading, UPLOADING_OTHERFILES),
});

export default connect(mapStateToProps)(BulkUpload);
