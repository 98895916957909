/* node_modules */
import React from 'react';
import classNames from 'classnames/bind';
import {
  node, string, shape, oneOfType, oneOf, bool,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';

/* style imports */
import styles from './Tooltip.scss';
import InfoBox from '../InfoBox';

const cx = classNames.bind(styles);

class Tooltip extends React.Component {
  static propTypes = {
    children: node.isRequired,
    /**
     * If text is an object, a react-intl FormattedMessage will be used in the tooltip
     * any props specified in the object will be passed to the FormattedMessage
     */
    text: oneOfType([string, shape({ id: string, defaultMessage: string })]).isRequired,
    iconClass: string,
    align: oneOf(['left', 'right']),
    responsive: bool,
    withIcon: bool,
  };

  static defaultProps = {
    iconClass: null,
    align: 'right',
    responsive: false,
    withIcon: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      showTip: false,
    };

    this.toggleTip = this.toggleTip.bind(this);
  }

  toggleTip() {
    this.setState({ showTip: !this.state.showTip });
  }

  render() {
    const {
      children,
      text,
      iconClass,
      align,
      responsive,
      withIcon,
      ...props
    } = this.props;
    const { showTip } = this.state;

    if (!withIcon) {
      return (
        <span {...props} className={cx('Tooltip', { 'Tooltip--ShowTip': showTip })}>
          <InfoBox text={text} className={cx('Tooltip__Content', { 'Tooltip__Content--Left': align === 'left', 'Tooltip__Content--Responsive': responsive })} />
          <div
            onMouseEnter={this.toggleTip}
            onMouseLeave={this.toggleTip}
          >
            {children}
          </div>
        </span>
      );
    }

    return (
      <span {...props} className={cx('Tooltip', { 'Tooltip--ShowTip': showTip })}>
        <span
          role="img"
          className={cx('Tooltip__Handle', iconClass || 'ion-information-circled')}
          onMouseEnter={this.toggleTip}
          onMouseLeave={this.toggleTip}
        />
        <InfoBox text={text} className={cx('Tooltip__Content', { 'Tooltip__Content--Left': align === 'left', 'Tooltip__Content--Responsive': responsive })} />
        {children}
      </span>
    );
  }
}

export const SimpleTooltip = ({children, text}) => (
  <div className={cx('SimpleTooltip')}>
    { children }
    <div className={cx('SimpleTooltip_Bottom')}>
      <span>{text}</span>
      <i />
    </div>
  </div>
);

export default Tooltip;
