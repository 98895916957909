/**
 * Chat width selector
 */

import React from 'react';
import classNames from 'classnames/bind';
import { func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import styles from './WidthSelector.scss';

const cx = classNames.bind(styles);

/**
 * @type {{[name: string]: string}}
 */
const ChatSizes = {
  Compact: '400px',
  Normal: '500px',
  Wide: '600px',
};

/**
 * @type {React.FC<{setWidth(width: string): void, currentWidth: string, className: string}>}
 */
const WidthSelector = ({ setWidth, currentWidth, className }) => {
  return (
    <div className={`${styles.WidthSelector} ${className}`}>
      <div className={styles.WidthSelector__Label}>
        <FormattedMessage id="WidthSelector_Title" defaultMessage="Chat width" />
      </div>

      <div className={styles.WidthSelector__Options}>
        {
          Object.entries(ChatSizes).map(([label, size]) => (
            <button
              key={size}
              className={cx(styles.WidthSelector__Option, { active: currentWidth === size })}
              type="button"
              onClick={() => setWidth(size)}
              title={label}
            >
              {label}
            </button>
          ))
        }
      </div>
    </div>
  );
};

WidthSelector.propTypes = {
  setWidth: func.isRequired,
  currentWidth: string.isRequired,
  className: string.isRequired,
};

export default WidthSelector;
