import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import Button from '../Button';

import pix from './26878773-MSG.webp';

import { HomepageBanner, HomepageBannerCTA, HomepageBannerDescription, HomepageBannerImage, HomepageBannerTitle } from '../HomepageBanner';
import styles from './index.scss';


const cx = classNames.bind(styles);

const ANNOUNCEMENT_DATE = 'Sun, 10 Jan 2021 00:01:00 -0500';

export default function ContestAd ()  {
  const announceResults = useMemo(() => dayjs().isSame(ANNOUNCEMENT_DATE) || dayjs().isAfter(ANNOUNCEMENT_DATE), []);

  if (!announceResults) return null;
  return (
    <HomepageBanner className={cx({ 'ContestAd--VoteEnabled': announceResults })}>
      <HomepageBannerTitle>
        <FormattedMessage id="ContestAd_Meet" defaultMessage="Meet Pix!" />
      </HomepageBannerTitle>

      <HomepageBannerDescription>
        <p>
          <FormattedMessage id="ContestAd_Thank" defaultMessage="Thank you to everyone for your Pix fanart!" />
          <br />
          <FormattedMessage 
            id="ContestAd_Description" 
            defaultMessage="For future fanart posts, please tag them with #PiczelPix in the piczel galleries, Twitter, and wherever else you post your artwork. Special thanks to {usernameLink} for the new header image!"
            values={{
              usernameLink:<Link to="/watch/MSG">MSG</Link>
            }}
          />  
        </p>
      </HomepageBannerDescription>

      
      <HomepageBannerCTA>
        <Button to="/mascotcontest/results">
          <FormattedMessage id="ContestAd_Results" defaultMessage="See results" />
          {' '}
          <span role="img" className="ion-android-arrow-dropright" />
        </Button>
      </HomepageBannerCTA>

      <HomepageBannerImage>
        <Link to="/gallery/image/37111">
          <img width={300} height={321} alt="pix" src={pix} />
        </Link>
      </HomepageBannerImage>
    </HomepageBanner>
  );
};