import { createSelector } from 'reselect';
import { produce } from 'immer';
import config from '~/config';
import { setLoading, isLoading } from './loading';
import { sortById } from '~/helpers/data';
import errorHandling from './_errorHandling';

interface NotificationsState {
  byId: {
    [id: number]: PiczelNotification
  },
  allIds: number[]
}

// State
const INITIAL_STATE: NotificationsState = {
  byId: {},
  allIds: [],
};

// Actions
const REMOVE_NOTIFICATION = 'piczel/notifications/REMOVE';

const UPDATE_NOTIFICATIONS = 'piczel/notifications/UPDATE';
const RESET_NOTIFICATIONS = 'piczel/notifications/RESET';

// Constants
export const LOADING_NOTIFICATIONS = 'piczel/notifications/LOADING_NOTIFICATIONS';

/**
 * Sorts the notifications returned by sortById
 * @param {{allIds: number[], byId: {[id: number]: Notification}}} state
 */
const sorted = ({ allIds, byId }) => {
  allIds.sort((a, b) => b - a);

  return {
    byId,
    allIds,
  };
};

// Reducer
export default function notificationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REMOVE_NOTIFICATION:
      return produce(state, (draft) => {
        draft.allIds = draft.allIds.filter(id => id !== action.id);
      });

    case UPDATE_NOTIFICATIONS:
      return produce(state, (draft) => {
        action.payload.forEach((notification) => {
          draft.allIds.push(notification.id);
          draft.byId[notification.id] = notification;
        });
      });
      // return sorted(sortById(state, action.payload));

    case RESET_NOTIFICATIONS:
      return INITIAL_STATE;

    default: return state;
  }
}

// Action creators
export function deleteNotification(id) {
  return { type: REMOVE_NOTIFICATION, id };
}

export function updateNotifications(payload) {
  return { type: UPDATE_NOTIFICATIONS, payload };
}

export function resetNotifications() {
  return { type: RESET_NOTIFICATIONS };
}

/**
 * Fetches all notifications from the server
 */
export function fetchNotifications() {
  return async (dispatch, getState, fetch: typeof window.fetch) => {
    if (isLoading(getState().loading, LOADING_NOTIFICATIONS)) return;
    dispatch(setLoading(LOADING_NOTIFICATIONS, true));
    /**
     * We'll leave the logic for notification merging to the backend
     * also ensures old 'now live' notifications aren't seen by the user
     * after the stream is offline
     */
    dispatch(resetNotifications());

    const res = await fetch(`${config.api}/users/me/notifications`);
    const json = await errorHandling(dispatch, res);

    dispatch(updateNotifications(json));
    dispatch(setLoading(LOADING_NOTIFICATIONS, false));
  };
}

/**
 * Clears a single notification, deleting it on the server.
 */
export function removeNotification(id) {
  return (dispatch, getState, fetch: typeof window.fetch) => {
    dispatch(deleteNotification(id));

    return fetch(`${config.api}/users/me/notifications/${id}`, {
      method: 'DELETE',
    });
  };
}

/**
 * Resets the state of notifications and tells the server to delete them.
 */
export function clearNotifications() {
  return (dispatch, getState, fetch: typeof window.fetch) => fetch(`${config.api}/users/me/notifications`, {
    method: 'DELETE',
  }).then(() => {
    dispatch(resetNotifications());
  });
}

export function addNotification(notification) {
  return (dispatch, getState) => {
    const notifications = allNotifications(getState().notifications);

    return dispatch(updateNotifications([...notifications, notification]));
  }
}

// Selectors
export const allNotifications = createSelector(
  state => state.byId,
  state => state.allIds,
  (notifications, ids) => ids.map(id => notifications[id]),
);

export function gotNewNotifications(state) {
  if (allNotifications(state).some(note => note.read === false)) return true;

  return false;
}
