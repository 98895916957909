import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import FormattedMessage from '~/components/common/FormattedMessage';

import styles from './Checkbox.scss';

const cx = classNames.bind(styles);

/**
 * Checkbox component (that looks like a toggle)
 * To be used in the settings page
 * @type {React.FC<{
 *  className?: string,
 *  name: string,
 *  checked: boolean,
 *  onChange: (e: import('react').ChangeEvent<HTMLInputElement>) => void,
 *  children: import('react').ReactChild,
 *  disabled?: boolean}
 * >}
 */
const Checkbox = ({
  name, checked, onChange, children, disabled, className, forPremium,
}) => (
  <div className={cx('Checkbox', { 'Checkbox--disabled': disabled }, className)}>
    <label className={cx('Checkbox__Label')}>
      <span className={cx('Checkbox__Text')}>{children}</span>

      <input
        name={name}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        className={cx('Checkbox__Input')}
        type="checkbox"
      />
      <div className={cx('Checkbox__Toggle')} />
    </label>
    {disabled && (
      <Link className={cx('Checkbox__Premium')} to="/account/patreon">
        <FormattedMessage id="Checkbox_PremiumOnly" defaultMessage="This is a feature for Premium users." />
      </Link>
    )}
  </div>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired, // Sometimes required for onChange
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  /** Whether or not it is a premium feature */
  forPremium: PropTypes.bool,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  disabled: false,
  forPremium: false,
  className: '',
};

export default Checkbox;
