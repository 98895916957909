import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import styles from './AvatarGroup.scss';

const cx = classNames.bind(styles);

const AvatarGroup = ({ children, display, className }) => {
  return (
    <div className={cx('AvatarGroup', className)}>
      {
        Array.from(children).slice(0, display).map((element, i) => React.cloneElement(element, {
          style: { zIndex: children.length - i },
          className: cx('AvatarGroup__Item'),
        }))
      }
      {
        children.length > display && (
          <div className={cx('AvatarGroup__More')}>
            +
            {children.length - display}
          </div>
        )
      }
    </div>
  );
};

AvatarGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  display: PropTypes.number,
  className: PropTypes.string,
};

AvatarGroup.defaultProps = {
  display: 4,
  className: null,
};

export default AvatarGroup;
