import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { LL_HLS } from '~/components/StreamPlayer/playerTechs';

const OvenPlayerContainer = loadable(() => import('~/components/OvenPlayer'));

export default function DebugPlayer() {
  const [player, setPlayer] = useState('ovenplayer');
  const [src, setSrc] = useState('http://localhost:8080/hls/113.m3u8'); //https://llhls-demo.ovenmediaengine.com/app/stream/llhls.m3u8
  useEffect(() => {
    document.title = 'Debug Player';
    document.body.style.background = 'white';
    const header = document.querySelector('header');
    if (header) {
      header.style.display = 'none';
    }
  }, []);


  return (
    <>
      <select value={player} onChange={e => setPlayer(e.target.value)}>
        <option value="ovenplayer">OvenPlayer</option>
        <option value="videojs">video.js</option>
      </select>

      <label>
        Source
        <input type="text" value={src} onChange={e => setSrc(e.target.value)} />
      </label>

      <OvenPlayerContainer
        vjsTech={LL_HLS}
        debugSources={player === 'ovenplayer' ? [
          {
            file: src,
            label: 'HLS',
            type: 'application/x-mpegURL',
          },
        ] : {
          src: src,
          type: 'application/vnd.apple.mpegurl'
        }}
        setDefault={() => null}
        stream={{
          id: 1,
          live_since: new Date(),
          recordings: [],
          title: 'Debug Player',
          description: 'This is a debug player',
          user: {
            id: 1,
            username: 'Debug Player',
            avatar: 'https://avatars.githubusercontent.com/u/1024025?v=4',
          },
          thumbnail: 'https://i.imgur.com/3b3q6xR.png',
        }}
      />
    </>
  );
}
