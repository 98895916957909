import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import FormattedMessage from '~/components/common/FormattedMessage';
import ContentBlur from '~/components/ContentBlur';

import styles from './ImageContent.scss';

const cx = classNames.bind(styles);

/**
 * Displays gallery content based on type
 */
const Content = (props) => {
  const [resizedFailed, setResizedFailed] = useState(false);

  const [isClicked, setClicked] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const imgRef = useRef(null);
  const {
    blur,
    onClick: clickFunction,
    image: { url, large, webp },
    title,
    is_video: isVideo,
    is_flash: isFlash,
    isPresentational,
  } = props;

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      try {
        if (webp) {
          const response = await fetch(webp.url, { signal: controller.signal });
          if (response.status === 404) {
            setResizedFailed(true);
          }
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          // Error
        }
      }
    })();
    return () => controller.abort();
  }, [webp]);

  useEffect(() => {
    setIsImageLoaded(false);
  }, [url]);

  useEffect(() => {
    if (imgRef.current) {
      setIsImageLoaded(imgRef.current.complete);
    }
  }, [imgRef]);

  const getEmbed = () => {
    if (isVideo) {
      return <video loop autoPlay muted src={url} />;
    }

    if (isFlash && isClicked) {
      const {
        width,
        height,
      } = props;

      const flooredWidth = Math.min(width, window.innerWidth);
      const flooredHeight = (height / width) * Math.min(width, window.innerWidth);

      return (
        <embed
          wmode="transparent"
          scale="noborder"
          type="application/x-shockwave-flash"
          src={url}
          width={`${flooredWidth}px`}
          height={`${flooredHeight}px`}
        />
      );
    }

    if (isFlash) {
      return (
        <img
          src="/img/piczelflash.png"
          alt="Flash content, click to play"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setClicked(true);
          }}
          loading="lazy"
        />
      );
    }

    return (
      <picture>
        {
          !isImageLoaded && (
          <div className={cx('ImageContent__Loader')}>
            <div className={cx('ImageContent__Loader__Bar')} />
          </div>
          )
        }
        {
          webp && !resizedFailed && (
            <source srcSet={webp.url} type="image/webp" />
          )
        }
        {
          large && !resizedFailed && (
            <source srcSet={large.url} type="image/jpg" />
          )
        }

        <source srcSet={url} />

        <img
          src={url}
          title={title}
          alt={title}
          ref={imgRef}
          onLoad={() => setIsImageLoaded(true)}
        />
      </picture>
    );
  };

  return (
    <ContentBlur content={props}>
      {!isPresentational
        ? (
          <a href={url}>
            {getEmbed()}
            <span className={cx('ImageContent__Hint')}>
              <FormattedMessage id="ImageContent_FullSize" defaultMessage="Click to see full size" />
            </span>
          </a>
        ) : getEmbed()}
    </ContentBlur>
  );
};

Content.propTypes = {
  /** Title of the displayed content */
  title: PropTypes.string,
  /** Url of the displayed content. This is currently broken. */
  url: PropTypes.string,
};

export default Content;
