import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './Modal.scss';

const Modal = ({ children, title, close, className }) => {
  const ref = useRef(null);
  const canClose = !!close;

  useEffect(() => {
    if (!canClose) return;

    function clickawayListener(e) {
      const box = ref.current;
      if (!box) return;

      if (!box.contains(e.target) && e.target !== box) close();
    }

    function escListener(e) {
      if (e.keyCode === 27) close();
    }

    document.addEventListener('click', clickawayListener, true);
    document.addEventListener('keydown', escListener);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('click', clickawayListener, true);
      document.removeEventListener('keydown', escListener);
    };
  }, [canClose, close]);

  return (
    <div ref={ref} className={`${styles.Modal} ${className}`}>
      <div className={styles.Modal__Title}>
        {title}
      </div>
      { canClose && <button className={styles.Modal__Close} aria-label="Close" type="button" onClick={close}><span role="img" className="ion-close-circled" /></button> }

      { children }
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.node,
};

Modal.defaultProps = {
  className: '',
  title: '',
  close: null,
};

export default Modal;
