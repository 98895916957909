import { configureFetch } from './helpers/fetch';

/*
* Slightly modified version of redux-thunk
* Now the fetch function is injected in the action creator's parameters
*/
function createThunkMiddleware() {
  return ({ dispatch, getState }) => next => (action) => {
    if (typeof action === 'function') {
      const fetch = configureFetch(getState, dispatch);

      return action(dispatch, getState, fetch);
    }

    return next(action);
  };
}

export const thunk = createThunkMiddleware();
