/* node_modules */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

/* local imports */
import Config from '~/config';

import { setLocale } from '~/modules/translations';

/* style imports */
import styles from './LanguageSelector.scss';

const cx = classNames.bind(styles);

/**
 * Language list point
 */
const Language = ({
  langCode, handleClick, children, active,
}) => (
  <li
    onClick={active ? null : () => handleClick(langCode)}
    className={cx({
      LanguageSelector__Option: true,
      'LanguageSelector__Option--active': active,
    })}
  >
    {children}
  </li>
);

const LanguageSelector = ({ dispatch, currentLocale }) => {
  const [active, setActive] = useState(false);
  const ref = useRef(null);
  const handleClick = language => dispatch(setLocale(language));

  useEffect(() => {
    if (active) {
      const clickawayListener = (e) => {
        const list = ref.current;

        if (!list) return;

        if (!list.contains(e.target) && e.target !== list) {
          setActive(false);
        }
      };

      document.addEventListener('click', clickawayListener);

      return () => {
        document.removeEventListener('click', clickawayListener);
      }
    }
  }, [active]);
  
  return (
    <div
      ref={ref}
      className={cx({
        LanguageSelector: true,
        'LanguageSelector--active': active,
      })}
      onClick={() => setActive(!active)}
    >
      <ul className={styles.LanguageSelector__CustomSelect}>
        {Object.keys(Config.languages).map((code, i) => (
          <Language
            langCode={code}
            handleClick={handleClick}
            active={currentLocale === code}
            key={code}
          >
            {Config.languages[code]}
          </Language>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSelector;
