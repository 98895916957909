/**
 * Basic UI components for autocomplete
 */

import React, { useRef, useCallback, useEffect, useState } from 'react';
import { node, string, oneOfType, arrayOf, func, any } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Autocomplete.scss';

const cx = classNames.bind(styles);


export const AutocompletePane = ({ children, ...props }) => {
  const ref = useRef(null);

  const [userDisabled, setUserDisabled] = useState(false);

  useEffect(() => {
    function clickawayListener(e) {
      const pane = ref.current;
      if (!pane) return;

      if (e.target !== pane && !pane.contains(e.target)) {
        setUserDisabled(true);
      }
    }

    function escListener(e) {
      if (e.keyCode === 27) setUserDisabled(true);
    }

    document.addEventListener('keydown', escListener);
    document.addEventListener('click', clickawayListener);

    return () => {
      document.removeEventListener('keydown', escListener);
      document.removeEventListener('click', clickawayListener);
    };
  }, []);

  if (userDisabled) return null;

  return (
    <div ref={ref} className={styles.Autocomplete__Pane} {...props}>
      {children}
    </div>
  );
};

AutocompletePane.propTypes = {
  children: node.isRequired,
};

/**
 * @type {React.FC<{ subTitle: string }>}
 */
export const AutocompleteTitle = ({ children, subTitle, ...props }) => (
  <div className={styles.Autocomplete__Title} {...props}>
    { children }

    {
      subTitle && (
        <div className={styles.Autocomplete__TitleSmall}>
          {subTitle}
        </div>
      )
    }
  </div>
);

AutocompleteTitle.propTypes = {
  children: node.isRequired,
  subTitle: string,
};

AutocompleteTitle.defaultProps = {
  subTitle: null,
};


export const AutocompleteSuggestions = ({ children, blur, ...props }) => {
  const [focusedId, setFocusedId] = useState(null);
  const [focusedEl, setFocusedEl] = useState(null);
  const focusedRef = useRef(null);
  const containerRef = useRef(null);
  const isMobile = /Android|iPhone/.test(navigator.userAgent);

  const setFocused = useCallback((element) => {
    setFocusedEl(element);
    if (element.dataset.id) {
      setFocusedId(element.dataset.id);
    }
  }, []);

  const onFocus = useCallback((e) => {
    setFocused(e.target);

    e.preventDefault();
  }, [setFocused]);

  useEffect(() => {
    const container = containerRef.current;

    // focus first child when children change
    if (container && container.firstChild) {
      const matchingId = Array.from(container.children).find(element => element.dataset.id === focusedId);

      const focus = matchingId || container.firstChild;
      
      setFocused(focus);
      focusedRef.current = focus;
    }
  }, [children, isMobile, setFocused, focusedId]);

  useEffect(() => {
    /**
     * Moves the focus whenever the appropiate keys are pressed
     * or triggers the focused suggestion's click handler when Tab or Enter are pressed
     * @param {KeyboardEvent} e
     */
    function moveFocus(e) {
      /**
       * @type {HTMLDivElement?}
       */
      const currentContainer = containerRef.current;
      /**
       * @type {HTMLButtonElement?}
       */
      const focused = focusedRef.current;

      /**
       * Whether the 'focused' ref actually lost focus or was unmounted
       */
      const isFocusLost = (!focused || (focused && !focused.parentElement));
      
      if (isFocusLost && currentContainer && (e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
        /**
         * If arrow up is pressed, focus the lowest element on the list, if arrow down is pressed, focus the highest
         * @type {HTMLButtonElement|null}
         */
        const target = e.key === 'ArrowUp' ? currentContainer.lastChild : currentContainer.firstChild;
        if (target) {
          setFocused(target);
          focusedRef.current = target;
        }
        return;
      }

      if (!focused) return;

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        e.stopPropagation();

        const next = focused.previousSibling ? focused.previousSibling : focused.parentElement.lastChild;
        setFocused(next);
        focusedRef.current = next;
        return;
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        e.stopPropagation();

        const next = focused.nextSibling ? focused.nextSibling : focused.parentElement.firstChild;
        setFocused(next);
        focusedRef.current = next;
        return;
      }

      if (e.key === 'Tab' || e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();

        if (focusedRef.current) {
          focusedRef.current.click();
        }
        return;
      }

      // give back focus to the text input if any other key is pressed
      blur();
    }

    window.addEventListener('keydown', moveFocus, {
      capture: true,
    });

    return () => window.removeEventListener('keydown', moveFocus, {
      capture: true,
    });
  }, []);

  useEffect(() => {
    if (containerRef.current && focusedRef.current) {
      /**
       * @type {HTMLDivElement|null}
       */
      const currentContainer = containerRef.current;
      /**
       * @type {HTMLButtonElement|null}
       */
      const focused = focusedRef.current;

      /**
       * Whether the 'focused' ref actually lost focus or was unmounted
       */
      const isFocusLost = (focused && !focused.parentElement);

      if (isFocusLost && currentContainer && currentContainer.firstChild) {
        setFocused(currentContainer.firstChild);
      }
    }
  }, [containerRef, focusedRef, setFocused]);

  return (
    <div ref={containerRef} className={styles.Autocomplete__Suggestions} {...props}>
      { typeof children === 'function' ? children(onFocus, focusedEl) : children }
    </div>
  );
};

AutocompleteSuggestions.propTypes = {
  children: oneOfType([node, arrayOf(node), func]).isRequired,
  /**
   * Should give back focus to the text input
   */
  blur: func.isRequired,
};

export const SuggestionIcon = ({ src, alt }) => (
  <div className={styles.Autocomplete__SuggestionIcon}>
    {
      (typeof src === 'string') ? (
        <img loading="lazy" src={src} alt={alt} />
      )
        : (src)
    }
  </div>
);

SuggestionIcon.propTypes = {
  /**
   * Absolute URL to the image, or, a custom element,
   * 
   * For example:
   * ```js
   * <SuggestionIcon src={{
   *  <svg>
   * ....
   * </svg>
   * }} />
   */
  src: oneOfType([string, node]).isRequired,
};

export const AutocompleteSuggestion = ({ label, icon, onClick, onFocus, focused, description }) => {
  const ref = useRef(null);

  const isFocused = ref.current && focused !== null && ref.current === focused;

  return (
    <button
      ref={ref}
      onClick={onClick}
      className={cx('Autocomplete__Suggestion', { 'Autocomplete__Suggestion--Focused': isFocused })}
      type="button"
      onFocus={onFocus}
      data-id={typeof label === 'string' ? label : undefined}
    >
      { icon }
      <span className={styles.Autocomplete__SuggestionLabel}>
        { label }
      </span>

      {
        description && (
          <span className={styles.Autocomplete__SuggestionDescription}>
            { description }
          </span>
        )
      }
    </button>
  );
};

AutocompleteSuggestion.propTypes = {
  /**
   * The label that will appear on this suggestion
   */
  label: string.isRequired,

  /**
   * The <SuggestionIcon /> for this suggestion
   */
  icon: node,

  onClick: func.isRequired,

  onFocus: func,

  /**
   * A ref to the current focused element
   * 
   * Ideally, this proptype would be `instanceOf(HTMLElement)`, but node.js throws a `HTMLElement` is undefined when running the server
   */
  focused: any,

  /**
   * Additional information about the current option
   */
  description: string,
};

AutocompleteSuggestion.defaultProps = {
  icon: null,
  onFocus: null,
  focused: null,
  description: null,
};
