/**
 * "freezes" gifs
 */
class GifFreeze {
  /**
   * Create a new instance of frozen gifs
   * @param {NodeListOf<HTMLImageElement>} images List of images to freeze
   */
  constructor(images) {
    this.images = images;
    this.wrappers = [];
  }

  /**
   * Wraps gifs around a wrapper to control their animation
   *
   * @param {string} className The classname for the wrapper
   */
  freeze(className = 'FrozenGif') {
    this.images.forEach((image) => {
      if (image.dataset.frozen || !image.src.endsWith('.gif')) return;
      const wrapperEl = document.createElement('div');
      wrapperEl.className = className;
      const canvasEl = document.createElement('canvas');

      /**
       * @type {HTMLCanvasElement|null}
       */
      const canvas = wrapperEl.appendChild(canvasEl);

      if (canvas) {
        const ctx = canvas.getContext('2d');

        if (!ctx) return;

        const drawGif = () => {
          const imgWidth = image.width || 50;
          const imgHeight = image.height || 50;
          wrapperEl.style.height = `${imgHeight}px`;
          wrapperEl.style.width = `${imgWidth}px`;

          canvas.width = imgWidth;
          canvas.height = imgHeight;

          ctx.drawImage(image, 0, 0, imgWidth, imgHeight);

          const cloned = image.cloneNode(false);

          wrapperEl.appendChild(cloned);

          image.replaceWith(wrapperEl);
        };

        if (image.complete) {
          drawGif();
        } else {
          image.addEventListener('load', drawGif);
        }

        this.wrappers.push(wrapperEl);
        image.dataset.frozen = 'true';
      }
    });
  }

  unfreeze() {
    this.wrappers.forEach((wrapper) => {
      const img = wrapper.querySelector('img');
      if (img) {
        delete img.dataset.frozen;

        wrapper.replaceWith(img);
      }
    });
  }
}

export default GifFreeze;
