import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { setSidePaneWidth } from '~/modules/chat';

import styles from './SidePane.scss';

export const SIDE_PANE_WIDTH = 320;

const cx = classNames.bind(styles);

export const Collapsible = ({ title, children, startOpen }) => {
  const [open, setOpen] = useState(startOpen);

  return (
    <div className={cx('Collapsible', { 'Collapsible--Open': open })}>
      <button type="button" onClick={() => setOpen(!open)} className={styles.Collapsible__Title}>
        {title}
      </button>
      <div className={styles.Collapsible__Content}>
        { children }
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  startOpen: PropTypes.bool,
};

Collapsible.defaultProps = {
  startOpen: true,
};

const SidePane = ({ title, children, isOpen, className }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      const pane = ref.current;

      if (pane) {
        dispatch(setSidePaneWidth(pane.offsetWidth));
      }
    }
  }, [isOpen, dispatch]);


  if (!isOpen) return null;

  return (
    <div ref={ref} className={`${styles.SidePane} ${className}`}>
      { title && <h2 className={styles.SidePane__Title}>{ title }</h2> }

      <div className={styles.SidePane__Content}>
        { children }
      </div>
    </div>
  );
};

SidePane.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  /**
   * Whether or not the pane is rendered
   */
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

SidePane.defaultProps = {
  title: null,
  className: '',
};

export default SidePane;
