/**
 * Auth API helpers
 */

import fetch from 'isomorphic-fetch';
import config from '../config';

const mountedAt = '/auth';

/**
 * Wrapper function for API calls
 * Automatically adds the "/auth" prefix to the selected endpoint
 */
export async function api(endpoint, options) {
  const url = `${config.api}${mountedAt + (endpoint.startsWith('/') ? '' : '/')}${endpoint}`;

  const response = await fetch(url, options);

  if (!response.ok) {
    console.warn(`[API] Error while loading <${url}>`);
  }

  return response;
}

/**
 * Shortcut to make POST requests to the auth api
 * Takes care of encoding the params and using the right headers
 * @param {String} endpoint
 * @param {Object} data
 */
export function post(endpoint, data) {
  return api(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
