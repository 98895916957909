import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Modal from '../../Modal';
import styles from './index.scss';

const cx = classNames.bind(styles);

const ModalIsertLink = ({
  close, isOpen, insertLink, wrap,
}) => {
  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const text = !wrap && e.currentTarget.url_text.value;
    const url = e.currentTarget.url.value;
    insertLink({ url, text, wrap });
  };

  return (
    <Modal onRequestClose={close} isOpen={isOpen}>
      <div className={cx(['ModalInsertLink'])}>
        <form onSubmit={onSubmit}>
          <h3>
            <FormattedMessage
              id="ModalInsertLink_Title"
              defaultMessage="Insert Link"
            />
          </h3>
          {!wrap && (
            <label htmlFor="url_text" className={cx(['ModalInsertLink__Message'])}>
              <FormattedMessage
                id="ModalInsertLink_Url_Text"
                defaultMessage="Text"
              />
              <input
                name="url_text"
                id="url_text"
                type="text"
                placeholder="Example"
              />
            </label>
          )}
          <label htmlFor="url" className={cx(['ModalInsertLink__Message'])}>
            <FormattedMessage
              id="ModalInsertLink_Url"
              defaultMessage="URL"
            />
            <input
              name="url"
              id="url"
              type="url"
              placeholder="https://example.com/"
            />
          </label>
          <div className={cx(['ModalInsertLink__Save'])}>
            <button type="submit">
              <FormattedMessage
                id="Button_Add"
                defaultMessage="Add"
              />
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

ModalIsertLink.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  insertLink: PropTypes.func.isRequired,
  wrap: PropTypes.bool.isRequired,
};

export default ModalIsertLink;
