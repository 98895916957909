import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import config from '~/config';

import Avatar from '~/components/Avatar';
import ImagePreview from '../Gallery/ImagePreview';
import { StreamPreview } from '../Stream/List/StreamPreview';

import styles from './LinkPreview.scss';

const cx = classNames.bind(styles);

function useExtracPiczelLinks(text) {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const pattern = /https?:\/\/(?:www\.)?piczel.tv\/((gallery\/image)|(watch)|(gallery))\/([a-zA-Z0-9]*)/mg;
    setLinks(text.match(pattern) || []);
  }, [text]);

  return links;
}

const postGallery = '/gallery/image/';
const gallery = '/gallery/';
const watch = '/watch/';

function getTypeLink(link) {
  if (link.includes(postGallery)) {
    return postGallery;
  } if (link.includes(gallery)) {
    return gallery;
  } if (link.includes(watch)) {
    return watch;
  }
}

function getDetailsLink(link) {
  let route;
  if (link.includes(postGallery)) {
    const id = link.split(postGallery)[1] || '';
    route = `/gallery/${id}`;
  } else if (link.includes(gallery)) {
    const id = link.split(gallery)[1] || '';
    route = `/users/${id}/gallery?limit=4`;
  } else if (link.includes(watch)) {
    const id = link.split(watch)[1] || '';
    route = `/streams/${id}`;
  }
  const url = `${config.api}${route}`;

  return fetch(url).then(res => res.json());
}

const UrlStreamPreview = (props) => {
  const { username, adult, onClick } = props;
  const [showSpiler, setShowSpoiler] = useState(!adult);

  const clickShowSpoiler = useCallback(() => setShowSpoiler(true), [setShowSpoiler]);

  return (
    <div>
      <div>
        <Avatar className={styles.StreamPreview__Avatar} username={username} user={username} />
        <span>{username}</span>
      </div>
      <div style={{ position: 'relative' }}>
        <div onClick={(e) => onClick(null, e)}>
          <StreamPreview stream={props} />
        </div>
        {
          !showSpiler && (
            <div onClick={clickShowSpoiler} className={styles.StreamPreview__Spoiler} />
          )
        }
      </div>
    </div>
  );
};

const UrlImagePreview = (props) => {
  const { nsfw, onClick } = props;
  const [showSpiler, setShowSpoiler] = useState(!nsfw);
  const clickShowSpoiler = useCallback(() => setShowSpoiler(true), [setShowSpoiler]);

  return (
    <div>
      <ImagePreview {...props}  onClick={onClick} />
      {
        !showSpiler && (
          <div onClick={clickShowSpoiler} className={styles.GalleryPostPreview__Spoiler} />
        )
      }
    </div>
  );
};

const GalleryPreview = (props) => {
  const { images } = props;

  return images.map((image) => {
    const thumbUrl = image.image.thumb.url;

    return <div className={styles.GalleryPreview__Thumb} style={{ backgroundImage: `url(${thumbUrl})` }} key={image.id} />;
  });
};

const LinkPreview = ({ msg, onLoadPreview }) => {
  const links = useExtracPiczelLinks(msg.text);
  const [details, setDetails] = useState(null);
  const [error, setError] = useState(false);

  const onClick = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(links[0]);
  };

  useEffect(() => {
    if (links.length) {
      getDetailsLink(links[0]).then((res) => {
        if (res.status === 'error' || (Array.isArray(res) && !res.length)) {
          setError(true);
        } if (res.errors && res?.meta?.wrong_pass) {
          setDetails({ data: res.meta.wrong_pass });
          onLoadPreview();
        } else {
          setDetails(res);
          onLoadPreview();
        }
      });
    }
  }, [links]);

  return (
    <>
      {!!links.length && !error && (
        <div
          className={cx({
            GalleryPostPreview: getTypeLink(links[0]) === postGallery,
            GalleryPreview: getTypeLink(links[0]) === gallery,
            StreamPreview: getTypeLink(links[0]) === watch,
          })}
        >
          {
            details && getTypeLink(links[0]) === postGallery && (<UrlImagePreview {...details}  onClick={onClick} />)
          }
          {
            details && getTypeLink(links[0]) === gallery && (
              <>
                <div onClick={e => onClick(null, e)}>
                  <Avatar className={styles.GalleryPreview__Avatar} username={details[0].user.username} user={details[0].user} />
                  <span>{details[0].user.username}</span>
                </div>
                <div onClick={e => onClick(null, e)} className={styles.GalleryPreview__Thumbs}>
                  <GalleryPreview images={details} />
                </div>
              </>
            )
          }
          {
            details && getTypeLink(links[0]) === watch && (
              <UrlStreamPreview {...details.data[0]} onClick={onClick} />
            )
          }
        </div>
      )}
      <div dangerouslySetInnerHTML={{ __html: msg.text }} />
    </>
  );
};

export default LinkPreview;
