// @ts-check
/* eslint-disable camelcase */
/**
 * Homebrew chat client
 */
 import React, {
  useState, useEffect, useRef,
  useCallback, useContext
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import io from 'socket.io-client';
import {
  arrayOf, bool, func, number,
  shape, string,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import className from 'classnames/bind';
import { Link } from 'react-router-dom';

import {
  joinRoom, getRooms, clearRooms,
  patchRoom, leaveRoom, getActiveRoom, setActiveRoom,
  getModal, getNotificationReminder,
  addPendingRooms,
  getZoom,
  getSidePaneWidth,
  getOptions,
  getCurrentRoom,
  markRoomsJoined,
  getAccessToken,
  setBlockList,
  getCurrentUser,
  setAccessToken,
} from '~/modules/chat';
import { clearHistory, updateMessage } from '~/modules/chatMessages';
import Options, { fontOptions, defaultFont } from './Options';

import { SubscriptionContext } from '~/services/cable';
import config from '~/config';

import MessagePane from './MessagePane';
import ChatRoster from './ChatRoster';
import { addFlash } from '~/modules/flashes';
import { ChatContext, events } from './ChatContext';
import ChatOverview from './UserOverview';
import ActionModal from './ActionModal';
import Button from '../Button';
import Modal from './Modal';
import { getPlayingUsernames } from '~/modules/streams';
import RafflePrompt from './RafflePrompt';
import HomebrewNav from './HomebrewNav';
import ChatRoomSubject from './ChatRoomSubject';
import HomebrewFooter from './HomebrewFooter';

import Worker from './Message.worker';

import styles from './Homebrew.scss';

const cx = className.bind(styles);

// chat states
const CONNECTED = 'CONNECTED';
const CONNECTING = 'CONNECTING';
const DISCONNECTED = 'DISCONNECTED';
const RECONNECT_FAILED = 'RECONNECT_FAILED';

const USER_LIST = 'USER_LIST';
const OPTIONS = 'OPTIONS';

const CHAT_DEFAULT_WIDTH = 400; // px

const worker = typeof window !== 'undefined' ? new Worker() : null;

const Homebrew = ({
  streams, width, setWidth, isMobile, overlayMode,
}) => {
  const { client, setClient } = useContext(ChatContext);
  const overviewElement = useRef(null);
  const dispatch = useDispatch();
  const { chatFont, pmsDisplay } = useSelector(getOptions);

  const streamSubscriptions = useContext(SubscriptionContext);
  const [connectionState, setConnectionState] = useState(DISCONNECTED);

  const [widthBeforeOptions, setWidthBeforeOptions] = useState(width);
  const [sidePaneOpen, setSidePaneOpen] = useState(null);
  const [overviewUser, setOverviewUser] = useState(null);
  const [leavingPiczel, setLeavingPiczel] = useState(null);

  const playingUsernames = useSelector(getPlayingUsernames);
  const sidePaneWidth = useSelector(getSidePaneWidth);
  const activeRoom = useSelector(getActiveRoom);
  const rooms = useSelector(getRooms);
  const modalOpen = useSelector(getModal);
  const notificationReminder = useSelector(getNotificationReminder);
  const zoom = useSelector(getZoom);
  const currentRoom = useSelector(getCurrentRoom);

  const accessToken = useSelector(getAccessToken);
  const currentUser = useSelector(getCurrentUser);

  const navigateTo = useCallback((link) => {
    const el = document.createElement('a');
    el.target = '_blank';
    el.href = link;
    el.rel = 'noopener noreferrer';
    el.click();
    setLeavingPiczel(null);
  }, []);

  const onClick = useCallback((e) => {
    if (localStorage.trustChatLinks === '1') return;

    if (e.target.tagName === 'A' && !e.target.href.startsWith('https://piczel.tv/')) {
      // leaving piczel!
      e.preventDefault();
      e.stopPropagation();
      setLeavingPiczel(e.target.href);
    }
  }, []);

  const showPrivateMessageOnActiveRoom = useCallback((message) => {
    worker.postMessage({
      type: 'ADD_MESSAGE',
      options: {},
      roomId: activeRoom,
      message: {
        ...message,
        isPrivate: true,
        id: `${message.id}-pm`,
      },
    });
  }, [activeRoom]);

  const setOverview = useCallback((messageId, e) => {
    setOverviewUser(messageId);
    overviewElement.current = e.target;
  }, []);

  const setModeratedChat = useCallback((value) => {
    client?.emit(events.UPDATE_ROOM, {
      roomId: currentRoom.id,
      data: {
        moderated: value,
      }
    });

    dispatch(patchRoom({
      id: currentRoom.id,
      moderated: false,
    }));
  }, [dispatch, currentRoom, client]);

  const toggleSidePane = useCallback((section) => {
    const currentWidth = width ? parseInt(width, 10) : CHAT_DEFAULT_WIDTH;

    if (sidePaneOpen === section) {
      if ((widthBeforeOptions + sidePaneWidth) === currentWidth) {
        setWidth(`${widthBeforeOptions}px`);
      }

      setSidePaneOpen(null);
      return;
    }

    if (!sidePaneOpen && section) {
      setWidthBeforeOptions(currentWidth);
    }

    setSidePaneOpen(section);
  }, [sidePaneOpen, setWidth, width, widthBeforeOptions, sidePaneWidth]);

  useEffect(() => {
    if (sidePaneOpen) {
      setWidth(`${widthBeforeOptions + sidePaneWidth}px`);
    }
  }, [sidePaneWidth, sidePaneOpen, widthBeforeOptions, setWidth]);

  useEffect(() => {
    if (activeRoom && rooms.every(room => room.id !== activeRoom)) {
      const room = rooms.slice(-1)[0];
      if (room) { dispatch(setActiveRoom(room.id, true)); }
    }
  }, [rooms, activeRoom, dispatch]);

  useEffect(() => {
    if (!accessToken) {
      setConnectionState(CONNECTING);

      return;
    }

    const socket = io(config.chat_host, {
      path: '/chat',
      query: {
        token: accessToken,
        skipAutojoin: overlayMode ? '1' : '0',
      },
      reconnectionAttempts: 5,
      reconnectionDelayMax: 15000,
    });
    
    socket.on('connect_error', (err) => {
      console.log(err);
      if (err.message === 'USERNAME_TAKEN') {
        dispatch(setAccessToken({
          uid: null,
          accessToken: null,
        }));

        dispatch(addFlash('error', 'Username taken'));
      }
    });

    setClient(socket);

    dispatch((_, getState) => {
      const { blockList } = getState().chat;

      worker.postMessage({
        type: 'SET_BLOCKLIST',
        payload: blockList,
      });
    });

    socket.on('connect', () => {
      setConnectionState(CONNECTED);
    });

    socket.io.on('reconnect_attempt', () => setConnectionState(CONNECTING));

    socket.io.on('reconnect_failed', () => setConnectionState(RECONNECT_FAILED));

    socket.on(events.JOIN_ROOM, ({ data }) => {
      worker.postMessage({
        type: 'JOIN_ROOM',
        room: data.room,
      });

      if (!(pmsDisplay === 'inline' && data.room.isPrivate)) {
        dispatch(joinRoom(data.room));
      }

      if (!data.room.isPrivate && data.room.isStreamRoom) {
        dispatch(setActiveRoom(data.room.id));
      }
    });

    socket.on(events.ADD_MESSAGE, ({ data: { roomId, message } }) => {
      dispatch((_, getState) => {
        const state = getState();
        worker.postMessage({
          type: 'ADD_MESSAGE',
          options: state.chat.options,
          roomId,
          message,
        });
      });
    });

    socket.on(events.UPDATE_ROOM, ({ data: { room } }) => {
      dispatch(patchRoom(room));

      if (room.messages && room.messages.length === 0) {
        dispatch(clearHistory(room.id));
      }
    });

    socket.on(events.LEAVE_ROOM, ({ data: { roomId } }) => {
      dispatch(leaveRoom(roomId));

      worker.postMessage({
        type: 'LEAVE_ROOM',
        roomId,
      });
    });

    socket.on(events.NOTIFY, ({ data: { message, kind } }) => {
      if (kind !== 'mention') {
        dispatch(addFlash(kind, message));
      }
    });

    socket.on(events.UPDATE_MESSAGE, ({ data: { message } }) => {
      dispatch(updateMessage(message));
    });

    socket.on(events.SET_BLOCKLIST, ({ data }) => {
      dispatch(setBlockList(data));

      worker.postMessage({
        type: 'SET_BLOCKLIST',
        payload: data,
      });
    });

    socket.on(events.OPEN_PM, ({ id }) => {
      socket.emit(events.JOIN_ROOM, { id });
    });

    return () => {
      if (socket) {
        socket.removeAllListeners();
        socket.disconnect();
      }

      dispatch(clearRooms());
    };
  }, [accessToken, dispatch, setClient, overlayMode, pmsDisplay]);

  useEffect(() => {
    worker.onmessage = (action) => {
      if ((pmsDisplay === 'inline' || pmsDisplay === 'both') && action.data && action.data.payload && action.data.private && action.data.payload.roomId !== activeRoom) {
        showPrivateMessageOnActiveRoom(action.data.payload.message);
        return;
      } if (action.data.private && (action.data.payload.roomId === activeRoom || pmsDisplay === 'tab')) {
        return;
      }
      return dispatch(action.data);
    };
  }, [activeRoom, dispatch, showPrivateMessageOnActiveRoom, pmsDisplay]);

  useEffect(() => {
    if (accessToken && connectionState === CONNECTED && client) {
      const joined = [];

      streams.forEach((stream) => {
        const username = stream.parent_streamer || stream.username;
        if (joined.indexOf(username) === -1) {
          client.emit(events.JOIN_STREAM, {
            username,
          });
          joined.push(username);
        }
      });

      dispatch(addPendingRooms(joined));
    }
  }, [accessToken, connectionState, client, playingUsernames]);

  useEffect(() => {
    if (client) {
      const reconnectHandler = () => {
        // rejoin rooms
        rooms.forEach((room) => {
          if (!room.joined && !room.isStreamRoom) {
            console.log(`Rejoining ${room.name}`);
            client.emit(events.JOIN_ROOM, {
              id: room.id,
            });
          }

          dispatch(markRoomsJoined(true));
        });
        setConnectionState('CONNECTED');
      };

      client.io.on('reconnect', reconnectHandler);

      const disconnectHandler = () => {
        setConnectionState('DISCONNECTED');
        dispatch(markRoomsJoined(false));
      };

      client.on('disconnect', disconnectHandler);

      return () => {
        client.off('disconnect', disconnectHandler);
        client.io.off('reconnect', reconnectHandler);
      };
    }
  }, [client, dispatch, rooms]);

  useEffect(() => {
    const socket = client;

    if (!socket || !streamSubscriptions) {
      if (!socket) console.warn('No socket!');
      if (!streamSubscriptions) console.warn('No stream subscription group object!');
      return;
    }

    const serverMessage = (message, roomName, isAlert = null) => {
      worker.postMessage({
        type: 'ADD_MESSAGE',
        options: {},
        roomName,
        message: {
          id: (Math.random() * 1000000).toString(16),
          serverMessage: true,
          created_at: new Date(),
          component: message,
          alert: isAlert,
        },
      });
    };

    console.log('SubGroup.on');
    streamSubscriptions.on('STARTED_MULTI', ({ peer_name }, stream) => {
      // this room started a multi
      serverMessage({
        id: 'Chat_StartedMulti',
        defaultMessage: '{host} started a multistream with {user}',
        values: {
          user: peer_name,
          host: stream.username,
        },
      }, stream.username, 'info');
    }).on('LEFT_MULTI', ({ peer_name }, stream) => {
      serverMessage({
        id: 'Chat_LeftMulti',
        defaultMessage: '{user} left this multistream {streamLink}',
        values: {
          user: peer_name,
          streamLink: null,
        },
      }, stream.username, 'info');
    }).on('JOINED_MULTI', ({ peer_name }, stream) => {
      // this room joined another multi
      // I *think* making the socket join the room is not necessary since
      // that should be triggered by state changes in parent components
      // we might want to make it leave the current room though
      socket.emit(events.LEAVE_ROOM, {
        roomName: stream.username,
      });

      socket.once(events.JOIN_ROOM, ({ data: { room } }) => {
        if (room.name === peer_name) {
          serverMessage({
            id: 'Chat_JoinedMulti',
            defaultMessage: '{user} joined {host}\'s multistream',
            values: {
              user: stream.username,
              host: peer_name,
            },
          }, room.name, 'info');
        }
      });
    }).on('ADDED_MEMBER', ({ peer_name }, stream) => {
      // the current multistream added a member
      serverMessage({
        id: 'Chat_AddedMember',
        defaultMessage: '{user} joined the multistream',
        values: {
          user: peer_name,
        },
      }, stream.username, 'info');
    });

    // eslint-disable-next-line consistent-return
    return () => {
      console.log('SubGroup.off');

      streamSubscriptions.off();
    };
  }, [streamSubscriptions, client]);

  const [unreadPM, setUnreadPM] = useState(false);

  useEffect(() => {
    const hasUnreadPMs = Object.values(rooms).some(room => room.isPrivate && room.unread) && pmsDisplay === 'tab';

    setUnreadPM(hasUnreadPMs);
  }, [rooms, pmsDisplay]);

  useEffect(() => {
    if (overlayMode) {
      const previousValue = document.body.style.backgroundColor;
      document.body.style.backgroundColor = 'transparent';
      const header = document.querySelector('header');

      if (header) {
        header.style.display = 'none';
      }

      return () => {
        document.body.style.backgroundColor = previousValue;
        if (header) {
          header.style.display = 'block';
        }
      };
    }
  }, [overlayMode]);

  return (
      <div id="Homebrew_ModalPortal"  className={cx('Homebrew', { 'Homebrew--OverlayMode': overlayMode })} style={{ '--zoom': `${zoom}`, '--chat-font-family':`'${fontOptions[chatFont] || fontOptions[defaultFont]}'` }}>
        <canvas id="Homebrew_Canvas" className={cx('Homebrew__Canvas')} />
        {
          overviewUser && (
            <ChatOverview
              user={overviewUser}
              parent={overviewElement.current}
              hide={() => setOverviewUser(null)}
            />
          )
        }
        <ActionModal />
        {
          leavingPiczel && (
            <Modal title={<FormattedMessage id="Chat_LeavingPiczelTitle" defaultMessage="Leaving Piczel.tv" />} close={() => setLeavingPiczel(null)}>
              <div style={{ textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                <FormattedMessage
                  id="Chat_LeavingPiczelBody"
                  defaultMessage="You are now leaving Piczel.tv, the following link will take you to {link}"
                  values={{ link: leavingPiczel }}
                />
              </div>

              <div style={{ textAlign: 'center', marginTop: '1.5em' }}>
                <Button onClick={() => setLeavingPiczel(null)} color="red">
                  <FormattedMessage id="Chat_LeavingPiczelCancel" defaultMessage="Cancel" />
                </Button>

                <Button onClick={() => navigateTo(leavingPiczel)}>
                  <FormattedMessage id="Chat_LeavingPiczelContinue" defaultMessage="Continue" />
                </Button>
              </div>

              <div style={{ textAlign: 'center' }}>
                <Button
                  color="transparent"
                  onClick={() => {
                    localStorage.setItem('trustChatLinks', '1');
                    navigateTo(leavingPiczel);
                  }}
                >
                  <FormattedMessage id="Chat_LeavingPiczel_Dismiss" defaultMessage="Don't remind me again" />
                </Button>
              </div>
            </Modal>
          )
        }
        {
          !overlayMode && (
            <HomebrewNav
              rooms={rooms}
              activeRoom={activeRoom}
              currentRoom={currentRoom}
              notificationReminder={notificationReminder}
              toggleSidePane={toggleSidePane}
            />
          )
        }
        {
          currentRoom && (
            <>
              <ChatRoomSubject
                onClick={onClick}
                setModeratedChat={setModeratedChat}
                notificationReminder={!overlayMode && notificationReminder}
                currentUser={currentUser}
                currentRoom={currentRoom}
              />

              <div className={cx('Homebrew__MessagePaneWrapper', { 'Homebrew__MessagePaneWrapper--SidePane': sidePaneOpen !== null, 'Homebrew__MessagePaneWrapper--hasRaffle': currentRoom.raffle && !currentRoom.raffle.finished })}>
                <MessagePane
                  onClick={onClick}
                  setOverview={setOverview}
                  currentRoom={currentRoom}
                  currentUser={currentUser}
                  sidePane={sidePaneOpen}
                  lockToBottom={overlayMode}
                />

                <Options
                  currentRoom={currentRoom}
                  currentUser={currentUser}
                  isOpen={sidePaneOpen === OPTIONS}
                />

                {
                  currentRoom.raffle && !currentRoom.raffle.finished && (
                    <RafflePrompt
                      currentUser={currentUser}
                      room={currentRoom}
                      raffle={currentRoom.raffle}
                      className={cx('Homebrew__RafflePrompt')}
                    />
                  )
                }

                {
                  sidePaneOpen === USER_LIST && (
                    <ChatRoster
                      setOverview={setOverview}
                      isOpen={sidePaneOpen === USER_LIST}
                      users={currentRoom.users}
                      members={currentRoom.members}
                    />
                  )
                }

              </div>
              
              {
                !overlayMode && (
                  <HomebrewFooter
                    currentRoom={currentRoom}
                    currentUser={currentUser}
                    modalOpen={modalOpen}
                    connectionState={connectionState}
                    isMobile={isMobile}
                    unreadPM={unreadPM}
                    width={width}
                    defaultWidth={CHAT_DEFAULT_WIDTH}
                  />
                )
              }
            </>
          )
        }
      </div>
  );
};

Homebrew.defaultProps = {
  streams: [],
  overlayMode: false,
};

Homebrew.propTypes = {
  streams: arrayOf(shape({
    username: string,
  })),
  width: number.isRequired,
  setWidth: func.isRequired,
  overlayMode: bool,
};

export default Homebrew;
