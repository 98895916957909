import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import className from 'classnames/bind';

import { events, ChatContext } from './ChatContext';
import { getActiveRoom } from '~/modules/chat';
import Markdown from '~/components/Markdown';
import styles from './Topic.scss';
import hb from './Homebrew.scss';

const cx = className.bind(styles);

const Topic = ({ currentRoom, currentUser }) => {
  const activeRoom = useSelector(getActiveRoom);
  const [editTopicAvailable, setEditTopicAvailable] = useState(false);
  const [showMessageTopic, setShowMessageTopic] = useState(true);
  const [showReminder, setShowReminder] = useState(true);
  const [editTopic, setEditTopic] = useState(false);
  const inputTopic = useRef(null);
  const { client: socket } = useContext(ChatContext);

  const saveTopic = () => {
    if (!socket) return;
    const { value } = inputTopic.current;
    setShowMessageTopic(false);
    handleBlur();
    socket.emit(events.ADD_MESSAGE, {
      roomId: activeRoom,
      message: {
        text: `/topic ${value || ''}`,
      },
    });
  };

  const selectInputText = () => {
    setTimeout(() => {
      const { current } = inputTopic;
      current.select();
      current.scrollTop = current.scrollHeight;
    }, 100);
  };

  const handleSave = (e) => {
    if (e.type === 'click' || e.key === 'Enter') {
      saveTopic();
    }
  };

  const handleBlur = () => {
    setTimeout(() => setEditTopic(false), 300);
  };

  const handleOnClick = () => {
    if (editTopicAvailable) {
      setEditTopic(true);
      selectInputText();
    }
  };

  useEffect(() => {
    setEditTopicAvailable(!!currentUser?.permissions?.subject);
  }, [currentUser]);

  useEffect(() => {
    setShowReminder((!editTopic && showMessageTopic && editTopicAvailable && !currentRoom?.subject?.length));
  }, [editTopic, showMessageTopic, editTopicAvailable, currentRoom]);

  return (
    <>
      {
      !editTopic && (
        <div
          className={cx('Topic', { Topic_Box_Shadow: showReminder })}
          onClick={handleOnClick}
        >
          <Markdown>
            { currentRoom.subject }
          </Markdown>
        </div>
      )
      }
      {
        showReminder && (
        <button className={`${styles.Topic_Tooltip} ${hb.Homebrew__OverlayHidden}`}>
          <FormattedMessage id="Chat_Topic_Empty" defaultMessage="Click here to edit your topic" />
          <b onClick={() => setShowMessageTopic(false)} role="img" className="ion-android-cancel" />
        </button>
        )
      }
      {
        editTopic && (
          <div className={`${styles.EditTopic}`}>
            <textarea
              autoFocus
              placeholder="Topic"
              spellCheck="false"
              maxLength={255}
              ref={inputTopic}
              onKeyDown={handleSave}
              defaultValue={currentRoom.subject}
              onBlur={handleBlur}
            />
            <div>
              <button type="button" onClick={handleSave}>
                <FormattedMessage id="Chat_Topic_Save" defaultMessage="Save" />
              </button>
            </div>
          </div>
        )
      }
    </>
  );
};


export default Topic;
