import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import styles from './ListItem.scss';

const cx = classNames.bind(styles);

const ListItem = ({ className, title, description, icon, ...props }) =>  (
  <button type="button" className={cx('ListItem', className)} {...props}>
    <div className={cx('ListItem__Icon')}>
      {icon}
    </div>
    <div className={cx('ListItem__Title')}>
      {title}
    </div>
    <div className={cx('ListItem__Description')}>
      {description}
    </div>
  </button>
);

ListItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

ListItem.defaultProps = {
  className: '',
};

export default ListItem;
