import React, { useRef, useEffect } from 'react';
import Tooltip from '~/components/Tooltip';

const MessagesTooltips = (charactersRemaining) => {
  if (charactersRemaining > 0) {
    return {
      id: 'CharacterCountIndicator_InfoTooltip',
      defaultMessage: `${charactersRemaining} characters remaining`,
    };
  } if (charactersRemaining === 0) {
    return {
      id: 'CharacterCountIndicator_WarningTooltip',
      defaultMessage: 'Message is at max length',
    };
  }
  return {
    id: 'CharacterCountIndicator_DangerTooltip',
    defaultMessage: 'Message is too long',
  };
};

const CharacterCountIndicator = ({ percent, charactersRemaining, ...props }) => {
  const ref = useRef(null);

  useEffect(() => {
    const canvas = ref.current;

    if (!canvas) return;

    /**
     * @type {CanvasRenderingContext2D}
     */
    const ctx = canvas.getContext('2d');

    ctx.strokeStyle = '#3db9ea';

    if (percent > 1) {
      ctx.strokeStyle = '#d9534e';
    }

    ctx.lineWidth = 3;


    ctx.clearRect(0, 0, 25, 25);

    ctx.beginPath();
    const start = 1.5 * Math.PI;

    const stopPercent =  Math.min(1, Math.max(0, percent));

    ctx.arc(12.5, 12.5, 9, start, start +  stopPercent * (2 * Math.PI), false);
    ctx.stroke();
  }, [percent]);

  const tooltip = MessagesTooltips(charactersRemaining);

  return (
    <Tooltip
      text={tooltip}
      align="left"
      withIcon={false}
    >
      <div {...props}>
        <canvas width={25} height={25} ref={ref} />
        <div>
          {charactersRemaining}
        </div>
      </div>
    </Tooltip>
  );
};

export default CharacterCountIndicator;
