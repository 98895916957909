import React from 'react';
import Comment from './Comment';

import styles from './List.scss';

class CommentList extends React.Component {
  render() {
    const {
      isSignedIn,
      currentUser,
      comments,
      deleteComment,
      sendComment,
      loadReplies,
      owner,
      level = 0,
    } = this.props;

    return (
      <div className={styles.CommentList}>
        {comments.map(comment => (
          <Comment
            {...{
              isSignedIn, currentUser, sendComment, deleteComment, loadReplies,
            }}
            owner={owner}
            level={level}
            comment={comment}
            key={comment.id}
          />
        ))}
      </div>
    );
  }
}

export default CommentList;
