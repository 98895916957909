// @ts-check
import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';

import { updateMultiRequest } from '~/modules/currentUser';

import Button from '~/components/Button';
import styles from './index.scss';

const cx = classNames.bind(styles);

/**
 * @type {React.FC<{type: 'sent'|'received', multistream: UserState["multistream"]}>}
 */
export const MultistreamRequestList = ({ type, multistream }) => {
  const dispatch = useDispatch();
  const updateRequest = useCallback((username, id, accept = true) => dispatch(updateMultiRequest(username, id, accept)), []);

  return (
    <div>
      <h4>
        <FormattedMessage
          id={`StreamMultistream_Requests_${type.replace(/^\w/, $1 => $1.toUpperCase())}`}
          defaultMessage={`Requests ${type}`}
        />
      </h4>

      {
        multistream[type].length > 0
          ? multistream[type].map(req => (
            <MultiRequest {...req} updateRequest={updateRequest} sent={type === 'sent'} />
          ))
          : 'Nothing here.'
      }
    </div>
  );
};

MultistreamRequestList.propTypes = {
  type: propTypes.oneOf(['sent', 'received']).isRequired,
  multistream: propTypes.exact({
    sent: propTypes.arrayOf(propTypes.shape({
      id: propTypes.number,
      accepted: propTypes.bool,
      target_stream: propTypes.shape({
        slug: propTypes.string,
      }),
      host_stream: propTypes.shape({
        slug: propTypes.string,
      }),
    })).isRequired,
    received: propTypes.arrayOf(propTypes.shape({
      id: propTypes.number,
      accepted: propTypes.bool,
      target_stream: propTypes.shape({
        slug: propTypes.string,
      }),
      host_stream: propTypes.shape({
        slug: propTypes.string,
      }),
    })),
  }).isRequired,
};

export default class MultiRequest extends React.Component {
  static propTypes = {
    id: propTypes.number.isRequired,
    target_stream: propTypes.shape({
      slug: propTypes.string,
      id: propTypes.number,
    }).isRequired,
    host_stream: propTypes.shape({
      slug: propTypes.string,
      id: propTypes.number,
    }).isRequired,
    accepted: propTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.accept = this.accept.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  accept(e) {
    const { id, target_stream, updateRequest } = this.props;
    e.preventDefault();

    updateRequest(target_stream.slug, id, true);
  }

  cancel(e) {
    const { id, target_stream, updateRequest } = this.props;
    e.preventDefault();

    updateRequest(target_stream.slug, id, false);
  }

  render() {
    const {
      sent, accepted, host_stream, target_stream,
    } = this.props;

    let status = <FormattedMessage id="StreamMultistream_Pending" defaultMessage="Pending" />;
    if (accepted) status = <FormattedMessage id="StreamMultistream_Accepted" defaultMessage="Accepted" />;

    return (
      <div className={cx('MultiRequest')}>
        <div className={cx('MultiRequest__Info')}>
          {sent ? target_stream.slug : host_stream.slug}

          <span className={cx('MultiRequest__Status', {
            'MultiRequest__Status--accepted': accepted,
          })}
          >
            {status}
          </span>
        </div>
        <div className={cx('MultiRequest__Actions')}>
          {(!sent && !accepted) && (
            <Button onClick={this.accept}>
              <FormattedMessage
                id="StreamMultistream_Accept"
                defaultMessage="Accept"
              />
            </Button>
          )}

          <Button
            color="red"
            onClick={this.cancel}
          >
            {sent ? (
              <FormattedMessage
                id="StreamMultistream_Cancel"
                defaultMessage="Cancel"
              />
            ) : accepted ? (
              <FormattedMessage
                id="StreamMultistream_Abandon"
                defaultMessage="Abandon"
              />
            ) : (
              <FormattedMessage
                id="StreamMultistream_Decline"
                defaultMessage="Decline"
              />
            )}
          </Button>
        </div>
      </div>
    );

    return (
      <div className={styles.MultiRequest}>
        <label>{sent ? target_stream.slug : host_stream.slug}</label>
        {accepted ? (
          <FormattedMessage
            id="StreamMultistream_Accepted"
            defaultMessage="Accepted"
          />
        ) : (
          <FormattedMessage
            id="StreamMultistream_Pending"
            defaultMessage="Pending"
          />
        )}



        <button
          type="button"
          onClick={this.cancel}
        >
          {sent ? (
            <FormattedMessage
              id="StreamMultistream_Cancel"
              defaultMessage="Cancel"
            />
          ) : accepted ? (
            <FormattedMessage
              id="StreamMultistream_Abandon"
              defaultMessage="Abandon"
            />
          ) : (
            <FormattedMessage
              id="StreamMultistream_Decline"
              defaultMessage="Decline"
            />
          )}
        </button>
      </div>
    );
  }
}
