import React, { useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { Carousel } from 'react-responsive-carousel';

import ContestResult from '~/components/ContestResult';

import Winner from './pixRefSheet-thumb.jpg';
import WinnerFull from './pixRefSheet.jpg';

import styles from './index.scss';
import ImagePreview from '~/components/Gallery/ImagePreview';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ContestResults = () => {
  const [results, setResults] = useState([]);
  const [useCarousel, setUseCarousel] = useState(false);

  useEffect(() => {
    const adjustCarousel = () => {
      if (window.innerWidth <= 950) {
        setUseCarousel(true);
      } else {
        setUseCarousel(false);
      }
    };

    adjustCarousel();

    window.addEventListener('resize', adjustCarousel);

    return () => {
      window.removeEventListener('resize', adjustCarousel);
    };
  }, []);

  useEffect(() => {
    import('./results.json').then(data => setResults(data.default));
  }, []);

  const Wrapper = useCarousel ? Carousel : 'div';

  const top4 = useMemo(() => Array.from(results.slice(0, 4)), [results]);

  return (
    <div className={styles.ContestResults}>
      <Helmet>
        <title>Mascot contest results</title>
      </Helmet>

      <h1 className={styles.ContestResults__Title}>
        Meet our new mascot, Pix!
      </h1>

      <a href={WinnerFull} target="_blank" rel="noopener noreferrer">
        <img className={styles.ContestResults__Image} src={Winner} alt="Pix, the new Piczel mascot" />
      </a>

      <div>
        <p>
          With over 100 submissions and thousands of individual voters, our mascot contest was a huge success. Thank you to everyone who entered, and to all the voters! With so many great entries, we were confident no matter who the voters chose we&apos;d end up with a fantastic mascot.
        </p>

        <p>
          After we counted up all the votes, we ended up with a top 4, instead of a top 3! There was a tie for third, and so we will be buying an extra Kamvas 13 to fill out the prize pool.
        </p>

        <p>
          Once we had our top 4 voted designs, we thought long and hard about which design to pick, but it&apos;s tough to argue with such a clear vote lead! Pix has a great, vibrant design which stands out against our relatively muted color scheme on Piczel.tv, with a clearly identifiable silhouette and design that lets her be recognizable in a variety of outfits.
        </p>

        <p>
          We&apos;ve worked with Quinto to adjust Pix&apos;s design a bit to make her easier to draw (by removing the full logo from her back, and simplifying her socks) but for anyone that preferred her old socks, you&apos;re welcome to draw either.
        </p>

        <p>
          We&apos;ll be getting in touch with the winners ASAP via email or twitter soon, and going through the prize selection process. We&apos;re hoping to get them all shipped out within a week or so. 
        </p>
      </div>

      
      <h1 className={styles.ContestResults__h1}>
        Top voted entries
      </h1>


      <Wrapper className={styles.ContestResults__TopVoted}>
        {
          top4.map((result, index) => (
            <ContestResult key={result.id} percent={result.votes / top4[0].votes} position={index + 1} key={result.id} votes={result.votes} galleryImage={result.gallery_image} />
          ))
        }
      </Wrapper>

      <hr />

      <h1 className={styles.ContestResults__h1} style={{ fontWeight: 300 }}>
        Results
      </h1>

      <h2 style={{ textAlign: 'center' }}>
        {
          results.map(result => result.votes).reduce((a, b) => a + b, 0)
        }
        &nbsp;
        votes in total
      </h2>

      <div className={styles.ContestResults__Entries}>
        {
          results.map((result, index) => {
            return (
              <div className={styles.ContestResults__Entry}>
                <div className={styles.ContestResults__EntryUsername}>
                  {index + 1}. {result.gallery_image.user.username}
                </div>
                <div className={styles.ContestResults__EntryVotes}>
                  { result.votes }
                  {' '}
                  votes
                </div>

                <div className={styles.ContestResults__EntryImage}>
                  <ImagePreview noUsernames {...result.gallery_image} />
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default ContestResults;
