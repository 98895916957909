import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './CooldownRange.scss';

const rangeLabels = [
  { value: 0, label: 'OFF' },
  { value: 5, label: '5s' },
  { value: 10, label: '10s' },
  { value: 30, label: '30s' },
  { value: 60, label: '1m' },
  { value: 120, label: '2m' },
  { value: 300, label: '5m' },
  { value: 600, label: '10m' },
  { value: 1200, label: '20m' },
  { value: 1800, label: '30m' },
];

const CooldownRange = ({ value, onChange }) => {
  const [currentValue, setCurrentValue] = useState(0);

  const timeout = useRef(null);

  const handleChange = useCallback((e) => {
    e.persist();
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      onChange(e);
      timeout.current = null;
    }, 500);

    setCurrentValue(e.target.value);
  }, [onChange]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={styles.CooldownRange}>
      <label htmlFor="CooldownRange">
        <div className={styles.CooldownRange__Title}>
          <FormattedMessage
            id="CooldownRange_Label"
            defaultMessage="Slow mode: {value}"
            values={{
              value: rangeLabels.reduce((current, next) => {
                if (currentValue < next.value || currentValue === 0) {
                  return current;
                }

                if (currentValue >= next.value) {
                  return next;
                }
              }, rangeLabels[0]).label,
            }}
          />
        </div>

        <select className={styles.CooldownRange__Input} value={currentValue} onChange={handleChange} id="CooldownRange">
          {
            rangeLabels.map(label => (
              <option key={label.value} label={label.label} value={label.value}>{label.label}</option>
            ))
          }
        </select>

        <span className={styles.CooldownRange__Description}>
          <FormattedMessage id="CooldownRange_Description" defaultMessage="Users will be restricted to sending one message per this interval" />
        </span>
      </label>
    </div>
  );
};

export default CooldownRange;
