import { preferences } from '~/services/storage';
import getSources from '../components/StreamPlayer/playerSources';
import { LL_HLS, OVENPLAYER } from '../components/StreamPlayer/playerTechs';

/**
 * Extract the error or errors from an API response
 */
export function getErrorArray(response) {
  let errors = [];
  if (response.error) {
    errors.push(response.error);
  }

  if (response.errors) {
    if (Array.isArray(response.errors)) {
      errors = errors.concat(response.errors);
    } else {
      for (const prop in response.errors) {
        if (response.errors.hasOwnProperty(prop)) {
          errors.push(`${prop} ${response.errors[prop]}`);
        }
      }
    }
  }
  return errors;
}

/**
 * Extract query string parameters from the current URL
 *
 * @returns {Object} params
 */
export function getUrlParams() {
  const vars = [];
  let hash;
  const hashes = window.location.href
    .slice(window.location.href.indexOf('?') + 1)
    .split('&');
  for (let i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=');
    vars.push(hash[0]);
    vars[hash[0]] = hash[1];
  }

  return vars;
}


/**
 * Map Avatar
 */

const avatars = {};

/**
 *
 * @param {String} urlAvatar - the id of the stream
 */

export async function loadAvatar(urlAvatar) {
  try {
    if (avatars[urlAvatar]) {
      return avatars[urlAvatar];
    }
    const response = await fetch(urlAvatar);
    
    const blob = await response.blob();
    
    const url = URL.createObjectURL(blob);
    avatars[urlAvatar] = url;

    return url;
  } catch (error) {
    return error;
  }
}

/**
 * @returns {Boolean}
 */
export function userHasConfirmedAge(currentUser) {
  if (currentUser) {
    return currentUser.settings.nsfw_filter.user_is_over18;
  }

  return JSON.parse(preferences.getItem('userConfirmedAge')) == true;
}

/**
 * Returns a promise that resolves when the playlist is available
 * @param {Stream} stream 
 */
export const waitHLSPlaylistAvailable = (stream) => {
  const hlsSource = getSources(OVENPLAYER, LL_HLS, stream).find(src => src.name === LL_HLS);

  if (!hlsSource) throw new Error(`.m3u8 url is undefined for stream: ${stream.id}!`);

  return new Promise((resolve) => {
    const isHLSAvailable = () => fetch(typeof hlsSource.src === 'function' ? hlsSource.src(stream) : hlsSource.src).then(response => response.status === 200)
      .catch((reason) => {
        console.error(reason);
        return false;
      });

    const interval = setInterval(async () => {
      const isAvailable = await isHLSAvailable();

      if (isAvailable) {
        resolve(stream);
        clearInterval(interval);
      }
    }, 1000);
  });
};

export default waitHLSPlaylistAvailable;
