/* node_modules */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* local imports */
import ContentBlur from '~/components/ContentBlur';
import Content from '../Content';

/* style imports */
import styles from './Collection.scss';

const cx = classNames.bind(styles);

/**
 * Displays multiple images and shows controls
 */
class Collection extends React.Component {
  static propTypes = {
    /** An image object */
    image: PropTypes.shape({
      /** The images to display */
      images: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string,
          thumb: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      })),
    }).isRequired,
    blur: PropTypes.bool.isRequired,
    isPresentational: PropTypes.bool,
  }

  static defaultProps = {
    isPresentational: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      curImg: 0,
    };

    this.go = this.go.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress(e) {
    const { curImg } = this.state;

    switch (e.keyCode) {
      case 37: // Left Arrow
        return this.go(e, curImg - 1);
      case 39: // Right Arrow
        return this.go(e, curImg + 1);
      default: return null;
    }
  }

  /**
   * Selects an image by param
   *
   * @param {string} e
   * @param {int} n
   * @public
   */
  go(e, n) {
    e.stopPropagation();
    e.preventDefault();

    const { image: { images } } = this.props;

    if (n < 0 || n > images.length - 1) return;

    this.setState({ curImg: n });
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
  }

  render() {
    const { image: { images }, image, isPresentational } = this.props;
    const { curImg } = this.state;

    return (
      <ContentBlur content={image}>
        <div className={styles.GalleryCollection}>
          {!isPresentational && (
            <span>
              {!curImg === 0 && (
                <i
                  className={cx('GalleryCollection__Controls', 'GalleryCollection__Controls--prev', 'ion-chevron-left')}
                  onClick={e => this.go(e, curImg - 1)}
                />
              )}
              <p>{`${curImg + 1} / ${images.length}`}</p>
              {!curImg === images.length - 1 && (
                <i
                  className={cx('GalleryCollection__Controls', 'GalleryCollection__Controls--next', 'ion-chevron-right')}
                  onClick={e => this.go(e, curImg + 1)}
                />
              )}
            </span>
          )}
          <div className={styles.GalleryCollection__ThumbList}>
            {images.map((img, i) => (
              <img
                onClick={e => this.go(e, i)}
                className={cx({
                  GalleryCollection__Thumb: true,
                  'GalleryCollection__Thumb--active': curImg === i,
                })}
                src={`${img.image[img.is_video || img.is_flash ? 'vthumb' : 'thumb'].url}`}
                key={`thumb:${i}`}
                loading="lazy"
              />
            ))}
          </div>
          <Content {...images[curImg]} />
        </div>
      </ContentBlur>
    );
  }
}

export default Collection;
