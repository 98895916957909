import React, { useReducer, useCallback, useEffect, useContext, useMemo } from 'react';
import className from 'classnames/bind';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import styles from './ChatTabs.scss';
import { ChatContext, events } from './ChatContext';
import { getOpeningPM, setOpeningPM, incrementUnread, getPendingRooms } from '~/modules/chat';
import PendingRoomButton from './PendingRoomButton';
import { getPlayingUsernames } from '~/modules/streams';

const cx = className.bind(styles);

const ChatTabs = ({ rooms, activeRoom, setActiveRoom, closeRoom }) => {
  const { client } = useContext(ChatContext);
  const pendingRooms = useSelector(getPendingRooms);
  const openingPM = useSelector(getOpeningPM);
  const streamUsernames = useSelector(getPlayingUsernames);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (!client) return;
    const newMessageHandler = ({ data: { roomId, message }}) => {
      if (!message.serverMessage && roomId !== activeRoom) {
        dispatch(incrementUnread(roomId));
      }
    };

    client.on(events.ADD_MESSAGE, newMessageHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      client.off(events.ADD_MESSAGE, newMessageHandler);
    };
  }, [client, activeRoom, dispatch]);

  useEffect(() => {
    if (!client) return;

    const onJoinRoom = ({ data: { room } }) => {
      if (openingPM && room.isPrivate) {
        setActiveRoom(room.id);
        dispatch(setOpeningPM(null));
      }

      if (room.isPrivate && !openingPM && room.messages.length) {
        const alreadyJoined = rooms.findIndex(joinedRoom => joinedRoom.id === room.id) !== -1;

        if (!alreadyJoined) {
          dispatch(incrementUnread(room.id));
        }
      }
    };

    client.on(events.JOIN_ROOM, onJoinRoom);

    return () => {
      client.off(events.JOIN_ROOM, onJoinRoom);
    };
  }, [client, openingPM, dispatch, setActiveRoom, rooms]);

  const onCloseRoom = useCallback((roomId) => {
    const roomIndex = rooms.findIndex(room => room.id === roomId);
    let newFocus;

    if (activeRoom === roomId) {
      if ((roomIndex + 1) < rooms.length) {
        newFocus = roomIndex + 1;
      } else if ((roomIndex - 1) >= 0) {
        newFocus = roomIndex - 1;
      }

      if (newFocus !== undefined) {
        setActiveRoom(rooms[newFocus].id);
      }
    }

    closeRoom(roomId);
  }, [closeRoom, rooms, activeRoom, setActiveRoom]);


  const sortedRooms = useMemo(() => Array.from(rooms).sort((roomA, roomB) => {
    const indexA = streamUsernames.indexOf(roomA.name);
    const indexB = streamUsernames.indexOf(roomB.name);

    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    if (indexA < indexB) return -1;
    if (indexB < indexA) return 1;

    return 0;
  }), [rooms, streamUsernames]);

  return (
    <nav className={cx('ChatTabs', { 'ChatTabs--CloseEnabled': rooms.length > 1 })}>
      {
        sortedRooms.map(room => (
          <div
            key={room.id}
            className={cx('ChatTabs__Item', {
              'ChatTabs__Item--PM': room.isPrivate,
              'ChatTabs__Item--HasUnread': room.unread,
              'ChatTabs__Item--Active': room.id === activeRoom,
              'ChatTabs__Item--Pinned': room.pinned,
            })}
          >
            <button
              className={styles.ChatTabs__ItemLabel}
              onClick={() => {
                setActiveRoom(room.id);
              }}
              type="button"
              key={room.id}
            >
              {room.name}
            </button>


            <button title={intl.formatMessage({ id: 'Chat_LeaveRoom', defaultMessage: 'Leave room' })} onClick={() => onCloseRoom(room.id)} type="button" className={styles.ChatTabs__ItemClose}>
              <span role="img" className="ion-close" />
            </button>
          </div>
        ))
      }
      {
        pendingRooms.map(roomName => (
          <div key={roomName} className={cx('ChatTabs__Item')}>
            <PendingRoomButton roomName={roomName} />
          </div>
        ))
      }
    </nav>
  );
};

ChatTabs.propTypes = {
  rooms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  activeRoom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setActiveRoom: PropTypes.func.isRequired,
  closeRoom: PropTypes.func.isRequired,
};

export default React.memo(ChatTabs);
