// @ts-check
import React, { useState, useCallback } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  PROMPTING, getStreamsByUsernames, SET_PASSWORDS, startPlayingStreams,
} from '~/modules/streams';
import Config from '~/config';

import styles from './index.scss';
import { isLoading } from '~/modules/loading';

export default function StreamPrompt({ playing }) {
  const dispatch = useDispatch();
  const loading = useSelector(state => isLoading(state.loading, PROMPTING));
  const streams = useSelector(state => getStreamsByUsernames(state, PROMPTING));
  const [passwords, setPasswords] = useState(Object.fromEntries(streams.map(stream => [stream.username, ''])));
  const setPassword = useCallback((username, password) => setPasswords(state => ({ ...state, [username]: password })), []);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({
      type: SET_PASSWORDS,
      payload: passwords,
    });

    dispatch(startPlayingStreams(playing));
  }, [passwords]);

  return (
    <form onSubmit={onSubmit} className={styles.StreamPrompt}>
      <h1><FormattedMessage id="StreamPrompt__Title" defaultMessage="Private stream" /></h1>
      <p><FormattedMessage id="StreamPrompt__Subtext" defaultMessage="One or more streams requested are private, you need a password to watch them" /></p>
      {
        streams.map(stream => (
          <div key={stream.username}>
          <img className={styles.StreamAvatar} alt={`${stream.username}'s avatar`} src={`${Config.avatarsHost}/avatars/${stream.user.id}`} />
            <b>{stream.username}</b>
            {
              stream.settings && stream.settings.private && (
              <div>
                &quot;
                {
                  stream.settings.private.description
                }
                &quot;
              </div>
              )
            }
            <input className={styles.StreamInput} type="text" value={passwords[stream.username]} onChange={e => setPassword(stream.username, e.target.value)} autoFocus />
          </div>
        ))
      }
      <button disabled={loading} type="submit">{loading ? 'Loading...' : 'Submit'}</button>
    </form>
  );
}

StreamPrompt.propTypes = {
  playing: propTypes.string.isRequired,
};
