// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span[aria-expanded="true"] {
    transition: color 700ms, background-color 700ms;
}
   
span[aria-expanded="false"] {
    line-height: 1.7em;
    color: #222;
    background-color: #222;
    border-radius: 4px;
    padding: 0;
    cursor: pointer;
    user-select: none;
}
   `, "",{"version":3,"sources":["webpack://./src/app/components/Markdown/index.css"],"names":[],"mappings":"AAAA;IACI,+CAA+C;AACnD;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,iBAAiB;AACrB","sourcesContent":["span[aria-expanded=\"true\"] {\n    transition: color 700ms, background-color 700ms;\n}\n   \nspan[aria-expanded=\"false\"] {\n    line-height: 1.7em;\n    color: #222;\n    background-color: #222;\n    border-radius: 4px;\n    padding: 0;\n    cursor: pointer;\n    user-select: none;\n}\n   "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
