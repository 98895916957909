import { VIDEOJS, OVENPLAYER, HLS, HLS_NO_DVR, LL_HLS, FLASH, AUDIO, WEBRTC, STREAMSHOTS, LL_HLS_EXPERIMENTAL, WEBRTC_TCP } from './playerTechs';

const httpProtocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';
const HLS_HOST = `${httpProtocol}://${process.env.HLS_HOST}`; 
export const WEBRTC_HOST = process.env.WEBRTC_HOST; 
export const WEBRTC_PORT = process.env.WEBRTC_PORT;

export const baseSources = [
  {
    name: 'RECORDING_MP4',
    type: 'video/mp4',
    player: [OVENPLAYER],
    active: (stream, recordingId) => recordingId,
    src: (stream, recordingId) => `${stream.recordings.find(recording => recording.id === recordingId).url}`,
  },
  {
    label: 'HLS',
    name: LL_HLS,
    type: 'application/vnd.apple.mpegurl',
    player: OVENPLAYER,
    fallback: [WEBRTC],
    src: (stream, recordingId = null, key = null) => `${HLS_HOST}/live/${stream.id}/llhls.m3u8`,
  },
  {
    name: FLASH,
    type: 'rtmp/flv',
    player: VIDEOJS,
    src: (stream, recordingId = null, key = null) => `rtmp://piczel.tv:1935/live&${stream.id}${ key ? `?key=${key}` : ''}`,
  },
  {
    name: HLS_NO_DVR,
    type: 'application/vnd.apple.mpegurl',
    player: OVENPLAYER,
    src: (stream, recordingId = null, key = null) => `${HLS_HOST}/hls/${stream.id}/index.m3u8`,
  },
  {
    label: 'WebRTC',
    name: WEBRTC,
    type: 'webrtc',
    player: OVENPLAYER,
    fallback: [LL_HLS],
    src: stream => `wss://${WEBRTC_HOST}:${WEBRTC_PORT}/live/${stream.id}?transport=udp`,
  },
  {
    label: 'WebRTC (TCP)',
    name: WEBRTC_TCP,
    type: 'webrtc',
    player: OVENPLAYER,
    fallback: [LL_HLS],
    src: stream => `wss://${WEBRTC_HOST}:${WEBRTC_PORT}/live/${stream.id}?transport=tcp`,
  },
  {
    label: 'Audio Only',
    name: AUDIO,
    type: 'webrtc',
    player: OVENPLAYER,
    src: stream => `wss://${WEBRTC_HOST}:${WEBRTC_PORT}/live/${stream.id}_audio?transport=udp`,
  },
];

const getSourcesForPlayer = (player, tech) => {
  return baseSources.filter((source) => {
    return source.player.includes(player) && source.name === tech;
  });
};

const getSourceForStream = (source, player, stream, recordingId = null, key = null) => ({
  ...source,
  [player === OVENPLAYER ? 'file' : 'src']: source.src(stream, recordingId, key),
});

const getSources = (player, tech, stream, recordingId = null, key = null) => {
  let actualTech = tech === STREAMSHOTS ? AUDIO : tech;

  if (recordingId) {
    actualTech = 'RECORDING_MP4';
  }

  const playerSources = getSourcesForPlayer(player, actualTech);

  const streamSources = playerSources
    .filter((source) => {
      if (source.active || source.active === undefined) {
        return true;
      }
      
      if (!source.active) return false;

      return source.active(stream, recordingId);
    })
    .map(source => getSourceForStream(source, player, stream, recordingId, key));

  // sort streamsources to put current tech first
  const sortedSources = streamSources.sort((a, b) => {
    if (a.name === tech) {
      return -1;
    }

    if (b.name === tech) {
      return 1;
    }

    return 0;
  });

  if (playerSources.length === 0 && tech !== LL_HLS) {
    console.warn(`Unknown tech ${tech}, using LL_HLS fallback`);

    return getSources(player, LL_HLS, stream, recordingId, key);
  }

  const fullPlaylist = [
    ...sortedSources,
  ];

  return fullPlaylist;
};

export const getSourcesForOvenPlayer = (stream, recording = null) => {
  return baseSources.reduce((filtered, source) => {
    if ((source.player.includes(OVENPLAYER) && !recording && source.label) || (recording && source.name === 'RECORDING_MP4')) {
      const label = source.name === AUDIO ? STREAMSHOTS : source.label;

      const res = {
        ...source,
        label,
        file: source.src(stream, recording),
      };
      delete res.src;
      filtered.push(res);
    }
    return filtered;
  }, []);
};

export default getSources;
