export function isQueryParamSet(param, href) {
  let location = '';

  if (typeof window !== 'undefined') {
    location = href || window.location.href;
  }

  if (
    location.indexOf(`?${param}`) !== -1
    || location.indexOf(`&${param}`) !== -1
  ) {
    return true;
  }

  return false;
}

export function getParameterByName(name, url) {
  if (!url) {
    if (typeof window !== 'undefined') {
      url = window.location.href;
    } else {
      // Abort if we're running in the server and no url was specified
      return null;
    }
  }

  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
