import React from 'react';
import classNames from 'classnames/bind';
import styles from './index.scss';

const cx = classNames.bind(styles);

export function HomepageBanner ({ children, className, titleOnly, ...props }: { children: JSX.Element|JSX.Element[], className?: string, titleOnly?: boolean }) {
  return (
  <div className={cx('HomepageBanner', {'HomepageBanner--NoDescription': titleOnly} , className)} {...props}>
    {children}
  </div>
  );
}

export function HomepageBannerTitle ({ children, className, ...props }: { children: JSX.Element|JSX.Element[], className?: string }) {
  return (
  <div className={cx('HomepageBanner__Title', className)} {...props}>
    {children}
  </div>
  );
}

export function HomepageBannerDescription ({ children, className, ...props }: { children: JSX.Element|JSX.Element[], className?: string }) {
  return (
  <div className={cx('HomepageBanner__Description', className)} {...props}>
    {children}
  </div>
  );
}


export function HomepageBannerImage ({ children, className, ...props }: { children: JSX.Element|JSX.Element[], className?: string }) {
  return (
  <div className={cx('HomepageBanner__Image', className)} {...props}>
    {children}
  </div>
  );
}

export function HomepageBannerCTA ({ children, className, ...props }: { children: JSX.Element|JSX.Element[], className?: string }) {
  return (
  <div className={cx('HomepageBanner__CTA', className)} {...props}>
    {children}
  </div>
  );
}

