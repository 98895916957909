import React, { useCallback, useContext } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import Avatar from '~/components/Avatar';
import Button from '~/components/Button';
import Modal from './Modal';

import styles from './RaffleParticipants.scss';
import { ChatContext, events } from './ChatContext';
import { setShowingRaffleEntrants } from '~/modules/chat';

const cx = classNames.bind(styles);


const RaffleParticipants = ({ roomId, raffle, allowDeletion }) => {
  const dispatch = useDispatch();
  const { client } = useContext(ChatContext);
  const close = () => dispatch(setShowingRaffleEntrants(false));

  const kickParticipant = useCallback((username) => {
    client?.emit(events.KICK_RAFFLE, {
      roomId,
      username,
    });
  }, [client, roomId]);

  return createPortal((
    <Modal title={<FormattedMessage id="RaffleParticipants_Modal" defaultMessage="Participants" />} close={close}>
      <div className={cx('RaffleParticipants')}>
        {
          raffle.participants.map(username => (
            <div className={cx('RaffleParticipants__Participant')} key={username}>
              <Avatar username={username} />
              <div className={cx('RaffleParticipants__Participant--Username')}>
                {username}
              </div>
              {
                allowDeletion && (
                  <Button color="red" onClick={() => kickParticipant(username)}>
                    <FormattedMessage id="RaffleParticipants_Kick" defaultMessage="Remove" />
                  </Button>
                )
              }
            </div>
          ))
        }
      </div>
    </Modal>
  ), document.getElementById('Homebrew_ModalPortal'));
};

export default RaffleParticipants;
