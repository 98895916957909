import { createSelector } from 'reselect';
import { schema, denormalize, normalize } from 'normalizr';
import { batch } from 'react-redux';
import config from '~/config';
import { sortById } from '~/helpers/data';

import errorHandling from './_errorHandling';
import { setLoading } from './loading';
import { setFromId, resetFromId } from './pagination';
import { getUsersByName, updateUsers, userSchema } from './user';
import { ADD_ENTITIES } from './entity';

// Schema
export const galleryImageSchema = new schema.Entity('galleryImages', {
  user: userSchema,
});

export const folderSchema = new schema.Entity('folders');

interface GalleryState {
  requiresPassword: boolean;
  images: number[];
  folders: number[];
  sections: {
    [section: string]: number[];
  };
  queue: number[];
}

// State
const INITIAL_STATE: GalleryState = {
  requiresPassword: false,
  images: [],
  folders: [],
  sections: {},
  queue: [],
};

// Loading
export const FETCHING_IMAGES = 'piczel/gallery/FETCHING_IMAGES';
export const FETCHING_SECTION = 'piczel/gallery/FETCHING_SECTION';

export const UPLOADING_IMAGE = 'piczel/gallery/UPLOADING_IMAGE';
export const UPLOADING_OTHERFILES = 'piczel/gallery/UPLOADING_OTHERFILES';

// Actions
const SET_IMAGE = 'piczel/gallery/UPDATE_IMAGE';
const REMOVE_IMAGE = 'piczel/gallery/REMOVE_IMAGE';

const RESET_IMAGES = 'piczel/gallery/RESET_IMAGES';
const SET_IMAGES = 'piczel/gallery/UPDATE_IMAGES';

const RESET_FOLDERS = 'piczel/gallery/RESET_FOLDERS';
const SET_FOLDERS = 'piczel/gallery/UPDATE_FOLDERS';
const REMOVE_FOLDER = 'piczel/gallery/REMOVE_FOLDER';

const RESET_SECTION = 'piczel/gallery/RESET_SECTION';
const SET_SECTION = 'piczel/gallery/UPDATE_SECTIONS';

const RESET_QUEUE = 'piczel/gallery/RESET_QUEUE';
const SET_QUEUE = 'piczel/gallery/SET_QUEUE';

// Reducer
export default function galleryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REMOVE_IMAGE:
      return {
        ...state,
        images: state.images.filter(id => id !== action.id),
      };

    case RESET_IMAGES:
      return {
        ...state,
        images: [],
      };

    case SET_IMAGES:
      return {
        ...state,
        images: action.payload,
      };

    case RESET_SECTION:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.section]: [],
        },
      };

    case SET_SECTION:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.section]: action.payload,
        },
      };

    case RESET_FOLDERS:
      return { ...state, folders: [] };

    case SET_FOLDERS:
      return { ...state, folders: action.payload };

    case REMOVE_FOLDER:
      return {
        ...state,
        folders: state.folders.filter(folderId => folderId !== action.id),
      };

    case RESET_QUEUE:
      return { ...state, queue: [] };

    case SET_QUEUE:
      return { ...state, queue: action.payload };

    default: return state;
  }
}

// Action creators
export function setImage(id, payload) {
  return { type: SET_IMAGE, id, payload };
}

export function removeImage(id) {
  return { type: REMOVE_IMAGE, id };
}

export function resetImages() {
  return { type: RESET_IMAGES };
}

export function updateImages(images) {
  return { type: SET_IMAGES, payload: images };
}

export function resetFolders() {
  return { type: RESET_FOLDERS };
}

export function updateFolders(folders) {
  return { type: SET_FOLDERS, payload: folders };
}

export function removeFolder(id) {
  return { type: REMOVE_FOLDER, id };
}

export function resetSection(section) {
  return { type: RESET_SECTION, section };
}

export function updateSection(section, ids) {
  return { type: SET_SECTION, section, payload: ids };
}

export function resetQueue() {
  return { type: RESET_QUEUE };
}

export function setQueue(payload) {
  return { type: SET_QUEUE, payload };
}

/**
 * Upload a single, primary image
 */
export function uploadImage(data, opts = {}) {
  return (dispatch, getState, fetch) => {
    dispatch(setLoading(UPLOADING_IMAGE, true));

    return fetch(`${config.api}/gallery`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(response => errorHandling(dispatch, response))
      .then((response) => {
        dispatch(setLoading(UPLOADING_IMAGE, false));
        return response;
      });
  };
}

/**
 * Fetch a single image and all relevant info
 */
export function fetchImage(id, password = null) {
  return (dispatch, getState, fetch) => {
    dispatch(setLoading(FETCHING_IMAGES, true));

    return fetch(`${config.api}/gallery/${id}${password ? `?password=${password}` : ''}`)
      .then(res => errorHandling(dispatch, res))
      .then((image) => {
        if (!image) return false;

        const normalized = normalize(image, galleryImageSchema);

        dispatch({
          type: ADD_ENTITIES,
          payload: normalized.entities,
        });

        // const currentImages = getState().gallery.images;
        // dispatch(updateImages([...currentImages, normalized.result]));

        dispatch(setLoading(FETCHING_IMAGES, false));

        return image;
      });
  };
}

/**
 * Edit a single image
 */
export function updateImage(data, opts = {}) {
  return (dispatch, getState, fetch) => {
    dispatch(setLoading(UPLOADING_IMAGE, true));

    return fetch(`${config.api}/gallery/${data.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(response => errorHandling(dispatch, response))
      .then((response) => {
        dispatch(setLoading(UPLOADING_IMAGE, false));
        const normalized = normalize(response, galleryImageSchema);

        dispatch({
          type: ADD_ENTITIES,
          payload: normalized.entities,
        });

        return response;
      });
  };
}

/**
 * Delete a single image
 */
export function deleteImage(id, opts = {}) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/gallery/${id}`, {
      method: 'DELETE',
    }).then(() => dispatch(removeImage(id)));
  };
}

export function favoriteImage(id, value) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/gallery/favorites/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ value }),
    }).then((response) => {
      if (!response.ok) return null;

      const image = getImageById(getState(), id);
      const normalized = normalize({
        id,
        favorite: value,
        favorites_count: image.favorites_count + (value ? 1 : -1),
      }, galleryImageSchema);

      dispatch({
        type: ADD_ENTITIES,
        payload: normalized.entities,
      });

      return image;
    });
  };
}

interface FetchImagesOptions {
  username?: string;
  favorites?: boolean;
  sort?: string;
  section?: string;
  hideNsfw?: boolean;
  keepOld?: boolean;
  fromId?: number | null;
  password?: string;
  folderId?: number;
  requestId?: string;
  reset?: boolean;
}

/**
 * fetches gallery images from API
 */
export function fetchImages(options: FetchImagesOptions = {}) {
  return (dispatch, getState, fetch: typeof window.fetch) => {
    const requestId = options.requestId || 'GALLERY';

    let fetchUrl: string|URL = `${config.api}`;

    if (options.username) options.username = options.username.trim();

    if (options.favorites && options.username) {
      fetchUrl += `/gallery/favorites/${options.username}`;
    } else if (options.username) {
      fetchUrl += `/users/${options.username}/gallery`;
    } else {
      fetchUrl += '/gallery';
    }

    if (options.folderId) {
      fetchUrl = `${config.api}/gallery/folder/${options.folderId}`;
    }

    fetchUrl = new URL(fetchUrl);

    if (typeof options.hideNsfw !== 'undefined') fetchUrl.searchParams.set('hideNsfw', JSON.stringify(options.hideNsfw));


    /**
     * @type {string?}
     */
    const sort = options.sort || options.section;

    if (sort) {
      fetchUrl.searchParams.set('sort', sort);
      if (sort === 'curated') {
        fetchUrl.searchParams.set('limit', '8');
      }
    }

    dispatch(setLoading(requestId, true));

    if (!options.keepOld) {
      dispatch(resetFromId(requestId));
      options.fromId = null;

      if (!options.section) dispatch(resetImages());
      if (options.section || options.favorites) dispatch(resetSection(options.section || `FAVORITES_${options.username}`));
    }

    if (options.fromId) fetchUrl.searchParams.set('from_id', String(options.fromId));
    if (options.password) fetchUrl.searchParams.set('password', options.password);

    return fetch(fetchUrl)
      .then(res => errorHandling(dispatch, res))
      .then((json) => {
        if (json.status === 'error') return false;
        if (json.folder) {
          dispatch(setLoading(requestId, false));
          return false;
        }

        const {
          entities,
          result: allIds,
        } = normalize(json, [galleryImageSchema]);

        batch(() => {
          dispatch({
            type: ADD_ENTITIES,
            payload: entities,
          });

          const section = options.section || (options.favorites && `FAVORITES_${options.username}`) || null;
          const imageIds = section ? getState().gallery.sections[section] : getState().gallery.images;

          if (section) {
            dispatch(updateSection(section, [...new Set([...imageIds, ...allIds])]));
          } else {
            dispatch(updateImages([...new Set([...imageIds, ...allIds])]));
          }

          const lastId = allIds.length > 0 ? allIds[allIds.length - 1] : -1;

          dispatch(setFromId(requestId, lastId));

          dispatch(setLoading(requestId, false));
        });

        return json;
      });
  };
}

/**
 * fetches a user's folder from the server
 */
export function fetchFolders(username) {
  return (dispatch, getState, fetch) => {
    dispatch(resetFolders());

    return fetch(`${config.api}/users/${username}/gallery/folders`)
      .then(res => errorHandling(dispatch, res))
      .then((folders) => {
        if (!folders) return false;

        const normalized = normalize(folders, [folderSchema]);

        dispatch({
          type: ADD_ENTITIES,
          payload: normalized.entities,
        });

        dispatch(updateFolders(normalized.result));

        return folders;
      });
  };
}

export function updateFolder(id, data) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/gallery/folder/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => errorHandling(dispatch, res))
      .then((json) => {
        const normalized = normalize(json, folderSchema);

        dispatch({
          type: ADD_ENTITIES,
          payload: normalized.entities,
        });
      });
  };
}

export function deleteFolder(id) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/gallery/folder/${id}`, {
      method: 'DELETE',
    })
      .then((response) => {
        if (response.ok) {
          dispatch(removeFolder(id));
        }
      });
  };
}

/**
 * fetches a user's queued images
 */
export function fetchQueue(opts: FetchImagesOptions = {}) {
  return (dispatch, getState, fetch) => {
    if (opts.reset) dispatch(resetQueue());

    return fetch(`${config.api}/queue`)
      .then(response => errorHandling(dispatch, response))
      .then((response) => {
        const normalized = normalize(response, [galleryImageSchema]);

        dispatch({
          type: ADD_ENTITIES,
          payload: normalized.entities,
        });

        dispatch(setQueue(normalized.result));
      });
  };
}

/**
 * Fetches tags similar to the string provided
 * Does not update the store.
 */
export function searchTags(string) {
  return (dispatch, getState, fetch) => {
    const fetchUrl = new URL(`${config.api}/tags`);
    fetchUrl.searchParams.set('q', string);
    fetchUrl.searchParams.set('limit', '3');

    return fetch(fetchUrl.toString())
      .then(res => errorHandling(dispatch, res))
      .then(tags => tags.sort((a, b) => b.count - a.count))
      .then(tags => tags.map(tag => tag.title));
  };
}

// Selectors
export const getGalleryImages = createSelector(
  state => state.gallery.images,
  state => state.entities,
  (images, entities) => ({
    byId: entities.galleryImages, // denormalize(images, [galleryImageSchema], entities),
    allIds: images,
  }),
);

/**
 * Returns images sorted in a specific section
 */
export function getImagesBySection(state, section) {
  if (!section) return [];

  return denormalize(section, [galleryImageSchema], state.entities);
}

/**
 * Returns images sorted in a specific folder
 * @param {RootState['gallery']}
 */
export function getImagesByFolder(state, folderId) {
  const images = getGalleryImages(state);
  return images.allIds.reduce((obj, id) => {
    const image = images.byId[id];
    if (image.folder_id === parseInt(folderId, 10)) obj.push(image);
    return obj;
  }, []);
}

/**
 * Returns images by a specified user
 */
export function getImagesByUser(state, username) {
  if (!username) return [];

  const galleryImages = getGalleryImages(state);

  return galleryImages.allIds
    .filter(id => galleryImages.byId[id].user.toLowerCase() === username.toLowerCase())
    .map(id => galleryImages.byId[id]);
}

export function getImageById(state, id) {
  return denormalize(id, galleryImageSchema, state.entities);
}

export const getFolders = state => denormalize(state.gallery.folders, [folderSchema], state.entities);

export const getGalleryQueue = state => denormalize(state.gallery.queue, [galleryImageSchema], state.entities);
