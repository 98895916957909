import { createContext, useContext } from 'react';

const RouterContext = createContext(null);

const useRouter = () => {
  const router = useContext(RouterContext);
  return router;
};

export { RouterContext, useRouter };
