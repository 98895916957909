import config from '~/config';
import { preferences } from '~/services/storage';
import handleError from './_errorHandling';
import { setLoading } from './loading';
import { addFlash } from './flashes';

// State
interface TranslationMessage {
  id: string;
  defaultMessage: string;
}
interface TranslationsState {
  currentLocale: string;
  messages: {
    [locale: string]: TranslationMessage[],
  };
}

const INITIAL_STATE = {
  currentLocale: 'en',
  messages: {},
};

// Actions
const UPDATE_LOCALE = 'piczel/translations/SET_LOCALE';
const UPDATE_MESSAGES = 'piczel/translations/UPDATE_MESSAGES';

// Reducer
export default function translationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_LOCALE:
      return {
        ...state,
        currentLocale: action.payload,
      };

    case UPDATE_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.language]: action.payload,
        },
      };

    default: return state;
  }
}

// Action creators
export function updateLocale(payload) {
  return { type: UPDATE_LOCALE, payload };
}

export function updateMessages(payload) {
  return { type: UPDATE_MESSAGES, language: payload.data.locale, payload: payload.messages };
}

export function setLocale(language = 'en') {
  return (dispatch) => {
    dispatch(setLoading('app.translations', true));

    return dispatch(fetchMessages(language)).then((res) => {
      dispatch(updateLocale(res.language));
      dispatch(setLoading('app.translations', false));
      preferences.setItem('locale', res.language);
    });
  };
}

export function fetchMessages(language = 'en') {
  return async function (dispatch, getState, fetch: typeof window.fetch) {
    return fetch(`${config.api}/translations/${language}`)
      .then(response => response.json())
      .then(json => dispatch(updateMessages(json)));
  };
}

export function uploadMessages(language, values) {
  return function (dispatch, getState, fetch: typeof window.fetch) {
    return fetch(`${config.api}/translations/${language}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: values,
      }),
    }).then(response => handleError(dispatch, response))
      .then(() => {
        dispatch(addFlash('success', 'Translations were updated.', true));
      });
  };
}

export function deleteMessages(language) {
  return function (dispatch, getState, fetch: typeof window.fetch) {
    return fetch(`${config.api}/translations/${language}`, {
      method: 'DELETE',
    }).then(response => handleError(dispatch, response))
      .then(() => {
        dispatch(addFlash('success', 'Translations were deleted.', true));
      });
  };
}
