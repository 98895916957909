import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ADD_STREAM_LIST, fetchStreams, FETCHING_STREAMS, getAddStreamList,
} from '~/modules/streams';
import { isLoading } from '~/modules/loading';

import Loading from '~/components/Loading';
import { StreamPreview } from '../Stream/List/StreamPreview';
import Wrapper from './Wrapper';

import styles from './AddStreamList.scss';

export default function AddStreamList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStreams({
      live_only: !true,
    }, ADD_STREAM_LIST));
  }, [dispatch]);

  const loading = useSelector(state => isLoading(state.loading, FETCHING_STREAMS));
  const streams = useSelector(getAddStreamList);

  if (loading) {
    return <Loading message="Loading streams..." />;
  }

  return (
    <div className={styles.AddStreamList__Wrapper}>
      <div className={styles.AddStreamList}>
        {streams.map(stream => (
          <Wrapper key={stream.id} stream={stream}>
            <StreamPreview className={styles.AddStreamListPreview} stream={stream} />
          </Wrapper>
        ))
        }
      </div>
    </div>
  );
}
