import React from 'react';
import FormattedMessage from '~/components/common/FormattedMessage';

import styles from './index.scss';

class NotFound extends React.Component {
  static ComponentName = 'NotFound';

  constructor(props) {
    super(props);

    this.state = {
      timeout: null,
    };
  }

  componentDidMount() {
    const timeout = setTimeout(() => {
      location.href = '/';
    }, 5000);

    this.setState({ timeout });
  }

  componentWillUnmount() {
    const { timeout } = this.state;

    if (timeout) clearTimeout(timeout);
  }

  render() {
    const { isBanned } = this.props;

    return (
      <div className={styles.NotFound}>
        <img className={styles.NotFound__Image} src="/img/pix-404.png" />
        <div className={styles.NotFound__Message}>
          <h1 className={styles.NotFound__Headline}>
            {
              isBanned
                ? (
                  <FormattedMessage
                    id="NotFound__Headline__Suspend__Account"
                    defaultMessage="Piczel.tv suspends accounts which break our Terms of Service."
                  />
                )
                : (
                  <FormattedMessage
                    id="NotFound__Headline"
                    defaultMessage="Whoops!"
                  />
                )
            }
          </h1>
          <p className={styles.NotFound__SubText}>
            {
              isBanned
                ? (
                  <FormattedMessage
                    id="NotFound__SubText__Suspend__Account"
                    defaultMessage="This account is currently suspended"
                  />
                )
                : (
                  <FormattedMessage
                    id="NotFound__SubText"
                    defaultMessage="Page not found. Redirecting you to the homepage"
                  />
                )
            }
          </p>
        </div>
      </div>
    );
  }
}

export default NotFound;
