import React, { useCallback, useContext } from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { leaveRoom, setActiveRoom } from '~/modules/chat';
import ChatTabs from './ChatTabs';
import styles from './Homebrew.scss';
import { ChatContext, events } from './ChatContext';

const cx = classNames.bind(styles);

const HomebrewNav = ({ rooms, activeRoom, currentRoom, notificationReminder, toggleSidePane, }) => {
  const { client } = useContext(ChatContext);
  const dispatch = useDispatch();

  const popoutChat = useCallback(() => {
    window.open(`/chat/${currentRoom.name}`, 'PopoutChat', `height=${window.innerHeight},width=600,resizable,status`);
  }, [currentRoom]);

  const closeRoom = useCallback((id) => {
    dispatch(leaveRoom(id));

    if (client) {
      client.emit(events.LEAVE_ROOM, {
        roomId: id,
      });
    }
  }, [dispatch, client]);
  
  const setActive = useCallback((id) => {
    dispatch(setActiveRoom(id, true));
  }, [dispatch]);

  return (
    <div className={styles.Homebrew__Nav}>
      <ChatTabs
        rooms={rooms}
        setActiveRoom={setActive}
        closeRoom={closeRoom}
        activeRoom={activeRoom}
      />

      <div className={styles.Homebrew__Toolbar}>
        <button
          onClick={() => toggleSidePane('USER_LIST')}
          className={styles.Homebrew__ToolbarButton}
          type="button"
          title="Toggle user list"
        >
          <span className={styles.Homebrew__ToolbarUserCount}>
            { currentRoom ? currentRoom.users.length : null }
          </span>
          <span role="img" className="ion-android-contacts" />
        </button>

        <button
          onClick={() => toggleSidePane('OPTIONS')}
          className={cx('Homebrew__ToolbarButton', { 'Homebrew__ToolbarButton--Glowing': notificationReminder })}
          type="button"
          title="Options"
        >
          <span role="img" className="ion-android-settings" />
        </button>

        <button type="button" className={styles.Homebrew__ToolbarButton} onClick={() => popoutChat()} title="Popout chat">
          <span role="img" className="ion-android-open" />
        </button>
      </div>
    </div>
  );
};

export default HomebrewNav;
