import ReactGA from 'react-ga4';

const initialize = () => {
  ReactGA.initialize('G-4FW54V7T64');
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    title: document.title,
  });
};

export default initialize;
