import React from 'react';
import { Formik, Form } from 'formik';

import TextField from '~/components/Form/TextField';
import Button from '~/components/Button';

const PasswordPrompt = ({ children, handleSubmit }) => (
  <Formik
    initialValues={{
      password: '',
    }}
    onSubmit={handleSubmit}
  >
    {() => (
      <Form>
        {children}
        <TextField
          name="password"
          placeholder="Password"
        >
          Password
        </TextField>
        <Button>
          Submit
        </Button>
      </Form>
    )}
  </Formik>
);

export default PasswordPrompt;
