import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentRoom } from '~/modules/chat';
import {
  AutocompletePane, AutocompleteTitle, AutocompleteSuggestions, AutocompleteSuggestion, SuggestionIcon,
} from './AutocompleteUI';
import Config from '~/config';

const usernameRegex = /@(\w*)$/;

const roles = ['streamer', 'mod', 'everyone'];

const UsernameAutocomplete = ({ message, setMessage, focusInput }) => {
  const currentRoom = useSelector(getCurrentRoom);
  const [suggestions, setSuggestions] = useState([]);
  const [roleSuggestions, setRoleSuggestions] = useState([]);

  useEffect(() => {
    if (!currentRoom) return;

    const match = message.match(usernameRegex)[1];

    const matchingPortion = new RegExp(match, 'i');

    const userSuggestions = currentRoom.users.filter(user => matchingPortion.test(user.username));

    userSuggestions.sort((userA, userB) => {
      const usernameA = userA.username.toLowerCase();
      const usernameB = userB.username.toLowerCase();

      const indexA = usernameA.indexOf(match);
      const indexB = usernameB.indexOf(match);

      return (indexA - indexB);
    });

    setSuggestions(userSuggestions);

    setRoleSuggestions(roles.filter(role => matchingPortion.test(role)));
  }, [currentRoom.id, message]);
  
  const autocomplete = value => {
    setMessage(message.replace(usernameRegex, value));
    focusInput();
  };

  return (
    <AutocompletePane>
      <AutocompleteTitle>Users</AutocompleteTitle>
      <AutocompleteSuggestions blur={focusInput}>
        {
          (onFocus, focused) => (
            <>
              {
                suggestions.map(user => (
                  <AutocompleteSuggestion
                    key={user.username}
                    onFocus={onFocus}
                    focused={focused}
                    icon={<SuggestionIcon alt={user.username} src={`${Config.avatarsHost}/avatars/${user.piczel_user_id || 'default'}`} />}
                    label={`@${user.username}`}
                    onClick={() => autocomplete(`@${user.username} `)}
                  />
                ))
              }
              {
                roleSuggestions.map(role => (
                  <AutocompleteSuggestion
                    key={role}
                    onFocus={onFocus}
                    focused={focused}
                    label={`@${role}`}
                    onClick={() => autocomplete(`@${role} `)}
                  />
                ))
              }
            </>
          )
        }
      </AutocompleteSuggestions>
    </AutocompletePane>
  );
};

UsernameAutocomplete.regex = usernameRegex;

export default UsernameAutocomplete;
