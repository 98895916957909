import React from 'react';
import ImagePreview from '../Gallery/ImagePreview';

import styles from './index.scss';

const ContestResult = ({ position, votes, galleryImage, percent }) => (
  <div className={styles.ContestResult}>
    <div className={styles.ContestResult__Outline} style={{ height: `${percent * 100}%` }} />
    <div className={styles.ContestResult__Position}>
      {
        position
      }
    </div>
    <div className={styles.ContestResult__Username}>

      &nbsp;

      { galleryImage.user.username }

      <span className={styles.ContestResult__Votes}>
        { votes }
        &nbsp;
        votes
      </span>
    </div>

    <div className={styles.ContestResult__Image}>
      <ImagePreview noUsernames {...galleryImage} />
    </div>
  </div>
);

export default ContestResult;
