/* node_modules */
import React from 'react';
import {
  NavLink,
} from 'react-router-dom';
import { connect } from 'react-redux';

/* local imports */
import {
  fetchUser,
  getUserByName,
  FETCHING_USER,
} from '~/modules/user';

import {
  fetchImages,
  fetchFolders,
  deleteFolder,
  updateFolder,
  getImagesByUser,
  getImagesByFolder,
  getImagesBySection,
  FETCHING_IMAGES,
  getFolders,
} from '~/modules/gallery';

import { addFlash } from '~/modules/flashes';
import { getVisibilitySettings } from '~/modules/contentVisibility';
import { isLoading } from '~/modules/loading';

import FormattedMessage from '~/components/common/FormattedMessage';
import ImageSet from '~/components/Gallery/ImageSet';
import Loading from '~/components/Loading';
import AdultToggle from '~/components/AdultToggle';
import PasswordPrompt from '../PasswordPrompt';
import Button from '~/components/Button';
import { withRouter } from '~/hooks';

import UserCard from './UserCard';
import FolderSet from './FolderSet';

/* style imports */
import styles from './Page.scss';

/**
 * Displays a single user's gallery
 */


const ProtectedImageSetRoute = (props) => {
  const {
    folders, byFolder, currentUser, adultToggleState, isLoading, pathname, setState, loadData, state, folderId,
  } = props;

  const folder = folders.find(item => item.id == folderId);

  if (!folder) return <Loading />;

  if (folder.password_protected && !state.currentPassword) {
    return (
      <PasswordPrompt
        handleSubmit={(values) => {
          setState({ currentPassword: values.password });
          loadData(pathname);
        }}
      />
    );
  }

  return (
    <ImageSet
      images={byFolder(folderId)}
      currentUser={currentUser}
      adultToggleState={adultToggleState}
      isLoading={isLoading}
      noUsernames
    />
  );
};

class GalleryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: null,
      loadingMore: false,
    };

    this.getRequestId = this.getRequestId.bind(this);
    this.loadData = this.loadData.bind(this);
    this.scrollListener = this.scrollListener.bind(this);
  }

  componentDidMount() {
    const {
      location: { pathname },
      currentUser,
    } = this.props;

    this.loadData(pathname);

    window.addEventListener('touchmove', this.scrollListener);
    window.addEventListener('scroll', this.scrollListener);
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname: prevPathname } } = prevProps;
    const { location: { pathname } } = this.props;

    // If section changes, load new images
    if (prevPathname !== pathname) {
      this.loadData(pathname);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.scrollListener);
    window.removeEventListener('scroll', this.scrollListener);
  }

  getRequestId(pathname) {
    const {
      params: { username },
    } = this.props;

    const splitPath = pathname.split('/');
    const loadFavorites = splitPath[splitPath.length - 1] === 'favorites';
    const folderId = !loadFavorites && Number(splitPath[splitPath.length - 1]);

    let requestId = `GALLERY_${username}`;

    if (loadFavorites) requestId += '_FAVORITES';
    if (folderId) requestId += `_FOLDER_${folderId}`;

    return requestId;
  }

  loadData(pathname, opts = {}) {
    const {
      dispatch,
      params: { username },
      user,
      isLoading,
      location,
    } = this.props;

    const {
      currentPassword: password,
    } = this.state;

    if (!user || user.username !== username || !user.gallery) {
      dispatch(fetchUser(username));
    }

    const splitPath = pathname.split('/');
    const loadFavorites = splitPath[splitPath.length - 1] === 'favorites';
    const folderId = !loadFavorites && Number(splitPath[splitPath.length - 1]);

    if (isLoading[this.getRequestId(location.pathname)]) {
      return;
    }

    return dispatch(fetchImages({
      username,
      favorites: loadFavorites,
      folderId,
      keepOld: opts.keepOld || false,
      password,
      requestId: this.getRequestId(pathname),
      fromId: opts.fromId,
    })).then((res) => {
      if (res.folder && password) this.setState({ currentPassword: null });
    });
  }

  scrollListener() {
    if (window.innerHeight + document.documentElement.scrollTop
      !== document.documentElement.offsetHeight) return;

    this.loadMore();
  }

  async loadMore() {
    const {
      location: { pathname },
      fromId,
      isLoading,
    } = this.props;

    const curFromId = fromId[this.getRequestId(pathname)];

    if (isLoading[this.getRequestId(location.pathname)] || (curFromId && curFromId === -1)) return;

    this.setState({
      loadingMore: true,
    });

    await Promise.resolve(this.loadData(pathname, { fromId: curFromId, keepOld: true }));

    this.setState({
      loadingMore: false,
    });
  }

  render() {
    const {
      dispatch,
      location,
      isSignedIn,
      currentUser,
      user,
      gallery,
      images: {
        byUser,
        byFolder,
        bySection,
      },
      folders,
      isLoading,
      params: { folderId },
      location: { pathname },
      adultToggleState,
      fromId,
    } = this.props;

    const {
      loadingMore,
    } = this.state;

    if (isLoading.user || isLoading.gallery || !user) return <Loading />;
    const images = bySection(gallery.sections[`FAVORITES_${user.username}`]);
    let propsToImage;
    if (pathname.endsWith('favorites')) {
      propsToImage = {
        images,
        currentUser,
        adultToggleState,
        isLoading: isLoading.gallery,
      };
    } else if (folderId && pathname.endsWith(folderId)) {
      propsToImage = {
        folders,
        byFolder,
        currentUser,
        adultToggleState,
        isLoading: isLoading.gallery,
        pathname,
        setState: this.setState,
        loadData: this.loadData,
        state: this.state,
        folderId,
      };
    } else {
      propsToImage = {
        images: byUser,
        currentUser,
        adultToggleState,
        isLoading: isLoading.gallery,
        noUsernames: true,
      };
    }

    return (
      <div className={styles.GalleryPage}>
        {(isLoading.user || !user || !user.gallery) ? <Loading /> : (
          <UserCard {...{
            isSignedIn, currentUser, user,
          }}
          />
        )}

        <AdultToggle />

        <div className={styles.GalleryPage__Navigation}>
          <NavLink to={pathname.endsWith('favorites') ? pathname.replace('/favorites', '') : pathname} className={({ isActive }) => (isActive ? styles.GalleryPage__NavigationLinkActive : '')}>
            <FormattedMessage
              id="ImageGallery_Images"
              defaultMessage="Images"
            />
          </NavLink>
          <span>—</span>
          <NavLink to={pathname.endsWith('favorites') ? pathname : `${pathname}/favorites`} className={({ isActive }) => (isActive ? styles.GalleryPage__NavigationLinkActive : '')}>
            <FormattedMessage
              id="ImageGallery_Favorites"
              defaultMessage="Favorites"
            />
          </NavLink>
        </div>
        {!pathname.endsWith('favorites')
          && (
            <FolderSet
              {...{ user, images: byUser }}
              showActions={currentUser && (currentUser.role === 'admin' || user.username === currentUser.username)}
              actions={{
                deleteFolder: id => dispatch(deleteFolder(id)),
                protectFolder: (id, password) => dispatch(updateFolder(id, { password })),
              }}
            />
          )}
        {
          (!folderId) && (
            <ImageSet
              {...propsToImage}
            />
          )
        }
        {
          !!folderId && (
            <ProtectedImageSetRoute
              {...propsToImage}
            />
          )
        }
        {/* <Routes>
          <Route
            index
            element={(
              <ImageSet
                images={byUser}
                currentUser={currentUser}
                adultToggleState={adultToggleState}
                isLoading={isLoading.gallery}
                noUsernames
              />
            )}
          />
          <Route
            path="favorites"
            element={(
              <ImageSet
                images={images}
                currentUser={currentUser}
                adultToggleState={adultToggleState}
                isLoading={isLoading.gallery}
              />
            )}
          />
          <Route
            path=":folderId"
            element={(
              <ProtectedImageSetRoute
                folders={folders}
                byFolder={byFolder}
                currentUser={currentUser}
                adultToggleState={adultToggleState}
                isLoading={isLoading}
                pathname={pathname}
                setState={this.setState}
                loadData={this.loadData}
                state={this.state}
              />
            )}
          />
        </Routes> */}

        <div style={{ textAlign: 'center' }}>
          {
            !loadingMore && fromId[this.getRequestId(pathname)] && fromId[this.getRequestId(pathname)] !== -1 && (
              <Button onClick={() => this.loadMore()}>
                <FormattedMessage id="Gallery_LoadMore" defaultMessage="Load more" />
              </Button>
            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: {
      user: isLoading(state.loading, FETCHING_USER),
      gallery: isLoading(state.loading, FETCHING_IMAGES),
      ...state.loading,
    },
    user: getUserByName(state, ownProps.params.username),
    gallery: state.gallery,
    images: {
      byUser: getImagesByUser(state, ownProps.params.username),
      bySection: section => getImagesBySection(state, section),
      byFolder: folderId => getImagesByFolder(state, folderId),
    },
    folders: getFolders(state),
    isSignedIn: state.currentUser.isSignedIn,
    currentUser: state.currentUser.data,
    adultToggleState: getVisibilitySettings(state),
    fromId: state.fromId,
  };
};

export default withRouter(connect(mapStateToProps)(GalleryPage));
