// @ts-check
// / <reference path="../types/user" />
import config from '~/config';
import handleError from './_errorHandling';
import { addFlash } from '~/modules/flashes';
import { setLoading } from './loading';

// State
const INITIAL_STATE: UserState = {
  isSignedIn: false,
  data: null,
  settings: {},
  referrals: [],
  social: {
    followers: [],
    following: [],
  },
  multistream: {
    sent: [],
    received: [],
  },
  premium: {
    pledge: null,
    subscribestar: null, // subscribestar patron
    rewards: [],
  },
};

// Loading
export const FETCHING_CURRENTUSER = 'piczel/currentuser/FETCHING';
export const SENDING_MULTISTREAM_INVITE = 'piczel/currentuser/SENDINGMULTI';

// Actions
const SET_SIGNED_IN = 'piczel/currentuser/SET_SIGNED_IN';
const SET_CURRENTUSER = 'piczel/currentUser/SET_CURRENTUSER';
const SET_REFERRALS = 'piczel/currentUser/SET_REFERRALS';
const SET_FOLLOWERS = 'piczel/currentUser/SET_FOLLOWERS';
const SET_FOLLOWINGS = 'piczel/currentUser/SET_FOLLOWINGS';
const SET_MULTI_REQUESTS = 'piczel/currentUser/SET_MULTI_REQUESTS';
const SET_PLEDGE = 'piczel/currentUser/SET_PLEDGE';
const SET_REWARDS = 'piczel/currentUser/SET_REWARDS';
const SIGN_OUT = 'piczel/currentUser/SIGN_OUT';
// Reducer
export default function currentUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SIGNED_IN:
      return { ...state, isSignedIn: action.payload };
    case SET_CURRENTUSER:
      return { ...state, data: action.payload };
    case SET_REFERRALS:
      return { ...state, referrals: action.payload };
    case SET_FOLLOWERS:
      return { ...state, social: { ...state.social, followers: action.payload } };
    case SET_FOLLOWINGS:
      return { ...state, social: { ...state.social, following: action.payload } };
    case SET_MULTI_REQUESTS:
      return { ...state, multistream: action.payload };
    case SET_PLEDGE:
      return { ...state, premium: { ...state.premium, [action.provider === 'subscribestar' ? 'subscribestar' : 'pledge']: action.payload } };
    case SET_REWARDS:
      return { ...state, premium: { ...state.premium, rewards: action.payload } };

    case SIGN_OUT:
      return INITIAL_STATE;

    default: return state;
  }
}

// Action creators
export function setSignedIn(value) {
  return { type: SET_SIGNED_IN, payload: value };
}

export function setCurrentUser(user) {
  return { type: SET_CURRENTUSER, payload: user.data };
}

export function setReferrals(payload) {
  return { type: SET_REFERRALS, payload };
}

export function setFollowers(payload) {
  return { type: SET_FOLLOWERS, payload };
}

export function setFollowings(payload) {
  return { type: SET_FOLLOWINGS, payload };
}

export function setMultiRequests(payload) {
  return { type: SET_MULTI_REQUESTS, payload };
}

export function setPledge(payload, provider = 'patreon') {
  return { type: SET_PLEDGE, payload, provider };
}

export function setRewards(payload) {
  return { type: SET_REWARDS, payload };
}

export const signOut = () => ({
  type: SIGN_OUT,
});

// CRUD - except C and D
export function fetchCurrentUser(silent = true) {
  return function (dispatch, getState, fetch) {
    if (!silent) dispatch(setLoading(FETCHING_CURRENTUSER, true));

    return fetch(`${config.api}/users/me`)
      .then(response => handleError(dispatch, response))
      .then((response) => {
        if (response.errors) return response.errors;

        dispatch(setCurrentUser({ data: response }));
        dispatch(setLoading(FETCHING_CURRENTUSER, false));

        return response;
      });
  };
}

/**
 * @param {import('redux').DeepPartial<User>} user
 * @param {{ silent: boolean }} [options]
 */
export function updateCurrentUser(user, options = { silent: true }) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/users/me`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    }).then(response => handleError(dispatch, response))
      .then((response) => {
        if (response.errors) return response.errors;

        if (!options.silent) dispatch(addFlash('success', 'Your account settings have been saved.'));
        return dispatch(setCurrentUser({ data: response }));
      });
  };
}

// Push notifications
export function updateSubscription(subscription, shouldDelete = false) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/users/me/push`, {
      method: shouldDelete ? 'DELETE' : 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(subscription),
    }).then(response => handleError(dispatch, response));
  };
}

// Avatar
export function updateCurrentUserAvatar(formData) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/avatars/me`, {
      method: 'PUT',
      body: formData,
    })
      .then(response => handleError(dispatch, response))
      .then(response => `${config.api + response.url}?${Date.now()}`);
  };
}

export function deleteCurrentUserAvatar() {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/avatars/me`, {
      method: 'DELETE',
    })
      .then(response => handleError(dispatch, response))
      .then(response => `${config.api + response.url}?${Date.now()}`);
  };
}

// Referrals
export function fetchReferrals() {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/users/me/referrals`)
      .then(response => handleError(dispatch, response))
      .then((response) => {
        if (response.errors) return response.errors;

        dispatch(setReferrals(response));
        return response;
      });
  };
}

// Social - Followers, Following
export function fetchFollowers() {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/users/me/followers`)
      .then(response => handleError(dispatch, response))
      .then((response) => {
        if (response.errors) return response.errors;

        dispatch(setFollowers(response));
        return response;
      });
  };
}

export function fetchFollowings() {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/users/me/following`)
      .then(response => handleError(dispatch, response))
      .then((response) => {
        if (response.errors) return response.errors;

        dispatch(setFollowings(response));
        return response;
      });
  };
}

export function updateFollowing(
  id,
  data = {
    now_live: true,
    announcements: true,
    new_images: true,
    favorited_posts: true,
  },
) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/users/me/follow/${id || ''}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => handleError(dispatch, response))
      .then((response) => {
        if (response.errors) return response.errors;

        dispatch(fetchFollowings());
        return response;
      });
  };
}

// Multistream
export function fetchMultiRequests() {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/streams/${getState().currentUser.data.username}/multi`)
      .then(response => handleError(dispatch, response))
      .then((response) => {
        if (response.errors) return response.errors;

        dispatch(setMultiRequests(response));
        return response;
      });
  };
}

export function sendMultiRequest(username) {
  return function (dispatch, getState, fetch) {
    dispatch(setLoading(SENDING_MULTISTREAM_INVITE, true));

    return fetch(`${config.api}/streams/${username}/multi`, {
      method: 'POST',
    }).then(response => handleError(dispatch, response))
      .then((response) => {
        if (response.errors) return response.errors;

        return dispatch(fetchMultiRequests());
      }).then(() => dispatch(setLoading(SENDING_MULTISTREAM_INVITE, false)));
  };
}

export function updateMultiRequest(username, id, accept = true) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/streams/${username}/multi/${id}`, {
      method: accept ? 'POST' : 'DELETE',
    }).then(response => handleError(dispatch, response))
      .then((response) => {
        if (response.errors) return response.errors;

        dispatch(fetchMultiRequests());

        return response;
      });
  };
}

// Stream Key
export function generateStreamKey() {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/users/me/new_stream_key`, {
      method: 'POST',
    })
      .then(response => handleError(dispatch, response))
      .then((response) => {
        if (response.errors) return response.errors;

        dispatch(setCurrentUser({
          data: {
            ...getCurrentUser(getState()),
            token: response.token,
          },
        }));

        return response.token;
      });
  };
}

// Patreon / Premium
/**
 * @param {'patreon'|'subscribestar'} provider
 */
export function fetchPledge(provider = 'patreon') {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/users/me/pledge?provider=${provider}`)
      .then(response => handleError(dispatch, response))
      .then((response) => {
        return dispatch(setPledge(response, provider));
      });
  };
}

export function linkPatreon(code) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/patreon/oauth_code`, {
      method: 'POST',
      body: JSON.stringify({
        code,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(async (response) => {
      if (!response.ok) throw new Error('Something went wrong while linkin Patreon');

      const json = await response.json();

      return json;
    })
      .then(async (response) => {
        if (response.errors) return response.errors;

        await dispatch(fetchPledge());
      });
  };
}


/**
 * Unlink a external account from current piczel account
 * @param {'patreon'|'subscribestar'} provider
 */
export function unlinkExternalPatron(provider = 'patreon') {
  return async function (dispatch, getState, fetch) {
    const response = await fetch(`${config.api}/${provider}`, {
      method: 'DELETE',
    });

    if (response.ok) {
      dispatch(setPledge(null, provider));

      if (provider == 'patreon') {
        dispatch(updateCurrentUser({
          patreon_id: null,
        }));
      }
    }
  };
}

export function linkSubscribestar(code) {
  return async function (dispatch, getState, fetch) {
    const response = await fetch(`${config.api}/subscribestar/link`, {
      method: 'POST',
      body: JSON.stringify({
        code,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      dispatch(fetchPledge('subscribestar'));
    }
  };
}

export function fetchRewards() {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/users/me/reward`)
      .then(response => handleError(dispatch, response))
      .then((response) => {
        if (response.errors) return response.errors;

        const codes = response.rewards.flatMap(
          reward => reward.premium_gift_codes || [],
        );


        response.gifts.forEach((giftCode) => {
          if (codes.findIndex(code => code.id === giftCode.id) === -1) {
            codes.push(giftCode);
          }
        });

        /** Sort by id so latest codes are at the top */
        codes.sort((a, b) => (a.id < b.id ? 1 : -1));

        dispatch(setRewards(codes || []));
        return codes;
      });
  };
}

export function redeemCode(code) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/premium/redeem/${code.replace(/\-/g, '')}`, {
      method: 'POST',
    }).then(res => handleError(dispatch, res))
      .then((json) => {
        dispatch(fetchRewards());
        return json;
      });
  };
}

export function mailFollowers(data) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/users/me/mail`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => handleError(dispatch, response))
      .then(() => {
        dispatch(addFlash('success', 'Your mail has been sent to your followers.'));
      });
  };
}

/**
 * Calls /users/me/update_password
 * @param {string} currentPassword The current password
 * @param {string} newPassword The new password
 * @param {string} confirmPassword Should match newPassword
 * @returns {import('~/types/state').AsyncAction}
 */
export function changePassword(currentPassword, newPassword, confirmPassword) {
  return async function (dispatch, getState, fetch) {
    const response = await fetch(`${config.api}/users/me/update_password`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        current_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      }),
    });

    const json = await response.json();

    if (response.ok) {
      const { status } = json;

      return dispatch(addFlash('success', status));
    }

    const { errors } = json;

    Object.entries(errors as { [field: string]: string[] }).forEach(([field, messages]) => {
      messages.forEach(message => dispatch(addFlash('error', `${field}: ${message}`)));
    });

    return json;
  };
}

/** Selectors */
export function getCurrentUser(state) {
  if (!state.currentUser.isSignedIn) return null;

  return state.currentUser.data;
}

/**
 * @param {RootState} state
 */
export const getSubscribestarPatron = state => state.currentUser.premium.subscribestar;

/**
 * @param {RootState} state
 */
export const getRewards = state => state.currentUser.premium.rewards;

/**
 * @param {RootState} state
 */
export const getPatreonPledge = state => state.currentUser.premium.pledge;
