import { createReducer, createAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { groupMessages } from './chatEntities';

export const updateHistory = createAction<{ roomId: string, messages: ChatMessage[] }>('UPDATE_HISTORY');
export const clearHistory = createAction<string>('CLEAR_HISTORY');
export const addMessage = createAction<{
  roomId: string,
  /**
   * If true, will break the message group
   */
  breaks?: boolean,
  message: ChatMessage,
}>('ADD_MESSAGE');
export const updateMessage = createAction<ChatMessage>('UPDATE_MESSAGE');

interface EntityState {
  entities: {
    [id: string]: any
  },
  messages: {
    [roomId: string]: {
      compact: string[],
      grouped: string[][],
    }
  }
}

const initialState: EntityState = {
  entities: {},
  messages: {},
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateHistory, (state, action) => {
      if (action.payload) {
        const { roomId, messages } = action.payload;

        messages.forEach((msg) => {
          state.entities[msg.id] = msg;
        });

        if (state.messages[roomId]) {
          const currentMessages = state.messages[roomId].compact.map(id => state.entities[id]);

          currentMessages.sort((a, b) => (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime());

          state.messages[roomId] = {
            grouped: groupMessages(currentMessages).map(group => group.map(msg => msg.id)),
            compact: currentMessages.map(msg => msg.id),
          };
        } else {
          state.messages[roomId] = {
            grouped: groupMessages(messages).map(group => group.map(msg => msg.id)),
            compact: messages.map(msg => msg.id),
          };
        }
      }
    })
    .addCase(clearHistory, (state, action) => {
      state.messages[action.payload] = {
        compact: [],
        grouped: [],
      };
    })
    .addCase(addMessage, (state, action) => {
      const { roomId, breaks, message } = action.payload;
      
      state.entities[message.id] = message;

      if (roomId in state.messages === false) {
        state.messages[roomId] = {
          compact: [],
          grouped: [],
        };
      }

      const { grouped, compact } = state.messages[roomId];
      compact.push(message.id);

      if (breaks || breaks === undefined || !grouped.length) {
        grouped.push([message.id]);
      } else {
        grouped[grouped.length - 1].push(message.id);
      }
    })
    .addCase(updateMessage, (state, action) => {
      state.entities[action.payload.id] = Object.assign({}, (state.entities[action.payload.id] || {}), action.payload);
    })
  ;
});

export const getRoomMessages = createSelector(
  state => state.chatMessages.entities,
  state => state.chatMessages.messages,
  state => state.chat.activeRoom,
  state => state.chat.options.messageDisplay,
  (entities, messages, roomId, display) => {
    const key = display === 'compact' ? 'compact' : 'grouped';
    const messageIds = messages[roomId];

    if (!messageIds) return [];

    return messageIds[key].map(idOrArray => (Array.isArray(idOrArray) ? (idOrArray.map(id => entities[id])) : (entities[idOrArray])));
  },
);

export default reducer;
