/* node_modules */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

/* style imports */
import styles from './Breadcrumbs.scss';

class BreadcrumbItem extends React.Component {
  render() {
    const { title, url, image } = this.props;

    return (
      <Fragment>
        &nbsp;&gt;&nbsp;
        <Link to={url}>
          {image && <img className={styles.Breadcrumbs__Icon} src={image} />}
          {title}
        </Link>
      </Fragment>
    );
  }
}

class Breadcrumbs extends React.Component {
  render() {
    const { links } = this.props;

    return (
      <nav className={styles.Breadcrumbs}>
        <Link to="/">Piczel.tv</Link>
        {links.map((link, i) => <BreadcrumbItem key={`link:${i}`} {...link} />)}
      </nav>
    );
  }
}

export default Breadcrumbs;
