import React from 'react';
import { useSelector } from 'react-redux';

import {
  AutocompletePane, AutocompleteTitle, AutocompleteSuggestions,
  AutocompleteSuggestion,
} from './AutocompleteUI';
import { getCurrentUser } from '~/modules/chat';
import { roleLevels } from './ChatContext';

export const commands = {
  tableflip: {
    description: 'Adds (╯°□°）╯︵ ┻━┻ to your message',
    value: '(╯°□°）╯︵ ┻━┻)',
  },
  unflip: {
    description: 'Adds ┬─┬ ノ( ゜-゜ノ) to your message',
    value: '┬─┬ ノ( ゜-゜ノ)',
  },
  shrug: {
    description: 'Adds ¯\\_(ツ)_/¯ to your message',
    value: '¯\\\\\\_(ツ)\\_/¯',
  },
  roll: {
    description: 'Rolls a number from 0 to 100',
    value: '/roll ', // note the added trailing space so the regex isn't triggered anymore
  },
  clear: {
    description: 'Clears chat history',
    value: '/clear',
  },
  topic: {
    description: 'Sets the room topic',
    value: '/topic',
    requires: 'subject',
  },
  random: {
    description: 'Selects an item from a list',
    value: '/random',
    role: 'mod',
  },
  raffle: {
    description: 'Starts a raffle',
    value: '/raffle',
    role: 'streamer',
  },
  me: {
    description: 'Displays text with emphasis',
    value: '/me',
  },
  join: {
    description: 'Joins a room',
    value: '/join',
  },
  spoiler: {
    description: 'Marks your message as a spoiler',
    value: '/spoiler',
  },
  help: {
    description: 'Show command available',
    value: '/help',
  },
};
/**
 * replaces a slash command with its value
 * @param {string} message 
 */
export const processMacro = message => message.replace(/^\/(\w+)$/, (match, command) => ((command in commands) ? commands[command].value : match));

export const useCommandsAvailable = () => {
  const currentUser = useSelector(getCurrentUser);

  return Object.keys(commands).filter(command => (!commands[command].role || roleLevels[commands[command].role] <= roleLevels[currentUser.role]) && (!commands[command].requires || currentUser.permissions[commands[command].requires]));
};

const SlashCommandAutocomplete = ({ message, setMessage, focusInput }) => {
  const messageSubstr = message.substr(1); // message without the slash
  const commandsAvailable = useCommandsAvailable();
  const suggestions = commandsAvailable.filter(command => command.indexOf(messageSubstr) !== -1);

  if (!suggestions.length) return null;

  const onClick = command => {
    setMessage(`${commands[command].value} `);
    focusInput();
  }

  return (
    <AutocompletePane>
      <AutocompleteTitle>
        Commands
      </AutocompleteTitle>
      <AutocompleteSuggestions blur={focusInput}>
        {
          (onFocus, focused) => suggestions.map(command => (
            <AutocompleteSuggestion
              key={command}
              label={`/${command}`}
              onFocus={onFocus}
              focused={focused}
              description={commands[command].description}
              onClick={() => onClick(command)}
            />
          ))
        }
      </AutocompleteSuggestions>
    </AutocompletePane>
  )
};

SlashCommandAutocomplete.regex = /^\/(\w*)$/;

export default SlashCommandAutocomplete;
