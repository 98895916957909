import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './OverlayLoginMessage.scss';

const OverlayLoginMessage = () => (
  <div className={styles.OverlayLoginMessage}>
    <span role="img" className="ion-information-circled" />
    &nbsp;
    <FormattedMessage id="OverlayLoginMessage" defaultMessage={`Right-click and select "interact" to login`} />
  </div>
);

export default OverlayLoginMessage;
