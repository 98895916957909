import React, { Component } from 'react';

import FormattedMessage from '~/components/common/FormattedMessage';

import styles from './ShareButtons.scss';

export default class ShareButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: null,
    };
  }

  componentDidMount() {
    const url = `https://piczel.tv${window.location.pathname}?${Date.now()}`;
    this.setState({ url });
  }

  render() {
    const { name, title } = this.props;
    const { url } = this.state;

    const postTitle = `${name}'s live stream on @PiczelTV`;
    const twitterText = `${name}'s live stream on @PiczelTV - ${title}`;

    return (
      <div className={`${styles.shareBtns} ${url === null ? 'loading' : 'loaded'}`}>
        <a className={`${styles.respSharingButton__link}`} rel="noopener" href={`https://twitter.com/intent/tweet/?text=${encodeURIComponent(twitterText)}&url=${encodeURIComponent(url)}`} target="_blank" aria-label="Share on Twitter">
          <div className={`${styles.respSharingButton} ${styles.respSharingButtonTwitter}`}>
            <div aria-hidden="true" className={`${styles.respSharingButton__icon} ${styles.respSharingButton__iconSolid}`}>
              <i className="ion-social-twitter" />
            </div>
            <FormattedMessage id="Share_Twitter" defaultMessage="Share on Twitter" />
          </div>
        </a>

        <a className={styles.respSharingButton__link} rel="noopener" href={`https://www.tumblr.com/widgets/share/tool?posttype=link&title=${postTitle}&caption=${title}.&content=${encodeURIComponent(url)}&canonicalUrl=${encodeURIComponent(url)}&shareSource=tumblr_share_button`} target="_blank" aria-label="Share on Tumblr">
          <div className={`${styles.respSharingButton} ${styles.respSharingButtonTumblr}`}>
            <div aria-hidden="true" className={`${styles.respSharingButton__icon} ${styles.respSharingButton__iconSolid}`}>
              <i className="ion-social-tumblr" />
            </div>
            <FormattedMessage id="Share_Tumblr" defaultMessage="Share on Tumblr" />
          </div>
        </a>
      </div>
    );
  }
}
