import propTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';

import BlurContext from './BlurContext';
import { getVisibilitySettings, ToggleState, setContentVisibility } from '~/modules/contentVisibility';

import styles from './index.scss';

const cx = classNames.bind(styles);


/**
 * Blurs children if necessary
 * @param {object} props
 * @param {JSX.Element} props.children
 * @param {Stream & GalleryImage} props.content
 * @returns {JSX.Element}
 */
export default function ContentBlur({ children, content }) {
  const [visible, setVisible] = useState(false);
  const toggleState = useSelector(state => getVisibilitySettings(state));
  const dispatch = useDispatch();
  // streams use 'adult' images use 'nsfw'
  const isAdult = content.adult || content.nsfw;
  const isVisible = toggleState === ToggleState.NSFW || !isAdult || visible;

  const disableBlur = (e) => {
    if (!isVisible) {
      e.preventDefault();
      e.stopPropagation();
    }

    setVisible(true);
  };

  return (
    <BlurContext.Provider value={isVisible}>
      <div
        className={cx('ContentBlur', { 'ContentBlur--Blurred': !isVisible, 'ContentBlur--Inactive': isVisible })}
        role="button"
        tabIndex={0}
        onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && disableBlur()}
        onClick={disableBlur}
      >
        <div className={styles.ContentBlur__Message}>
          <h2>Adult content</h2>
          <p className={styles.ContentBlur__MessageSubtext}>Click to show</p>
          <button type="button" onClick={() => dispatch(setContentVisibility(ToggleState.NSFW))} className={styles.ContentBlur__MessageSubtext}>Don&apos;t warn me again</button>
        </div>
        { children }
      </div>
    </BlurContext.Provider>
  );
}

ContentBlur.propTypes = {
  children: propTypes.node.isRequired,
  content: propTypes.shape({
    adult: propTypes.bool,
    nsfw: propTypes.bool,
  }).isRequired,
};
