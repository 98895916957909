import { handleError } from './flashes';

const INITIAL_STATE = 200;

// Actions
const SET_STATUS = 'piczel/errorHandling/SET_STATUS';

// Reducer
export function errorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_STATUS:
      return action.code;
    default:
      return state;
  }
}

// Action creators
export function setStatus(code) {
  return { type: SET_STATUS, code };
}

export default function errorHandling(dispatch, response) {
  if (response.status) dispatch(setStatus(response.status));

  return response.text().then(data => (data ? JSON.parse(data) : {})).then((json) => {
    // Use new error handling where possible
    if (!json) return json;

    if (json.status === 'error') {
      dispatch(handleError(`${json.message} (Code: ${json.code})`, `ErrorCode_${json.code}`));
    } else if (response.status !== 200 && response.status !== 204 && response.status !== 201) {
      Object.entries(json).forEach(([key, errors]) => {
        if (typeof errors === 'string') {
          dispatch(handleError(errors));
        }

        if (Array.isArray(errors)) {
          errors.map(msg => dispatch(handleError(msg.toString())));
        } else if (typeof errors === 'object') {
          Object.entries(errors).forEach(([field, message]) => {
            dispatch(handleError(`${field}: ${message}`));
          });
        }
      });

      return { errors: Object.values(json) };
    }

    return json;
  });
}
