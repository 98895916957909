import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const ErrorHandler = ({ statusCode }) => {
  if (statusCode === 404) return <Navigate to="/404" />;

  return null;
};

ErrorHandler.propTypes = {
  statusCode: PropTypes.number,
};

ErrorHandler.defaultProps = {
  statusCode: null,
};

export default ErrorHandler;
