import React from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { FormattedMessage } from 'react-intl';

/* local imports */
import { signIn } from '~/modules/auth';
import TextField from '../Form/TextField';
import Button from '../Button';
import { setAccessToken } from '~/modules/chat';

/* style imports */
import styles from './Login.scss';

const LoginForm = () => {
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validateOnChange={false}
      validateOnBlur={true}
      validate={(values) => {
        const errors = {};

        if (!values.email) errors.email = 'Required';
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        if (!values.password) errors.password = 'Required';

        return errors;
      }}
      onSubmit={(values) => {
        dispatch(setAccessToken({
          accessToken: null,
          uid: null,
        }));
        dispatch(signIn(values.email, values.password));
      }}
    >
      {({ errors }) => (
        <Form>
          <FormattedMessage id="Login_Email" defaultMessage="Email">
            {msg => (
              <TextField name="email" type="email" placeholder={msg} error={errors.email} fullWidth>
                {msg} 
              </TextField>
            )}
          </FormattedMessage>

          <FormattedMessage id="Login_Password" defaultMessage="Password">
            {msg => (
              <TextField name="password" type="password" placeholder={msg} error={errors.password} fullWidth>
                {msg}
              </TextField>
            )}
          </FormattedMessage>

          <Button className={styles.Login__Button} type="submit">
            <FormattedMessage id="Login_Submit" defaultMessage="Sign In" />
          </Button>

          <div className={styles.Login__Links}>
            <Link to={'/login/reset_password'}>
              <FormattedMessage id="PasswordReset__Link" defaultMessage="Forgot your password?" />
            </Link>
            <Link to={`/login/resend_confirmation`}>
              <FormattedMessage id="Resend_confirmation_email" defaultMessage="Resend Confirmation Email?" />
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
