import propTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';

import styles from './index.scss';

const cx = classNames.bind(styles);

/**
 * @typedef {Object} Props
 * @property {boolean} adding
 * @property {() => void} toggleAdding
 * @property {boolean} disabled
 *
 * @param {Props} props
 */
export default function AddStreamButton({ adding, toggleAdding, disabled }) {
  return (
    <i
      tabIndex={0}
      role="button"
      onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && !disabled && toggleAdding()}
      onClick={disabled ? undefined : toggleAdding}
      className={`${cx(styles.AddStreamButton, { adding, disabled })} ion-android-add-circle`}
    />
  );
}

AddStreamButton.propTypes = {
  adding: propTypes.bool,
  disabled: propTypes.bool,
  toggleAdding: propTypes.func.isRequired,
};

AddStreamButton.defaultProps = {
  adding: false,
  disabled: false,
};
