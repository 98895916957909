import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './Button.scss';

const cx = classNames.bind(styles);

/**
 * Default button component. Built upon for other types.
 */
const Button = ({
  className,
  to,
  children,
  color,
  size,
  disabled,
  ...props
}) => {
  const newClassName = cx(className, 'Button', {
    [`Button--${color}`]: color,
    [`Button--${size}`]: size,
    'Button--disabled': disabled,
  });

  if (to && to.startsWith('http')) {
    return <a {...props} href={to} className={newClassName}>{children}</a>;
  }
  if (to) {
    return <Link {...props} to={to} className={newClassName}>{children}</Link>;
  }

  return (
    <button type="button" {...props} className={newClassName}>
      {children}
    </button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['blue', 'flatBlue', 'black', 'red', 'transparent']),
  size: PropTypes.oneOf(['small', 'tiny']),
  style: PropTypes.objectOf(PropTypes.string),
};

Button.defaultProps = {
  disabled: false,
  className: null,
  to: null,
  size: null,
  color: 'blue',
  style: null,
};

/**
 * HOC that adds Button styles to an element
 * @param {React.ElementType} Component
 */
export const withButtonStyle = Component => (props) => {
  const color = props.color || null;
  const size = props.size || null;

  const className = [
    'Button',
    `Button--${color}`,
    `Button--${size}`,
  ];

  if (props.className) {
    className.push(props.className);
  }

  return (
    <Component {...props} className={cx(className)} />
  );
};

export default Button;
