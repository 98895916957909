import React, { useContext, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getPerformingAction, getActiveRoom, setPerformingAction, getCurrentRoom } from '~/modules/chat';

import Modal from './Modal';
import { ChatContext, events } from './ChatContext';
import Button from '../Button';
import { NEW_ACCOUNT_BLOCK_TIME } from './Options';

const locale = {
  kick: {
    id: 'ActionModal_ConfirmKick',
    defaultMessage: 'Kick {user}?',
  },
  ban: {
    id: 'ActionModal_ConfirmBan',
    defaultMessage: 'Ban {user}?',
  }
}

const ActionModal = () => {
  const performingAction = useSelector(getPerformingAction);
  const activeRoom = useSelector(getActiveRoom);
  const dispatch = useDispatch();
  
  const [promptBlockNewAccounts, setPromptBlockNewAccounts] = useState(false);

  const currentRoom = useSelector(getCurrentRoom);

  const { client: socket } = useContext(ChatContext);

  const updateRoom = useCallback((data) => {
    if (!socket) return;

    socket.emit(events.UPDATE_ROOM, {
      roomId: activeRoom,
      data,
    });
  }, [socket, activeRoom]);

  const confirm = useCallback(() => {
    if (socket) {
      socket.emit(events.ADD_MESSAGE, {
        roomId: activeRoom,
        message: {
          text: `/${performingAction.action} ${performingAction.target}`,
        },
      });
    }
    dispatch(setPerformingAction(null));

    if (performingAction.action === 'ban' && !localStorage.promptBlockNewAccounts && !currentRoom.min_account_age) {
      setPromptBlockNewAccounts(true);
    }
  }, [socket, activeRoom, performingAction, dispatch]);

  if (performingAction) {
    return (
      <Modal title={<FormattedMessage id="ActionModal_Title" defaultMessage="Confirm action" />} close={() => dispatch(setPerformingAction(null))}>
        <div style={{ textAlign: 'center', margin: '1em 0' }}>
          <FormattedMessage {...locale[performingAction.action]} values={{ user: performingAction.target }} />
        </div>
        <div style={{ textAlign: 'center'}}>
          <Button onClick={() => dispatch(setPerformingAction(null))}>
            <FormattedMessage id="ActionModal_Cancel" defaultMessage="Cancel" />
          </Button>
          <Button onClick={confirm} color="red">
            <FormattedMessage id="ActionModal_Confirm" defaultMessage="Confirm" />
          </Button>
        </div>
      </Modal>
    );
  }


  if (promptBlockNewAccounts) {
    return (
      <Modal
        title={<FormattedMessage id="BlockNewAccountsPrompt_Title" defaultMessage="Having trouble?" />}
        close={() => setPromptBlockNewAccounts(false)}
      >
        <div style={{ textAlign: 'center', margin: '1em' }}>
          <FormattedMessage id="BlockNewAccountsPrompt_Message" defaultMessage="if you're having trouble with spammers, disable chat for new accounts" />
        </div>

        <div style={{ textAlign: 'center', margin: '1em' }}>
          <Button
            color="red"
            onClick={() => {
              updateRoom({
                min_account_age: NEW_ACCOUNT_BLOCK_TIME,
                allow_anon: false
              });

              setPromptBlockNewAccounts(false);
            }}>
            <FormattedMessage id="BlockNewAccountsPrompt_Block" defaultMessage="Block new accounts" />
          </Button>

          <Button 
            onClick={() => {
              setPromptBlockNewAccounts(false);
            }}
          >
            <FormattedMessage id="BlockNewAccountsPrompt_Dismiss" defaultMessage="Dismiss" />
          </Button>
        </div>

        <div style={{ textAlign: 'center', marginTop: '1em'}}>
          <Button 
            color="transparent"
            onClick={() => {
              localStorage.setItem('promptBlockNewAccounts', '1');
              setPromptBlockNewAccounts(false);
            }}
          >
            <FormattedMessage id="BlockNewAccountsPrompt_DoNotRemind" defaultMessage="Don't remind me again" />
          </Button>
        </div>
      </Modal>
    )
  }

  return null;
};

export default React.memo(ActionModal);
