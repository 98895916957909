import React from 'react';
import classNames from 'classnames/bind';

import Button from '../Default';

import styles from './SelectorButton.scss';

const cx = classNames.bind(styles);

export class Selection extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const {
      id,
      onClick,
      setActive,
    } = this.props;

    if (onClick) onClick(e);

    setActive(e, id);
  }

  render() {
    const {
      children,
    } = this.props;

    return (
      <li {...this.props} onClick={this.handleClick}>
        {children}
      </li>
    );
  }
}

class SelectorButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      active: props.value || 0,
    };

    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.setActive = this.setActive.bind(this);
  }

  toggleVisibility(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ visible: !this.state.visible });
  }

  setActive(e, id) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      visible: false,
      active: id,
    });
  }

  render() {
    const {
      className,
      children,
      options,
    } = this.props;

    return React.Children.map(children, (activeButton, id) => (id === this.state.active ? (
      <Button {...this.props} {...activeButton.props} className={cx('SelectorButton', className)}>
        {activeButton.props.children}

        <span className={cx('SelectorButton__Switch')} onClick={this.toggleVisibility}>
          <i className="ion-ios-arrow-down" />
        </span>
        {this.state.visible && (
        <ul className={cx('SelectorButton__List')}>
          {React.Children.map(children, (child, id) => (
            React.cloneElement(child, { setActive: e => this.setActive(e, id) })
          ))}
        </ul>
        )}
      </Button>
    ) : null));

    return (
      <Button {...this.props} className={cx('SelectorButton', className)}>
        {React.Children.map(children, (child, id) => (id === this.state.active ? (
          child.props.children
        ) : null))}

        <span className={cx('SelectorButton__Switch')} onClick={this.toggleVisibility}>
          <i className="ion-ios-arrow-down" />
        </span>
        {this.state.visible && (
        <ul className={cx('SelectorButton__List')}>
          {React.Children.map(children, (child, id) => (
            React.cloneElement(child, { setActive: this.setActive, id })
          ))}
        </ul>
        )}
      </Button>
    );
  }
}

export default SelectorButton;
