import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Field } from 'formik';

import Button from '~/components/Button';

import styles from './TextField.scss';

const cx = classNames.bind(styles);


/**
 * Reusable Field component for single line text inputs
 */
const TextField = (props) => {
  const [isHovered, setHovered] = useState(false);

  const {
    value,
    name,
    children,
    error,
    action,
    readOnly,
    hoverableValue,
    fullWidth,
    withTooltip,
    indicator: orgIndicator,
    className,
  } = props;


  const hoverableSpread = hoverableValue ? {
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
  } : null;

  const valueSpread = hoverableValue ? {
    value: isHovered ? hoverableValue : 'Hover to show',
  } : null;

  let indicator = orgIndicator;

  if (error) {
    indicator = {
      icon: 'ion-alert',
      text: error,
    };
  }

  return (
    <div
      className={cx('TextField', {
        'TextField--indicator': indicator,
        [`TextField--indicator-${indicator && indicator.color}`]: indicator && indicator.color,
        'TextField--action': action,
        'TextField--hoverable': hoverableValue,
        'TextField--fullWidth': fullWidth,
        'TextField--withTooltip': withTooltip,
      },
        className
      )}
      {...hoverableSpread}
    >
      {children && <label htmlFor={name}>{children}</label>}
      <Field
        type="text"
        id={name}
        name={name}
        readOnly={readOnly}
        {...valueSpread}
        {...{ ...props, children: undefined }}
      />
      {indicator && (
        <div data-testid={`error-indicator-${name}`} className={cx('TextField__Indicator', {
          [`TextField__Indicator--${indicator.color}`]: indicator.color,
        })}
        >
          {indicator.icon && <i className={cx(['TextField__IndicatorIcon', indicator.icon])} />}
          {indicator.text && <span className={cx('TextField__IndicatorText')}>{indicator.text}</span>}
        </div>
      )}
      {action && (
        <Button
          className={cx('TextField__Action')}
          onClick={action.function}
          color="black"
        >
          <i className={cx(action.icon)} />
        </Button>
      )}
    </div>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  action: PropTypes.shape({
    icon: PropTypes.string,
    function: PropTypes.func,
  }),
  indicator: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.string,
  }),
  hoverableValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  /**
   * When enabled adds a bit of padding to the left for the tooltip icon
   */
  withTooltip: PropTypes.bool,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

TextField.defaultProps = {
  error: null,
  action: null,
  indicator: null,
  hoverableValue: null,
  withTooltip: false,
  readOnly: false,
  fullWidth: false,
  children: undefined,
};

export default TextField;
