import React, {
  useEffect, useRef, useState, useLayoutEffect, useCallback,
} from 'react';
import ReactDOM from 'react-dom';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { usePopper } from 'react-popper';

import UserOverview from '~/components/UserOverview';
import OverviewButtons from './OverviewButtons';
import { getZoom } from '~/modules/chat';

import Config from '~/config';

import styles from './UserOverview.scss';

/**
 * Chat-specific user overview
 * @type {React.FC<{parent: HTMLElement, hide: () => void}, messageId: number>}
 */
const ChatOverview = ({ parent, hide, user }) => {
  const [ref, setRef] = useState(null);
  const [elementSize, setElementSize] = useState({ width: 0, height: 0 });
  const zoom = useSelector(getZoom);
  const offset = useCallback(({ reference }) => {
    const { width, height } = elementSize;
    const xMax = window.innerWidth - (width + 30);
    const yMax = window.innerHeight - 410;
    const x = reference.x > xMax ? -(width + 10) : (reference.x * (zoom - 1));
    const y = reference.y > yMax ? -(height + 10) : (reference.y * (zoom - 1));
    return [
      x,
      y,
    ];
  }, [zoom, elementSize]);

  useEffect(() => {
    if (!ref?.firstChild) return;
    const resizeObserver = new ResizeObserver(() => {
      const { clientWidth, clientHeight } = ref?.firstChild || { clientHeight: 0, clientWidth: 0 };
      setElementSize({ width: clientWidth, height: clientHeight });
    });
    resizeObserver.observe(ref?.firstChild);
    return () => resizeObserver.disconnect();
  }, [ref]);

  const { styles: popperStyles, attributes } = usePopper(parent, ref, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
  });

  const { username } = user;

  useEffect(() => {
    /**
     * Used to hide the overview
     * @param {MouseEvent} e
     */
    function globalClickListener(e) {
      if (e.target && ref && e.target !== parent && (e.target !== ref)) {
        // Prevent clicking the profile image from being treated as an 'outside' click
        if (e.target.className === 'profile-image') return;
        hide();
      }
    }

    window.addEventListener('click', globalClickListener);

    return () => window.removeEventListener('click', globalClickListener);
  }, [zoom, parent, hide, ref]);

  return ReactDOM.createPortal(
    (
      <div
        className={styles.ChatUserOverview}
        ref={setRef}
        style={popperStyles.popper}
        {...attributes.popper}
      >
        <UserOverview user={{ username, avatar: { url: `${Config.avatarsHost}/avatars/${user.id}` } }}>
          <OverviewButtons user={user} />
        </UserOverview>
      </div>
    ),
    document.getElementById('userOverviewContainer'),
  );
};

// @ts-ignore
// eslint-disable-next-line no-undef
if (__CLIENT__) {
  // HTMLElement is undefined in node
  ChatOverview.propTypes = {
    parent: propTypes.instanceOf(HTMLElement).isRequired,
    hide: propTypes.func.isRequired,
  };
}

export default ChatOverview;
