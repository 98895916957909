/* node_modules */
import React from 'react';
import { Routes, Route } from 'react-router-dom';

/* local imports */
import GalleryUser from './User';
import GalleryUpload from './Image/Upload';

/**
 * Gallery routing component
 */
const Gallery = () => (
  <Routes>
    <Route path="upload" element={<GalleryUpload />} />
    <Route path=":username">
      <Route index element={<GalleryUser />} />
      <Route path="favorites" element={<GalleryUser />} />
      <Route path=":folderId" element={<GalleryUser />} />
    </Route>
  </Routes>
);

export default Gallery;
