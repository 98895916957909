/**
 * Standalone player component
 */
import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import { HLS, OVENPLAYER } from '~/components/StreamPlayer/playerTechs';
import getSources from '~/components/StreamPlayer/playerSources';

const Player = () => {
  const { streamId } = useParams();
  const location = useLocation();
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    OvenPlayer.create('player', {
      sources: getSources(OVENPLAYER, params.get('vjsTech') || HLS, { id: streamId }, null, params.get('playbackKey')),
      autoFallback: true,
      webrtcConfig: {
        timeoutMaxRetry: 1,
        connectionTimeout: 2500,
      },
    });
  }, []);

  return (
    <>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            src: '/ovenplayer/ovenplayer.js',
          },
          {
            src: 'https://unpkg.com/hls.js@1.0.2/dist/hls.min.js',
            type: 'text/javascript',
          }
        ]}
      />

      <div id="player" />
    </>
  )
}

export default Player;
