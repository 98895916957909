import { createReducer, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Config from '~/config';

const bannerApiCall = method => async (data, thunkApi) => {
  const fetch = thunkApi.extra;

  const response = await fetch(`${Config.api}/holidaybanners${data.id ? `/${data.id}` : ''}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return response.json();
};

export const setActiveBanner = createAction<Banner>('admin/SET_ACTIVE_BANNER');
export const createBanner = createAsyncThunk('admin/CREATE_BANNER', bannerApiCall('POST'));
export const updateBanner = createAsyncThunk('admin/UPDATE_BANNER', bannerApiCall('PATCH'));
export const deleteBanner = createAsyncThunk('admin/DELETE_BANNER', bannerApiCall('DELETE'));
export const fetchBanners = createAsyncThunk<Banner[], void, PiczelThunkApiConfig>('admin/FETCH_HOLIDAY_BANNERS', async (arg, thunkApi) => {
  const fetch = thunkApi.extra;
  
  const response = await fetch(`${Config.api}/holidaybanners`);
  
  return response.json();
});
export const fetchCurrentBanner = createAsyncThunk<Banner | null, void, PiczelThunkApiConfig>('admin/FETCH_CURRENT_BANNER', async (arg, thunkApi) => {
  const fetch = thunkApi.extra;

  const response = await fetch(`${Config.api}/holidaybaners/current`);

  return response.json();
});

const initialState: HolidayBannerState = {
  activeBanner: null,
  banners: [],
  allBanners: {},
  creating: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setActiveBanner, (state, action) => {
    state.activeBanner = action.payload;
  });

  builder.addCase(fetchBanners.fulfilled, (state, action) => {
    action.payload.forEach((holidayBanner) => {
      state.banners.push(holidayBanner.id);
      state.allBanners[holidayBanner.id] = holidayBanner;
    });
  });

  builder.addCase(createBanner.fulfilled, (state, action) => {
    if (action.payload.id) {
      state.banners.push(action.payload.id);
      state.allBanners[action.payload.id] = action.payload;
    }
  });

  builder.addCase(updateBanner.fulfilled, (state, action) => {
    if (action.payload.id) {
      state.allBanners[action.payload.id] = action.payload;
    }
  });

  builder.addCase(deleteBanner.fulfilled, (state, action) => {
    if (action.payload.id) {
      state.banners = state.banners.filter(id => id !== action.payload.id);
    }
  });

  builder.addCase(fetchCurrentBanner.fulfilled, (state, action) => {
    if (action.payload?.id) {
      state.activeBanner = action.payload;
    }
  })
});

export default reducer;
