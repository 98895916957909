import React, { useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import Modal from './Modal';
import TextField from '../Form/TextField';
import Button from '../Button';
import formatDuration from './formattingHelpers';
import { getCurrentRoom } from '~/modules/chat';
import { ChatContext, events } from './ChatContext';

import styles from './OptInRaffle.scss';

const cx = classNames.bind(styles);

const PROMPT_MAX_LENGTH = 30;

const OptInRaffle = ({ ...props }) => {
  const intl = useIntl();
  const currentRoom = useSelector(getCurrentRoom);
  const { client } = useContext(ChatContext);

  const roomId = currentRoom.id;

  return (
    <Modal title={<FormattedMessage id="OptInRaffle_Title" defaultMessage="Opt-in raffle" />} {...props}>
      <Formik
        initialValues={{
          winners: 1,
          maxParticipants: 0,
          prompt: '',
          duration: 120,
          rules: '',
        }}
        onSubmit={({ winners, maxParticipants, prompt, duration, rules }) => {
          client?.emit(events.START_RAFFLE, {
            roomId,
            options: {
              winners,
              maxParticipants,
              prompt,
              rules,
              duration,
            }
          });

          props?.close();
        }}
      >
        {
          ({ values }) => (
            <Form>
              <TextField className={cx('OptInRaffle__Field')} fullWidth type="number" min={1} max={10} name="winners">
                <FormattedMessage id="OptInRaffle_MaxWinners" defaultMessage="# of winners" />
              </TextField>

              <label className={cx('OptInRaffle__Field')}>
                <Field name="maxParticipants" type="range" min={0} step={1} max={20} />
                <FormattedMessage
                  id="OptInRaffle_MaxParticipants"
                  defaultMessage="Max entrants: {value}"
                  values={{
                    value: values.maxParticipants === 0 ? (
                      <FormattedMessage id="OptInRaffle_MaxParticipantsZero" defaultMessage="Unlimited" />
                    ) : (
                      values.maxParticipants
                    )
                  }}
                />
              </label>

              <TextField maxLength={PROMPT_MAX_LENGTH} className={cx('OptInRaffle__Field')} fullWidth type="text" name="prompt" placeholder={intl.formatMessage({ id: 'OptInRaffle_PromptPlaceholder', defaultMessage: 'New Raffle! Prize: something cool.' })}>
                <FormattedMessage id="OptInRaffle_Prompt" defaultMessage="Text prompt" />
              </TextField>

              <div className={cx('OptInRaffle__Field')}>
                <label htmlFor="OptInRaffle_Rules">
                  <FormattedMessage id="OpInRaffle_Rules" defaultMessage="Rules" />
                </label>
                <Field id="OptInRaffle_Rules" component="textarea" name="rules" maxLength={512} />
              </div>

              <label className={cx('OptInRaffle__Field')}>
                <FormattedMessage id="OptInRaffle_Duration" defaultMessage="Duration {duration}"  values={{ duration: formatDuration(values.duration, false) }} />
                <Field list="durationvalues" name="duration" type="range" step={30} max={900} min={30} />
              </label>

              <datalist id="durationvalues">
                <option value="30" />
                <option label="1m" value="60" />
                <option value="90" />
                <option label="2m" value="120" />
                <option label="5m" value="300" />
                <option label="10m" value="600" />
                <option label="15m" value="900" />
              </datalist>

              <Button type="submit">
                <FormattedMessage id="OptInRaffle_Create" defaultMessage="Create raffle" />
              </Button>
            </Form>
          )
        }
      </Formik>
    </Modal>
  );
};

export default OptInRaffle;
