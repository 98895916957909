/* node_modules */
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

/* local imports */
import ProfileNav from './ProfileNav';
import FormattedMessage from '~/components/common/FormattedMessage';

/* style imports */
import styles from './Header.scss';

/**
 * Our site header.
 */
function Header() {
  const location = useLocation();

  return (
    <header role="navigation" className={styles.Header}>
      <NavLink to="/" aria-label="piczel logo" role="banner" className={styles.Header__Logo} />
      <nav className={styles.Header__Nav}>
        <NavLink to="/premium" className={({ isActive }) => (isActive ? styles.Header__ActiveLink : '')}>
          <i className="ion-star" />
          {' '}
          <span><FormattedMessage id="MainNav_Premium" defaultMessage="Premium" /></span>
        </NavLink>
        <NavLink to="/help" className={({ isActive }) => (isActive ? styles.Header__ActiveLink : '')}>
          <i className="ion-help-circled" />
          {' '}
          <span><FormattedMessage id="MainNav_Help" defaultMessage="Help" /></span>
        </NavLink>
        <NavLink to="/search" className={({ isActive }) => (isActive ? styles.Header__ActiveLink : '')}>
          <i className="ion-search" />
          {' '}
          <span><FormattedMessage id="MainNav_Search" defaultMessage="Search" /></span>
        </NavLink>
      </nav>
      <ProfileNav {...{ location }} />
    </header>
  );
}


export default Header;
